import React, { useEffect, useState } from "react";
import NormalButton from "../../components/NormalButton";
import NormalTable from "../../components/NormalTable";
import NormalInput from "../../components/inputField";
import assets from "../../assets";
import "./styles.scss";
import "../users/styles.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAdminTickets, updateCommStatus } from "../../redux/reducers/customerCareSlice";
import { CSV_FILE_NAME, errorToast, fileDownload, successToast } from "../../utils";
import Exported from "../../services/endpoints";
import NoData from "../../components/noData";
import moment from "moment";
import NormalSelect from "../../components/NormalSelect";
import InputFieldNoValidation from "../../components/inputFieldNoValidation";
import CheckBox from "../../components/checkBox";

const CustomerCare = () => {
  
  let navigate = useNavigate();

  const headerDetails = [
    { label: "Ticket No" },
    { label: "Date & Time" },
    { label: "Username" },
    { label: "Email" },
    { label: "Category" },
    { label: "Ticket Details" },
    { label: "Status" },
    { label: "Comm" },
    { label: "Action" },
  ];

  const [page, setPage] = useState(1);
  const totalPages = useSelector((state) => state?.customerCare?.totalPages || 0);

  const [search, setSearch] = useState('');

  const data = useSelector((state) => state?.customerCare?.data || []);

  const dispatch = useDispatch();

  useEffect(() => {
    getTickets()
  }, [page, search]);

  const getTickets = () => {
    dispatch(getAdminTickets(page, search));
  }

  const onClickCSV = () => {
    fileDownload(`${Exported.Endpoints.GET_ALL_ADMIN_TICKETS}?export=1`, CSV_FILE_NAME.CUSTOMER_TICKET);
  }

  const onChangeCommStatus = (_id, e) => {

    if(e.target.checked == true){
      let request = {
        _id,
        communicationStatus: 'Sent'
      }
      updateCommStatus(request).then(res=>{
        dispatch(getAdminTickets(page, search));
        successToast("Communication status updated successfully.")
      }).catch(_err=>{} )
    }else{
      errorToast("Communication is already sent to this ticket.")
    }

   
  }

  return (
    <div className="users-main-container">
      <div className="users-header-container">
        <p className="users-title">Customer Care Tickets</p>
        <NormalButton label="EXPORT CSV" mainBg onClick={onClickCSV} />
      </div>
      <div className="customer-care-search-container ">
        <div className="customer-care-search-input">
          <InputFieldNoValidation leftIcon searchIcon placeholder="Search" onChange={(e)=> setSearch(e.target.value)} />
        </div>
      </div>
      <div className="users-table-container">
        <NormalTable 
          headerDetails={headerDetails} 
          pagination
          totalPages={totalPages}
          onPageChange={(page) => setPage(page)}
          page={page}>
          {data.length > 0 ? (
            <>
              {data.map((list) => {
                let {
                  _id,
                  ticketNo,
                  createdAt,
                  user: {userName, emailId},
                  category,
                  description,
                  status,
                  communicationStatus
                } = list;
                return (
                  <tr key={ticketNo}>
                    <td>{ticketNo}</td>
                    <td>{createdAt ? moment(createdAt).add("+05:30").format("DD MMM YY hh:mm a") : ``}</td>
                    <td>{userName}</td>
                    <td>{emailId}</td>
                    <td>{category}</td>
                    <td>{description}</td>
                    <td>{status}</td>
                    <td>
                      <CheckBox name={`${ticketNo}`} value={communicationStatus == 'Sent'} onChange={(e) => onChangeCommStatus(_id, e)}/>
                    </td>
                    <td>
                      <img
                        src={assets.Icons.edit}
                        alt="action"
                        className="customer-care-edit-icon"
                        onClick={() =>
                          navigate(`/main/customer-care/${list._id}`)
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </>
          ) : <tr>
          <td colSpan={9} className="text-center">
            <NoData />
          </td>
        </tr>}
        </NormalTable>
      </div>
    </div>
  );
};

export default CustomerCare;
