import { createSlice } from "@reduxjs/toolkit";
import request from "../../services";
import Exported from "../../services/endpoints";
import { successToast } from "../../utils";

export const tutorialSlice = createSlice({
    name: "tutorial",
    initialState: {
        data: [],
        totalPages: 0,
        tutorialId: ''
    },
    reducers: {
        saveData: (state, action) => {
            state.data = action.payload;
        },
        saveTotalPage: (state, action) => {
            state.totalPages = action.payload;
        },
        saveTutorialId: (state, action) => {
            state.tutorialId = action.payload;
        },
    }
});

export const generateTutorialId = () => async (dispatch) => {

    request({
        url: `${Exported.Endpoints.GENERATE_TUTORIAL_ID}`,
        method: Exported.APIMethods.GET
    }).then(res => {
        dispatch(saveTutorialId(res?.data?.data));
    }).catch(_err => {
    })
}



export const getTutorials = (page, search) => async (dispatch) => {
    let searchQuery = (search) ? `&search=${search}` : ``;

    request({
        url: `${Exported.Endpoints.GET_ALL_TUTORIALS}?page=${page}${searchQuery}`,
        method: Exported.APIMethods.GET,
        isLoader: search == ''
    }).then(res => {
        dispatch(saveData(res?.data?.data?.result));
        dispatch(saveTotalPage(res?.data?.data?.totalPages));
    }).catch(_err => {
    })
}

export const deleteTutorialsById = (_id) => {

    return new Promise((resolve, reject)=>{
        request({
            url: `${Exported.Endpoints.DELETE_TUTORIAL_BY_ID}${_id}`,
            method: Exported.APIMethods.DELETE,
        }).then(res => {
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const createTutorials = (data) => {

    return new Promise((resolve, _reject)=>{
        request({
            url: `${Exported.Endpoints.CREATE_TUTORIAL}`,
            method: Exported.APIMethods.POST,
            data
        }).then(res => {
            successToast(res?.data?.message)
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const updateTutorials = (data) => {

    return new Promise((resolve, _reject)=>{
        request({
            url: `${Exported.Endpoints.UPDATE_TUTORIAL}`,
            method: Exported.APIMethods.PUT,
            data
        }).then(res => {
            successToast(res?.data?.message)
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const getTutorialsById = (_id) => {

    return new Promise((resolve, _reject)=>{
        request({
            url: `${Exported.Endpoints.GET_TUTORIAL_BY_ID}${_id}`,
            method: Exported.APIMethods.GET,
        }).then(res => {
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const { saveData, saveTotalPage, saveTutorialId } = tutorialSlice.actions;

export default tutorialSlice.reducer;
