import { createSlice } from "@reduxjs/toolkit";
import request from "../../services";
import Exported from "../../services/endpoints";
import { errorToast, successToast } from "../../utils";

export const staffSlice = createSlice({
    name: "staff",
    initialState: {
        data: [],
        totalPages: 0,
        empId: ''
    },
    reducers: {
        saveData: (state, action) => {
            state.data = action.payload;
        },
        saveTotalPage: (state, action) => {
            state.totalPages = action.payload;
        },
        saveEmpId: (state, action) => {
            state.empId = action.payload;
        }
    }
});


export const getData = (page, search) => async (dispatch) => {

    let searchQuery = (search) ? `&search=${search}` : ``;

    request({
        url: `${Exported.Endpoints.GET_ALL_STAFF}?page=${page}${searchQuery}`,
        method: Exported.APIMethods.GET,
        isLoader: search == ''
    }).then(res => {
        dispatch(saveData(res?.data?.data?.result));
        dispatch(saveTotalPage(res?.data?.data?.totalPages));
    }).catch(_err => {
    })
}


export const deleteStaffById = (id) => {

    return new Promise((resolve, reject) => {
        request({
            url: `${Exported.Endpoints.DELETE_STAFF_BY_ID}${id}`,
            method: Exported.APIMethods.DELETE
        }).then(res => {
            successToast(res?.data?.message);
            resolve(res);
        }).catch(_err => {
            reject(_err);
        })
    })
}


export const generateEmpId = () => async (dispatch) => {
    request({
        url: `${Exported.Endpoints.GENERATE_EMP_ID}`,
        method: Exported.APIMethods.GET
    }).then(res => {
        dispatch(saveEmpId(res?.data?.data));
    }).catch(_err => {
    })
}

export const createStaff = (data) => {

    return new Promise((resolve, reject) => {
        request({
            url: `${Exported.Endpoints.CREATE_STAFF}`,
            method: Exported.APIMethods.POST,
            data
        }).then(res => {
            successToast(res?.data?.message);
            resolve(res);
        }).catch(_err => {
            reject(_err);
        })
    })
}

export const getStaffById = (id) => {

    return new Promise((resolve, reject) => {
        request({
            url: `${Exported.Endpoints.GET_STAFF_BY_ID}${id}`,
            method: Exported.APIMethods.GET
        }).then(res => {
            resolve(res?.data);
        }).catch(_err => {
            reject(_err);
        })
    })
}

export const editStaff = (data) => {

    return new Promise((resolve, reject) => {
        request({
            url: `${Exported.Endpoints.UPDATE_STAFF}`,
            method: Exported.APIMethods.PUT,
            data
        }).then(res => {
            successToast(res?.data?.message);
            resolve(res);
        }).catch(_err => {
            reject(_err);
        })
    })
}

export const { saveData, saveTotalPage, saveEmpId } = staffSlice.actions;

export default staffSlice.reducer;
