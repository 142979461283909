import React, { useEffect } from "react";
import NormalButton from "../../components/NormalButton";
import NormalModal from "../../components/NormalModal";
import NormalTable from "../../components/NormalTable";
import NormalInput from "../../components/inputField";
import assets from "../../assets";
import { useState } from "react";
import "./styles.scss";
import "../users/styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { deleteTutorialsById, getTutorials } from "../../redux/reducers/tutorialSlice";
import NoData from "../../components/noData";
import ExportedData from "../../assets";
import TooltipComponent from "../../components/tooltip";
import { deleteBadgeById } from "../../redux/reducers/badgesSlice";
import DeleteDropDown from "../../components/deleteModal";
import { useNavigate } from "react-router-dom";
import InputFieldNoValidation from "../../components/inputFieldNoValidation";

const LearnTutorial = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const headerDetails = [
    { label: "Content Id" },
    { label: "Title" },
    { label: "Icon" },
    { label: "Media File" },
    { label: "Duration" },
    { label: "Action" },
  ];

  const dispatch = useDispatch();

  const data = useSelector((state) => state?.tutorials?.data);

  const totalPages = useSelector((state) => state?.tutorials?.totalPages);
  const [page, setPage] = useState(1);

  const [search, setSearch] = useState('');
  const [deleteData, setDeleteData] = useState(null);

  useEffect(() => {
    getData()
  }, [page, search]);


  const getData = () => {
    dispatch(getTutorials(page, search))
  }

  const onClickVideo = (media) => {
    window.open(media, '_blank');
  }

  const onClickDelete = (list) => {
      setDeleteData(list);
      setModalOpen(true)
  }

  const onClickEdit = (list) => {
    navigate("/main/add-tutorial", {
      state: {
        data: list
      }
    })
  }

  const onClickDeleteFromPopup = () => {
    let {
      _id
    } = deleteData;

    deleteTutorialsById(_id).then(_res => {
      getData();
      setModalOpen(false);
      setDeleteData(null)
    }).catch(_err => { })
  }



  return (
    <div className="users-main-container">
      <div className="users-header-container">
        <p className="users-title">Learn Tutorials Content</p>
      </div>
      <div className="d-flex justify-content-end mb-5">
        <div className="tutorial-search-container">
          <label></label>
          <InputFieldNoValidation leftIcon searchIcon placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
        </div>
      </div>
      <div className="users-table-container">
        <NormalTable
          headerDetails={headerDetails}
          pagination
          totalPages={totalPages}
          onPageChange={(page) => setPage(page)}
          page={page}>
          {data.length > 0 ? (
            <>
              {data.map((list) => {
                let { contentId, title, media, icon, duration } = list;
                return (
                  <tr key={contentId}>
                    <td>{contentId}</td>
                    <td>{title}</td>
                    <td>
                      <img src={icon} alt="content" className="tutorial-icon" />
                    </td>
                    <td>
                      <img src={ExportedData.Icons.video} onClick={() => onClickVideo(media)} alt="content" className="media-tutorial-icon" />
                    </td>
                    <td>{duration}</td>
                    <td align="center">
                      <TooltipComponent
                        onClickEdit={() => onClickEdit(list)}
                        onClickDelete={() => onClickDelete(list)} />
                    </td>
                  </tr>
                );
              })}
            </>
          ) :
            <tr>
              <td colSpan={6} className="text-center">
                <NoData />
              </td>
            </tr>}
        </NormalTable>
      </div>
      <DeleteDropDown
        show={modalOpen}
        title={"Delete"}
        screen={"Content"}
        description={"Are you sure do you want to delete?"}
        onClickYes={onClickDeleteFromPopup}
        onHide={() => setModalOpen(false)}
      />
    </div>
  );
};

export default LearnTutorial;
