import React, { useEffect } from "react";
import NormalInput from "../../components/inputField";
import NormalButton from "../../components/NormalButton";
import { useLocation, useNavigate } from "react-router-dom";
import assets from "../../assets";
import NormalSelect from "../../components/NormalSelect";
import { useState } from "react";
import "./styles.scss";
import "../editPremiumFeature/styles.scss";
import { useForm } from "react-hook-form";
import { deleteDropDownById, getCountryDropdown, getDropDown } from "../../redux/reducers/dropdownSlice";
import { DROP_DOWN_CATEGORY, errorToast } from "../../utils";
import DeleteDropDown from "../../components/deleteModal";
import AddDropButton from "../../components/dropDownButton";
import AddDropDown from "../../components/addDropdown";
import InputFieldNoValidation from "../../components/inputFieldNoValidation";
import { useDispatch, useSelector } from "react-redux";
import { createPlan, getAllPlansFeature, getPlanById, updatePlan } from "../../redux/reducers/plansSlice";
import MultiSelect from "../../components/multiSelect";

const AddSubscription = () => {

  let navigate = useNavigate();

  let location = useLocation();

  const { state } = location;

  const [planData, setPlanData] = useState(state?.data);


  const {
    handleSubmit,
    register,
    formState: { errors },
    trigger,
  } = useForm({ mode: "onBlur" });

  const [priceTable, setPriceTable] = useState([])

  const [countries, setCountries] = useState([]);

  const [showDelete, setShowDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [dropdownCategory, setDropdownCategory] = useState('');
  const [show, setShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [planName, setPlanName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedPlanFeatures, setSelectedPlanFeatures] = useState([]);

  const dispatch = useDispatch();


  const [modifiedPlanFeature, setModifiedPlanFeature] = useState([]);

  const [hasFeaturesUpdated, setHasFeaturesUpdated] = useState(false);

  useEffect(() => {
    getCountries()
  }, []);

  useEffect(() => {
    if (modifiedPlanFeature.length > 0 && planData) {
      getPlanById(planData?._id).then(res => {
        updatePlanData(res?.data)
      }).catch(_err => { })
    }
  }, [hasFeaturesUpdated])

  const updatePlanData = (data) => {
    setPlanName(data?.planName);

    let features = [...data?.features];

    let countriesData = [...data?.countries];

    let finalizedFeatures = [];
    let finalizedCountries = [];

    for (let i = 0; i < modifiedPlanFeature.length; i++) {
      const element = modifiedPlanFeature[i];
      const matchingObject = features.find(obj => obj.featureId === element.featureId);
      if (matchingObject) {
        element.value = getValueObjectFromArray(matchingObject.value)
      }
      finalizedFeatures.push(element);
    }
    let priceTableData = []
    for (let i = 0; i < countriesData.length; i++) {
      const element = countriesData[i];

      const matchingObject = countries.find(obj => obj.value === element.countryId);
      if (matchingObject) {
        finalizedCountries.push(matchingObject);

        let item = {
          country: matchingObject?.label, countryId: matchingObject?.value, currency: "", amount: element.amount
        }
        priceTableData.push(item)
      }
    }

    setSelectedCountry(finalizedCountries);
    setPriceTable(priceTableData)
    setModifiedPlanFeature([...finalizedFeatures]);
  }

  const getValueObjectFromArray = (value) => {
    let filter = planFeatureDropdown.filter(it => it.value == value)[0];
    return filter
  }

  const [planFeatureDropdown, setPlanFeatureDropdown] = useState([
    {
      label: <AddDropButton
        label="Add New Options"
        onClick={() => onClickAddDropDown(DROP_DOWN_CATEGORY.PLAN_FEATURE)}
      />,
      isDisabled: true
    }
  ]);




  const getAllPlanFeaturesComp = () => {
    getAllPlansFeature().then(async res => {
      if (res) {
        let list = []
        for (let i = 0; i < res.length; i++) {
          const element = res[i];
          let item = {
            featureId: element._id,
            featureName: element.featureName,
            value: null
          }
          list.push(item);
        }
        setModifiedPlanFeature(list);
        setHasFeaturesUpdated(true)
      }
    }).catch(_err => { })

  }

  const getCountries = () => {
    getCountryDropdown().then(res => {
      setCountries(res);
      getPlanFeaturesDropDown()
    }).catch(_err => { })
  }




  const getPlanFeaturesDropDown = () => {
    getDropDown(DROP_DOWN_CATEGORY.PLAN_FEATURE).then(res => {
      let data = [{
        ...planFeatureDropdown[0]
      }];
      data = [...data, ...res];

      setPlanFeatureDropdown(data);
      getAllPlanFeaturesComp()
    }).catch(_err => { })
  }

  const onSubmit = async () => {

  };


  const handleUpdate = async () => {

    if (validateForm()) {

      let countries = [];

      for (let i = 0; i < priceTable.length; i++) {
        const element = priceTable[i];
        let item = {
          countryId: element.countryId,
          amount: element.amount
        }
        countries.push(item)
      }

      let features = [];

      for (let i = 0; i < modifiedPlanFeature.length; i++) {
        const element = modifiedPlanFeature[i];
        let item = {
          featureId: element.featureId,
          value: element.value?.value
        }
        features.push(item)
      }


      let request = {
        planName: planName,
        countries: countries,
        features: features,
      }

      if (planData) {
        request["_id"] = planData?._id;
        updatePlan(request).then(res => {
          navigate(-1)
        }).catch(_err => {
        })
      } else {
        createPlan(request).then(res => {
          navigate(-1)
        }).catch(_err => {
        })
      }


    }
  };

  const onClickDeleteFromPopup = () => {
    setShowDelete(false)
    let {
      _id,
      dropDownType
    } = deleteData;

    deleteById(_id, dropDownType);
  }

  const deleteById = (id, type) => {
    deleteDropDownById(id).then(_res => {
      getPlanFeaturesDropDown(type);
      setDeleteData(null);
    })
  }

  const onClickDeleteFromList = (data) => {
    setShowDelete(true);
    setDeleteData(data)
  }

  const onClickAddDropDown = (category) => {
    setDropdownCategory(category);
    setEditData(null)
    setShow(true);
  }

  const onAddedData = (_category) => {
    setShow(false);
    setEditData(null)
    getPlanFeaturesDropDown()
  }

  const onClickEdit = (data) => {
    setDropdownCategory(data.dropDownType);
    setEditData({
      label: data.label,
      value: data.value,
      id: data._id
    });
    setShow(true);
  }

  const handleAddNewCountry = (value, index) => {

    let list = [...priceTable];

    list[index].amount = value;

    setPriceTable(list)
  };


  const validateForm = () => {
    if (!planName) {
      errorToast("Please enter plan name.")
      return false;
    }

    for (let i = 0; i < priceTable.length; i++) {
      const element = priceTable[i];

      if (!element.amount) {
        errorToast("Please enter amount for " + element.country)
        return false;
      }
    }

    for (let i = 0; i < modifiedPlanFeature.length; i++) {
      const element = modifiedPlanFeature[i];

      if (!element.value) {
        errorToast("Please select value for " + element.featureName)
        return false;
      }
    }
    return true
  }

  const onChangeCountry = (e) => {
    let list = [];

    for (let i = 0; i < e.length; i++) {
      const element = e[i];
      let filter = priceTable.filter(it => it.country == element.country)[0]
      if (filter) {
        list.push({ country: element?.label, countryId: element?.value, currency: "", amount: filter.amount })
      } else {
        list.push({ country: element?.label, countryId: element?.value, currency: "", amount: null })
      }
    }
    setPriceTable(list)
    setSelectedCountry(e)
  }

  const onChangePlanFeature = (value, index) => {
    let list = [...modifiedPlanFeature];

    list[index].value = value;

    setModifiedPlanFeature(list)

  }

  return (
    <div
      className="free-feature-user-container"
    >
      <div className="back-to-user-container">
        <img
          src={assets.Icons.left_icon}
          alt="left-icon"
          className="left-icon"
        />
        <span
          className="back-to-user"
          onClick={() => navigate("/main/payment")}
        >
          Back to Payment
        </span>
      </div>
      <div className="edit-user col-md-8 ">
        <p className="edit-user-details">Add New Subscription Model</p>
        <div className="col-md-12 d-flex">
          <div className="add-subscription-left-container col-md-6">
            <div className="premium-title-container">
              <span className="premium-feature-main-title">
                New Subscription Model Details
              </span>
            </div>
            <div className="add-subscription-input-container">
              <div className="">
                <label className="add-subscription-input-label">
                  Name of Model
                </label>
                <InputFieldNoValidation
                  type={"text"}
                  value={planName}
                  onChange={(e) => setPlanName(e.target.value)}
                  placeholder="Please enter a name for the new model"
                />
              </div>
              <div className="">
                <label className="add-subscription-input-label countries-offered">
                  Countries Offered (Select All That Apply)
                </label>
                <MultiSelect
                  options={countries}
                  name="location"
                  onChange={(e) => onChangeCountry(e)}
                  borderGreen
                  value={selectedCountry}
                  disableButtons
                  leftIcon
                  countryIcon
                />
              </div>
              {
                priceTable.length > 0 &&
                <div className="update-pricing-main-container">
                  <p className="update-pricing">
                    Update Pricing (Inclusive Of GST)
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {priceTable.map((list, index) => (
                      <div className="update-pricing-container" key={`${index}`}>
                        <div className="col-md-6 update-left-container">
                          {list.country}
                        </div>
                        <div className="col-md-6 update-right-container d-flex align-items-center">
                          <span className="currency col-md-1">
                            {list.currency}
                          </span>
                          <InputFieldNoValidation
                            name={"list.amount"}
                            type={"number"}
                            value={list.amount}
                            placeholder="Enter Amount"
                            onChange={(e) =>
                              handleAddNewCountry(e.target.value, index)
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </form>
                </div>
              }

            </div>
          </div>
          <div className="col-md-7 ms-4">
            <div className="add-subscription-right-container ">
              <div className="premium-title-container">
                <span className="premium-feature-main-title">Features</span>
              </div>
              {modifiedPlanFeature.map((list, index) => {
                return (
                  <div className={`premium-feature col-md-12 px-3`} key={`${index}`}>
                    <div className="col-md-7">
                      <span className="free-feature-title ">
                        {list.featureName}
                      </span>
                    </div>


                    <div className="col-md-5">
                      <NormalSelect
                        options={planFeatureDropdown}
                        value={list.value}
                        onChange={(e) => onChangePlanFeature(e, index)}
                        disableBorder
                        onClickDelete={onClickDeleteFromList}
                        onClickEdit={onClickEdit}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className=" d-flex mt-3">
        <div className="edit-cancel-btn">
          <NormalButton
            label=" CANCEL"
            cancelBtn
            onClick={() => navigate("/main/payment")}
          />
        </div>
        <div className="edit-update-btn">
          <NormalButton label={planData ? "UPDATE" : "CREATE"} mainBg onClick={handleUpdate} />
        </div>
      </div>

      <AddDropDown
        show={show}
        category={dropdownCategory}
        onAddedData={onAddedData}
        editData={editData} />

      <DeleteDropDown
        show={showDelete}
        title={"Delete"}
        screen={"Payment"}
        description={"Are you sure do you want to delete?"}
        onClickYes={onClickDeleteFromPopup}
        onHide={() => setShowDelete(false)}
      />
    </div>
  );
};

export default AddSubscription;
