import React, { useEffect } from "react";
import "./styles.scss";
import "../login/styles.scss";
import "../editTicket/styles.scss";
import { useState } from "react";
import assets from "../../assets";
import { getNotificationData } from "../../redux/reducers/notificationSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import NoData from "../../components/noData";

const Notification = () => {

  const data = useSelector((state) => state?.notification?.data ?? []);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotificationData());
  }, [])

  return (
    <div className="edit-user-container">
      <div className="edit-user col-md-12 ">
        <p className="edit-user-details">Notifications</p>
        {
          (data && typeof data == 'object' && data.length > 0)
            ?
            data.map(it => {
              return (

                <div>
                  <p className="edit-ticket-status">{it.date != "Today" ? moment(it.date).format("DD MMM YYYY") : it.date}</p>
                  {
                    it?.results?.map(({logo, title, description}) => {
                      return (
                        <div className="notification-main-container">
                          <div className="notification-icon-container">
                            <img
                              src={logo}
                              alt="notification-icon"
                              className="notification-icon"
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <span className="notification-title">{title}</span>
                            <span className="notification-content">{description}</span>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>

              )
            })
            :
            <NoData/>
        }



      </div>
    </div>
  );
};

export default Notification;
