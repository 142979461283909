import React, { useEffect, useState } from "react";
import "./styles.scss";
import NormalModal from "../NormalModal";
import NormalButton from "../NormalButton";
import ExportedData from "../../assets";
import { useSelector } from "react-redux";
import { errorToast } from "../../utils";

const DeleteDropDown = ({ show,title, description, onClickYes, onHide, screen }) => {

    const [modalOpen, setModalOpen] = useState(show);

    const userData = useSelector((state)=> state.auth.userData);

    useEffect(()=>{
        setModalOpen(show)
    },[show])

    const hideModal = () =>{
        onHide();
        setModalOpen(false);
    }

    const onClickDelete = () => {

        let permissions = [...userData?.permissions];


        let filterData = permissions.filter(it => it.module == screen && it.isEdit)[0];

        if(filterData){
            onClickYes()
        }else{
            errorToast("You are not granted the authority to effectuate deletion.")
        }


    }

    return (
        <>
            <NormalModal 
                show={modalOpen} 
                setModal={hideModal}
                customModalClass="width75">
                <div>
                    <div className="delete-popup-container">
                        <span className="delete-users">{title}</span>
                        <img
                            src={ExportedData.Icons.close_popup}
                            alt="close"
                            className="popup-close-icon"
                            onClick={() => hideModal()}
                        />
                    </div>
                    <div className="confirm-delete-container">
                        <p className="confirm-delete">
                            {description}
                        </p>
                    </div>
                    <div className="delete-popup-btn-container">
                        <div className="cancel-btn-container">
                            <NormalButton
                                label="NO, CANCEL"
                                cancelBtn
                                onClick={() => hideModal()}
                            />
                        </div>
                        <div className="delete-btn-container">
                            <NormalButton label="YES, DELETE" mainBg onClick={()=> onClickDelete()} />
                        </div>
                    </div>
                </div>
            </NormalModal>
        </>
    );
};

export default DeleteDropDown;