import React, { useEffect } from "react";
import "./styles.scss";
import "../login/styles.scss";
import assets from "../../assets";
import NormalInput from "../../components/inputField";
import NormalSelect from "../../components/NormalSelect";
import { useState } from "react";
import DatePicker from "../../components/DatePicker";
import NormalButton from "../../components/NormalButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getUserById, updateUser } from "../../redux/reducers/userSlice";
import CustomController from "../../components/customController";
import { dropDownValidation } from "../../utils";
import { getCountryDropdown } from "../../redux/reducers/dropdownSlice";
import moment from "moment";

const EditUsers = () => {
  let navigate = useNavigate();

  const location = useLocation();

  let { state } = location;

  const [userData, setUserData] = useState(state?.data);

  const [selectedCountry, setSelectedCountry] = useState(null);

  const [countryOptions, setCountryOptions] = useState([]);
  const [dob, setDob] = useState(null);


  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    control
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    if (userData) {
      let {
        _id
      } = userData;

      getUserById(_id).then(res => {
        setUserData(res?.data);

        let {
          userName,
          emailId,
          dob,
          countryId
        } = res?.data;

        getCountryData(countryId)
        setValue("userName", userName)
        setValue("emailId", emailId);
        setValue("dob", dob);
        setDob(new Date(dob))

      }).catch(_err => {
      })

    }
  }, []);

  const getCountryData = (country) => {
    getCountryDropdown().then(res => {
      let data = [...res];
      setCountryOptions(data);
      if (country) {
        let item = data.filter(it => it.value == country)[0];
        setSelectedCountry(item);
        setValue("country", item?.value)
      }
    }).catch(_err => { })
  }

  const [options] = useState([
    { label: "All", value: 1 },
    { label: "Premium", value: 2 },
    { label: "Free", value: 3 },
  ]);

  const onSubmit = (data) => {

    let request = {
      "_id": userData?._id,
      "emailId": data?.emailId,
      "userName": data?.userName,
      "dob": moment(dob).format("YYYY-MM-DD"),
      "countryId": selectedCountry?.value
    }

    updateUser(request).then(_res=>{
      navigate(-1)
    }).catch(_err=>{})
  };
  const customErrorMessages = {
    required: "Email is required",
    pattern: {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: "Invalid email address",
    },
  };
  return (
    <form className="edit-user-container" onSubmit={handleSubmit(onSubmit)}>
      <div className="back-to-user-container">
        <img
          src={assets.Icons.left_icon}
          alt="left-icon"
          className="left-icon"
        />
        <span className="back-to-user" onClick={() => navigate(-1)}>
          Back to Users
        </span>
      </div>
      <div className="edit-user col-md-6 ">
        <p className="edit-user-details">Edit User Details</p>
        <div className="edit-user-input">
          <div className="edit-input-containers">
            <label className="login-input-label">Username</label>
            <NormalInput
              type={"text"}
              leftIcon
              usernameIcon
              register={register("userName", {
                required: "Username is required",
              })}
              error={errors.userName}
              messages={errors}
              placeholder="Please enter your Username"
            />
          </div>
          <div className="edit-input-containers">
            <label className="login-input-label">Email</label>
            <NormalInput
              type={"text"}
              placeholder={"Please enter your email"}
              register={register("emailId", {
                required: customErrorMessages.required,
                pattern: customErrorMessages.pattern,
              })}
              error={errors.emailId}
              messages={customErrorMessages}
              leftIcon
              emailIcon
            />
          </div>{" "}
          <div className="edit-input-containers ">
            <label className="login-input-label">Date of Birth</label>

            <CustomController
              name="dob"
              control={control}
              data={dob}
              error={errors.dob}
              register={register("dob", dropDownValidation('Country'))}
              render={({ field: { onChange } }) => {
                return (
                  <DatePicker
                    calendarStyle={true}
                    placeHolder={true}
                    isWeekdays={true}
                    format={"dd-MM-yyyy"}
                    placeholderText="Select from date"
                    value={dob}
                    error={errors.dob}
                    onChange={(e) => {
                      setDob(e);
                      onChange("dob")
                    }}
                    messages={errors}
                  />
                );
              }}
            />

          </div>
          <div className="edit-input-containers">
            <label className="login-input-label">Country Of Residence</label>

            <CustomController
              name="country"
              control={control}
              data={selectedCountry}
              error={errors.country}
              register={register("country", dropDownValidation('Country'))}
              render={({ field: { onChange } }) => {
                return (
                  <NormalSelect
                    options={countryOptions}
                    error={errors.country}
                    messages={errors}
                    borderGreen
                    disableButtons
                    value={selectedCountry}
                    onChange={(e) => {
                      setSelectedCountry(e)
                      onChange(e?.value);
                    }}
                    placeholder="Select a plan subscription"
                  />
                );
              }}
            />
          </div>
        </div>
        <div className=" d-flex mt-3">
          <div className="edit-cancel-btn">
            <NormalButton
              label=" CANCEL"
              cancelBtn
              onClick={() => navigate("/main/users")}
            />
          </div>
          <div className="edit-update-btn">
            <NormalButton label=" UPDATE" mainBg />
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditUsers;
