import React, { useEffect, useState } from "react";
import "./styles.scss";
import NormalModal from "../NormalModal";
import NormalButton from "../NormalButton";
import ExportedData from "../../assets";
import { useSelector } from "react-redux";
import { errorToast } from "../../utils";
import moment from "moment";

const PaymentHistory = ({ show, onHide,data = [] }) => {

    const [modalOpen, setModalOpen] = useState(show);

    const userData = useSelector((state) => state.auth.userData);

    useEffect(() => {
        setModalOpen(show)
    }, [show])

    const hideModal = () => {
        onHide();
        setModalOpen(false);
    }

    return (
        <>
            <NormalModal
                show={modalOpen}
                setModal={hideModal}
                customModalClass="width75">
                <div className="container">
                    <div>
                        <div className="delete-popup-container">
                            <span className="delete-users">Payment History</span>
                            <img
                                src={ExportedData.Icons.close_popup}
                                alt="close"
                                className="popup-close-icon"
                                onClick={() => hideModal()}
                            />
                        </div>
                        <table className="history-table">
                            <tr className="history-row">
                                <th className="history-head">Date</th>
                                <th className="history-head">Amount</th>
                            </tr>
                            {
                                data?.length > 0 ? data?.map(it => {
                                    return (
                                        <tr className="history-row">
                                            <td className="history-row">{moment(it?.chargedAt).format('DD MMM YYYY')}</td>
                                            <td className="history-row">{it?.amount}</td>
                                        </tr>
                                    )
                                })
                                : <tr> <td className="history-row" colSpan={2}>No Data</td></tr>
                            }
                        </table>
                    </div>
                </div>
            </NormalModal>
        </>
    );
};

export default PaymentHistory;