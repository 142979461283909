import React, { useEffect } from "react";
import assets from "../../assets";
import "./styles.scss";
import "../editUsers/styles.scss";
import "../users/styles.scss";
import NormalButton from "../../components/NormalButton";
import NormalSelect from "../../components/NormalSelect";
import NormalInput from "../../components/inputField";
import CheckBox from "../../components/checkBox";
import NormalTable from "../../components/NormalTable";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteSubscriptionById, getAllSubscription, saveData, updateAllStatus, updateDataBasedOnCheckbox } from "../../redux/reducers/subscriptionSlice";
import moment from "moment";
import DeleteDropDown from "../../components/deleteModal";
import { CSV_FILE_NAME, FILE_NAME, downloadFileFromUrl, errorToast, fileDownload } from "../../utils";
import Exported from "../../services/endpoints";
import NoData from "../../components/noData";
import { getCountryDropdown } from "../../redux/reducers/dropdownSlice";
import ExportedData from "../../assets";
import InputFieldNoValidation from "../../components/inputFieldNoValidation";
import PaymentHistory from "../../components/paymentHistory";
import RefundDetails from "../../components/refundDetails";

const ManageSubscriptions = () => {
  let navigate = useNavigate();
  const options = [
    { label: "All", value: 1 },
    { label: "India", value: 2 },
    { label: "Uk", value: 3 },
  ];

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showPaymentHistory, setShowPaymentHistory] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [country, setCountry] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);
  const totalPages = useSelector((state) => state?.subscriptions?.totalPages || 0);

  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedStatus, setCheckedStatus] = useState(false);

  const data = useSelector((state) => state?.subscriptions?.data);

  const [showRefundDetails, setShowRefundDetails] = useState(false);
  const [refundData, setRefundData] = useState(null);

  const [selectedCount, setSelectedCount] = useState(0);

  const onChangeCheckBox = (e) => {
    setCheckedAll(e.target?.checked);

    dispatch(updateAllStatus(e.target.checked));

  }
  const headerDetails = [
    { label: <CheckBox value={checkedAll} disabled={data.length == 0} onChange={onChangeCheckBox} /> },
    { label: "User Id" },
    { label: "Username" },
    { label: "Email" },
    { label: "Payment Duration" },
    { label: "Payment System" },
    { label: "Country" },
    { label: "Created On" },
    { label: "Updated On" },
    { label: "Transaction ID" },
    { label: "Amount (₹)" },
    { label: "Total Payment (₹)" },
    { label: "Payment History" },
    { label: "Refund Request" },
    { label: "Subscription Status" },
    { label: "Action" },
  ];


  const [fromDate, setFromDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    getData()
  }, [page, search, country, fromDate, endDate]);

  useEffect(() => {
    getCountryData();
  }, []);

  useEffect(() => {
    const checkedCount = data.filter(item => item.checked).length;
    setSelectedCount(`${checkedCount}`)
    setCheckedStatus(!(checkedCount == 0));
    setCheckedAll(data.length > 0 && checkedCount == data.length)
  }, [data])

  const getData = () => {
    dispatch(getAllSubscription(page, search, country, fromDate, endDate))
  }

  const getCountryData = () => {
    getCountryDropdown().then(res => {
      let data = [...res];
      setCountryOptions(data);
    })
  }

  const onClickDownloadInvoice = (item) => {
    
  }

  const onClickDelete = (item) => {

    let {
      _id
    } = item;

    let list = [];

    list.push(_id);

    let data = {
      "ids": list
    }
    setDeleteData(data);
    setShowDeletePopup(true);
  }

  const onClickDeleteFromPopup = () => {
    deleteSubscriptionById(deleteData).then(_res => {
      getData();
      setShowDeletePopup(false);
      setDeleteData(null)
    }).catch(_err => { })
  }


  const onClickExport = () => {
    fileDownload(`${Exported.Endpoints.GET_ALL_SUBSCRIPTION}?export=1`, CSV_FILE_NAME.SUBSCRIPTION_LIST);
  }


  const onClickClose = () => {
    setCheckedAll(false);
    setSelectedCount(0);
    setCheckedStatus(false);
    dispatch(updateAllStatus(false))
  }

  const onChangeCheckBoxFromList = (value, index) => {

    dispatch(updateDataBasedOnCheckbox({
      index, value
    }));

    setCheckedStatus(true)
  }

  const getRequest = () => {
    let item = [...data];
    let list = []
    item.map(it => {
      if (it.checked) {
        list.push({
          paymentId: it?.paymentId,
          type: it?.type
        });
      }
    })

    return list

  }

  const onClickDeleteAll = () => {
    let request = getRequest();
    if (request.length > 0) {
      let data = {
        "ids": [...request]
      }
      setDeleteData(data);
      setShowDeletePopup(true);
    } else {
      errorToast("Please select atleast one transaction to delete")
    }
  }

  const onClickDownload = () => {
    let request = getRequest();

    if (request.length > 0) {
      let data = {
        "items": [...request]
      }
      downloadFileFromUrl(Exported.Endpoints.DOWNLOAD_INVOICE, FILE_NAME.INVOICE,data, request.length == 1 ? 'pdf' : 'zip');
    } else {
      errorToast("Please select atleast on transaction to download invoice")
    }
  }

  return (
    <div className="manage-subscription-container">
      <div className="back-to-user-container">
        <img
          src={assets.Icons.left_icon}
          alt="left-icon"
          className="left-icon"
        />
        <span
          className="back-to-user"
          onClick={() => navigate("/main/payment")}
        >
          Back to Payment
        </span>
      </div>
      <div className="users-header-container mt-3">
        <p className="users-title">Manage Subscriptions</p>
        <NormalButton label="EXPORT CSV" mainBg onClick={onClickExport} />
      </div>
      {
        checkedStatus ?
          <div className="checked-container">
            <div className="selected-container" onClick={() => onClickClose()}>
              <img
                alt="close"
                className="close-popup"
                src={ExportedData.Icons.close} />
              <label>{selectedCount} Selected</label>
            </div>

            <div className="selected-container">
              <img
                alt="close"
                className="checked-delete-icon"
                onClick={() => onClickDownload()}
                src={ExportedData.Icons.download_invoice} />
              {/* <img
                alt="close"
                className="delete-icon"
                onClick={() => onClickDeleteAll()}
                src={ExportedData.Icons.delete} /> */}
            </div>
          </div> :

          <div className="manage-input-container ">
            <div className="search-container">
              <label></label>
              <InputFieldNoValidation leftIcon searchIcon placeholder="Search" onChange={(e) => setSearch(e?.target?.value)} />
            </div>

            <div className="country-container">
              <label className="subscription-label">Location</label>
              <NormalSelect
                options={countryOptions}
                name="location"
                disableButtons
                borderGreen
                onChange={(e) => {
                  setCountry(e?.label?.props?.data?.label);
                }} />
            </div>
          </div>
      }
      <div style={{
        marginTop: checkedStatus ? 15 : 0
      }}>
        <NormalTable
          headerDetails={headerDetails}
          pagination
          totalPages={totalPages}
          onPageChange={(page) => setPage(page)}
          onClickSave={(data) => {
            setEndDate(data.endDate);
            setFromDate(data.fromDate);
          }}
          page={page}>
          {data.length > 0 ? (
            <>
              {data.map((list, index) => {
                let {
                  _id,
                  userName,
                  emailId,
                  duration,
                  amount,
                  type,
                  countryName,
                  subscriptionId,
                  createdOn,
                  updatedOn,
                  totalAmountIndividual,
                  checked,
                  paymentStatus,
                  paymentHistories,
                  refundDetails,
                  paymentId
                } = list;
                return (
                  <tr key={_id}>
                    <td>
                      <CheckBox value={checked} onChange={(e) => onChangeCheckBoxFromList(e.target?.checked, index)} />
                    </td>
                    <td>{`${_id}`.slice(-8).toUpperCase()}</td>
                    <td>{userName}</td>
                    <td>{emailId}</td>
                    <td>{`${duration} Month`}</td>
                    <td>{type}</td>
                    <td>{countryName}</td>
                    <td>{moment(createdOn).format("DD MMM YYYY")}</td>
                    <td>{moment(updatedOn).format("DD MMM YYYY")}</td>
                    <td>{subscriptionId}</td>
                    <td>{amount}</td>
                    <td>{totalAmountIndividual}</td>
                    <td className="underlined-text"
                      onClick={() => {
                        setShowPaymentHistory(true)
                        setPaymentHistory(paymentHistories)
                      }}>Payment History</td>
                    <td className="underlined-text"
                      onClick={() => {
                        setShowRefundDetails(true)
                        setRefundData({
                          ...refundDetails,
                          paymentId,
                          type
                        })
                      }}>Refund Details</td>
                    <td>{paymentStatus}</td>
                    <td>
                      <img
                        src={assets.Icons.download_invoice}
                        alt="action"
                        className="delete-icon"
                        onClick={() => onClickDownloadInvoice(list)}
                      />
                    </td>
                  </tr>
                );
              })}
            </>
          ) : <tr>
            <td colSpan={16} className="text-center">
              <NoData />
            </td>
          </tr>}
        </NormalTable>
      </div>

      <DeleteDropDown
        show={showDeletePopup}
        title={"Delete"}
        screen={"Payment"}
        description={"Are you sure do you want to delete?"}
        onClickYes={onClickDeleteFromPopup}
        onHide={() => setShowDeletePopup(false)}
      />

      <PaymentHistory
        show={showPaymentHistory}
        onHide={()=> {
          setShowPaymentHistory(false)
          setPaymentHistory([])
        }}
        data={paymentHistory}/>

      <RefundDetails
        show={showRefundDetails}
        onHide={()=> {
          setShowRefundDetails(false)
          setRefundData(null)
        }}
        onUpdated={() => {getData()}}
        data={refundData}/>
    </div>
  );
};

export default ManageSubscriptions;
