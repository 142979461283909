import { createSlice } from "@reduxjs/toolkit";
import request from "../../services";
import Exported from "../../services/endpoints";
import { successToast } from "../../utils";

export const subscriptionSlice = createSlice({
    name: "subscriptions",
    initialState: {
        data: [],
        totalPages: 0,
        recentSubscription: []
    },
    reducers: {
        saveData: (state, action) => {
            state.data = action.payload;
        },
        saveRecentSubscription: (state, action) => {
            state.recentSubscription = action.payload;
        },
        saveTotalPage: (state, action) => {
            state.totalPages = action.payload;
        },
        updateDataBasedOnCheckbox: (state, action) => {
            const { index, value } = action.payload;
            let item = [...state.data];
            item[index].checked = value;
            state.data = item;
        },
        updateAllStatus: (state, action) => {
            const checked = action.payload;
            const updatedData = state.data.map(item => ({
                ...item,
                checked: checked
            }));
            state.data = updatedData;
        }
    }
});

export const getRecentSubscription = () => async (dispatch) => {

    request({
        url: Exported.Endpoints.RECENT_SUBSCRIPTION,
        method: Exported.APIMethods.GET,
        isLoader:false
    }).then(res => {
        dispatch(saveRecentSubscription(res?.data?.data));
    }).catch(_err => {
    })

}


export const getAllSubscription = (page, search, country, fromDate, endDate) => async (dispatch) => {
    let searchQuery = (search) ? `&search=${search}` : ``;
    let locationQuery = (country) ? `&country=${country}` : ``;

    let dateQuery = (fromDate && endDate) ? `&fromDate=${fromDate}&endDate=${endDate}` : ``

    request({
        url: `${Exported.Endpoints.GET_ALL_SUBSCRIPTION}?page=${page}${searchQuery}${locationQuery}${dateQuery}`,
        method: Exported.APIMethods.GET,
        isLoader: search == ''
    }).then(res => {
        dispatch(saveData(res?.data?.data?.results));
        dispatch(saveTotalPage(res?.data?.data?.totalPages));
    }).catch(_err => {
    })

}

export const deleteSubscriptionById = (data) => {

    return new Promise((resolve, _reject) => {
        request({
            url: Exported.Endpoints.DELETE_SUBSCRIPTION,
            method: Exported.APIMethods.DELETE,
            data
        }).then(res => {
            successToast(res?.data?.message);
            resolve(res)
        }).catch(_err => {
        })
    })

}

export const downloadInvoice = (data) => {

    return new Promise((resolve, _reject) => {
        request({
            url: Exported.Endpoints.DOWNLOAD_INVOICE,
            method: Exported.APIMethods.POST,
            data
        }).then(res => {
            successToast(res?.data?.message);
            resolve(res)
        }).catch(_err => {
        })
    })

}



export const { saveData, saveRecentSubscription, saveTotalPage, updateDataBasedOnCheckbox, updateAllStatus } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
