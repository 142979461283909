import React, { useEffect, useState } from "react";
import NormalTable from "../../components/NormalTable";
import "../staff/styles.scss";
import "../users/styles.scss";
import NoData from '../../components/noData';
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { getAppConfigs, updateAppConfig } from "../../redux/reducers/appConfigsSlice";

const CalendarPreferences = () => {

  const dispatch = useDispatch();

  const data = useSelector((state) => state?.appConfigs?.data || [])

  const headerDetails = [
    { label: "S No" },
    { label: "Type" },
    { label: "Action" },
  ];

  useEffect(() => {
    dispatch(getAppConfigs())
  }, [])

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    justifyContent: 'center',
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: '#0a6a78',
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      backgroundColor: "white",
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const handleSwitchChange = (index) => {
    const updatedData = [...data];
    let {
      _id,
      key,
    } = updatedData[index];
    
    let request = {
      _id,
      key,
      value: !updatedData[index].value
    }

    dispatch(updateAppConfig(request));
  };


  return (
    <div className="users-main-container">
      <div className="users-header-container">
        <p className="users-title">Application Configs</p>
      </div>
      <div className="users-table-container">
        <NormalTable
          headerDetails={headerDetails}
        >
          {data.length > 0 ? (
            <>
              {data.map((list, index) => {
                let {
                  _id,
                  key,
                  value,
                } = list;
                return (
                  <tr key={_id}>
                    <td>{index + 1}</td>
                    <td>{key}</td>
                    <td className="">
                      <div className="d-flex justify-content-center">

                        <AntSwitch
                          checked={value}
                          onChange={() => handleSwitchChange(index)}
                        />
                      </div>

                    </td>
                  </tr>
                );
              })}
            </>
          ) : <tr>
            <td colSpan={8} className="text-center">
              <NoData />
            </td>
          </tr>
          }
        </NormalTable>
      </div>



    </div>
  );
};

export default CalendarPreferences;
