import { createSlice } from "@reduxjs/toolkit";
import request from "../../services";
import Exported from "../../services/endpoints";

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        isRememberMe: false,
        userData: null
    },
    reducers: {
        saveRememberMe: (state, action) =>{
            state.isRememberMe = action.payload;
        },
        saveUserData: (state, action) =>{
            state.userData = action.payload;
        },
    }
});


export const login = (data) => {
    return new Promise((resolve, reject)=>{
        request({
            url: Exported.Endpoints.LOGIN,
            method: Exported.APIMethods.POST,
            data
        }).then(res=>{
            resolve(res);
        }).catch(err=>{
            reject(err);
        })
    })
}

export const forgotPassword = (data) => {
    return new Promise((resolve, reject)=>{
        request({
            url: Exported.Endpoints.FORGOT_PASSWORD,
            method: Exported.APIMethods.POST,
            data
        }).then(res=>{
            resolve(res);
        }).catch(err=>{
            reject(err);
        })
    })
}

export const verifyOtp = (data) => {
    return new Promise((resolve, reject)=>{
        request({
            url: Exported.Endpoints.VERIFY_OTP,
            method: Exported.APIMethods.POST,
            data
        }).then(res=>{
            resolve(res);
        }).catch(err=>{
            reject(err);
        })
    })
}

export const resetPassword = (data) => {
    return new Promise((resolve, reject)=>{
        request({
            url: Exported.Endpoints.RESET_PASSWORD,
            method: Exported.APIMethods.POST,
            data
        }).then(res=>{
            resolve(res);
        }).catch(err=>{
            reject(err);
        })
    })
}

export const updateProfile = (data) => {
    return new Promise((resolve, reject)=>{
        request({
            url: Exported.Endpoints.UPDATE_PROFILE,
            method: Exported.APIMethods.PUT,
            data
        }).then(res=>{
            resolve(res);
        }).catch(err=>{
            reject(err);
        })
    })
}

export const getStaffById = (id) => {
    return new Promise((resolve, reject)=>{
        request({
            url: `${Exported.Endpoints.STAFF_BY_ID}${id}`,
            method: Exported.APIMethods.GET
        }).then(res=>{
            resolve(res);
        }).catch(err=>{
            reject(err);
        })
    })
}

export const { saveRememberMe, saveUserData } = authSlice.actions;

export default authSlice.reducer;
