import React, { useEffect, useRef, useState } from "react";
import "../login/styles.scss";
import NormalInput from "../../components/inputField";
import NormalButton from "../../components/NormalButton";
import { useNavigate, useParams } from "react-router-dom";
import assets from "../../assets";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ExportedData from "../../assets";
import NormalSelect from "../../components/NormalSelect";
import {
  forgotPassword,
  getStaffById,
  saveUserData,
  updateProfile,
} from "../../redux/reducers/authSlice";
import { S3_BUCKET_FOLDER, dropDownValidation, upload } from "../../utils";
import { Toast } from "../../components/toast";
import CustomController from "../../components/customController";
const ProfileSettings = () => {
  let navigate = useNavigate();

  const userData = useSelector((state) => state?.auth?.userData ?? {});

  const [selectedGender, setSelectedGender] = useState(null);

  const [isEdit, setIsEdit] = useState(false);

  const fileInputRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    let { _id } = userData;

    getStaffByIdMethod(_id);
  }, []);

  const [options] = useState([
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Other", value: "other" },
  ]);

  const getStaffByIdMethod = (id) => {
    getStaffById(id)
      .then((res) => {
        let { firstName, lastName, gender, emailId, phone, password, photo } =
          res.data?.data;

        setValue("firstName", firstName);
        setValue("lastName", lastName);
        setValue("gender", gender);
        let selectGender = options.filter(it => it.value == `${gender}`.toLowerCase())[0];
        setSelectedGender(selectGender)

        setValue("emailId", emailId);
        setValue("phone", phone);
        setValue("password", password.slice(0, 8));
        setPhoto(photo);

        let data = {
          ...userData,
          ...res?.data?.data,
        };

        dispatch(saveUserData(data));
      })
      .catch((_err) => { });
  };


  const [photo, setPhoto] = useState(userData?.photo);
  const [photoFile, setPhotoFile] = useState(null);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    control,
    clearErrors
  } = useForm({ mode: "onBlur" });

  const customErrorMessages = {
    required: "Email is required",
    pattern: {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: "Invalid email address",
    },
  };

  const onSubmit = (data) => {
    let request = {
      _id: userData._id,
      firstName: data.firstName,
      lastName: data.lastName,
      gender: selectedGender?.value,
      phone: data.phone,
      photo: userData?.photo,
    };

    if (photoFile) {
      upload(S3_BUCKET_FOLDER.STAFFS, photoFile)
        .then((res) => {
          request["photo"] = res.fileName;
          updateProfileData(request);
        })
        .catch((_err) => { });
    } else {
      updateProfileData(request);
    }
  };

  const updateProfileData = (data) => {
    updateProfile(data)
      .then((res) => {
        Toast({ type: "success", message: res?.data?.message });
        getStaffByIdMethod(data["_id"]);
        setIsEdit(false);
      })
      .catch((_err) => { });
  };

  const triggerOtp = () => {
    let request = {
      emailId: userData?.emailId,
      userType: "staff",
    };
    forgotPassword(request)
      .then((res) => {
        navigate("/auth/verify-otp", {
          state: {
            emailId: userData.emailId,
            forgotPassword: false,
          },
        });
      })
      .catch((_err) => { });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setPhotoFile(selectedFile);
    }

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setPhoto(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };


  const onClickEditIcon = () => {
    if (isEdit) {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
        fileInputRef.current.click();
      }
    }
  }


  const deleteProfileImage = () => {
    if (isEdit) {
      setPhoto(ExportedData.Images.user_placeholder);
      setPhotoFile(null)
    }
  }

  const onClickCancel = () => {
    clearErrors();
    setIsEdit(!isEdit);
    let { _id } = userData;

    getStaffByIdMethod(_id);
  }

  return (
    <form className="edit-user-container" onSubmit={handleSubmit(onSubmit)}>
      <div className="edit-user col-md-6 ">
        <div className="d-flex align-items-center">
          <p className="edit-user-details">Profile Settings</p>
          {!isEdit && (
            <div className="col-md-1 ms-3">
              <NormalButton
                label={"EDIT"}
                cancelBtn
                onClick={() => setIsEdit(true)}
              />
            </div>
          )}
        </div>
        <div className="col-md-12 d-flex">
          <div className=" col-md-6 ">
            <div className="">
              <label className="login-input-label">First Name</label>
              <NormalInput
                type={"text"}
                register={register("firstName", {
                  required: "First Name is required",
                })}
                disabled={!isEdit}
                error={errors.firstName}
                messages={errors}
                placeholder="Please Enter First Name"
              />
            </div>
            <div className="">
              <label className="login-input-label">Last Name</label>
              <NormalInput
                type={"text"}
                register={register("lastName", {
                  required: "Last Name is required",
                })}
                error={errors.lastName}
                messages={errors}
                disabled={!isEdit}
                placeholder="Please Enter Last Name"
              />
            </div>
            <div className="">
              <label className="login-input-label">Gender</label>
              <CustomController
                name="gender"
                control={control}
                data={selectedGender}
                error={errors.gender}
                register={register("gender", dropDownValidation('Gender'))}
                render={({ field: { onChange } }) => {
                  return (
                    <NormalSelect
                      options={options}
                      borderGreen
                      disabled={!isEdit}
                      error={errors.gender}
                      value={selectedGender}
                      messages={errors}
                      onChange={(e) => {
                        setSelectedGender(e);
                        onChange(e?.value);
                      }}
                      placeholder="Select a Category or create a new one"
                    />
                  );
                }}
              />

            </div>
            <div className="">
              <label className="login-input-label">Email</label>
              <NormalInput
                type={"text"}
                placeholder={"Please enter your email"}
                register={register("emailId", {
                  required: customErrorMessages.required,
                  pattern: customErrorMessages.pattern,
                })}
                disabled={true}
                error={errors.emailId}
                messages={customErrorMessages}
              />
            </div>
            <div className="">
              <label className="login-input-label">Phone</label>
              <NormalInput
                type={"text"}
                register={register("phone", {
                  required: "Phone Number is required",
                })}
                disabled={!isEdit}
                error={errors.phone}
                messages={errors}
                placeholder="Please Enter Your Phone Number"
              />
            </div>
            {!isEdit && (
              <div className="">
                <label className="login-input-label">Password</label>
                <NormalInput
                  type={"password"}
                  register={register("password", {
                    required: "Password is required",
                  })}
                  error={errors.password}
                  messages={errors}
                  disabled={true}
                  placeholder="Please Enter Your Password"
                />
                <span className="change-password" onClick={() => triggerOtp()}>
                  Change password
                </span>
              </div>
            )}
          </div>
          <input
            type="file"
            style={{ display: "none" }}
            ref={fileInputRef}
            accept="image/png, image/jpeg"
            onChange={handleFileChange}
            multiple={false}
          />
          <div className=" col-md-6 ms-5 d-flex align-items-center  ">
            <div className="profile-image-container">
              <img
                src={photo}
                alt="profile"
                onClick={() => onClickEditIcon()}
                className="profile-settings-image"
                onError={(e) => {
                  e.target.src = ExportedData.Images.user_placeholder;
                  e.target.onerror = null; // To prevent infinite fallback loops
                }}
              />
              {isEdit && (
                <img
                  src={assets.Icons.profile_edit}
                  alt="edit"
                  onClick={() => onClickEditIcon()}
                  className="profile-edit"
                />
              )}

              {isEdit && (
                <p onClick={() => deleteProfileImage()} className="profile-delete">Delete</p>
              )}

            </div>



          </div>

        </div>

        {isEdit && (
          <div className=" d-flex mt-5">
            <div className="edit-cancel-btn">
              <NormalButton
                label=" CANCEL"
                cancelBtn
                onClick={() => onClickCancel()}
              />
            </div>
            <div className="edit-update-btn">
              <NormalButton label={`${"UPDATE"}`} mainBg />
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default ProfileSettings;
