import { createSlice } from "@reduxjs/toolkit";
import request from "../../services";
import Exported from "../../services/endpoints";
import { successToast } from "../../utils";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        data: [],
        totalPages: 0,

        deletedUsers: [],
        totalPagesDeletedUsers: 0
    },
    reducers: {
        saveData: (state, action) => {
            state.data = action.payload;
        },
        saveTotalPage: (state, action) => {
            state.totalPages = action.payload;
        },
        updateDataBasedOnCheckbox: (state, action) => {
            const { index, value } = action.payload;
            let item = [...state.data];
            item[index].checked = value;
            state.data = item;
        },
        updateAllStatus: (state, action) => {
            const checked = action.payload;
            const updatedData = state.data.map(item => ({
                ...item,
                checked: checked
            }));
            state.data = updatedData;
        },
        saveDeleteData: (state, action) => {
            state.deletedUsers = action.payload;
        },
        saveTotalPageDeletedUsers: (state, action) => {
            state.totalPagesDeletedUsers = action.payload;
        },
    }
});


export const getUserData = (page, search, location, subscription, permission, os, signInMode, paymentType) => async (dispatch) => {

    let searchQuery = (search) ? `&search=${search}` : ``;
    let locationQuery = (location) ? `&country=${location}` : ``;
    let subscriptionQuery = (subscription) ? `&subscription=${subscription}` : ``;
    let permissionQuery = (permission) ? `&permission=${permission}` : ``;
    let osQuery = (os) ? `&os=${os}` : ``;
    let signInModeQuery = (signInMode) ? `&signInMode=${signInMode}` : ``;
    let paymentTypeQuery = (paymentType) ? `&paymentType=${paymentType}` : ``;

    request({
        url: `${Exported.Endpoints.GET_ALL_USERS}?page=${page}${searchQuery}${locationQuery}${subscriptionQuery}${permissionQuery}${osQuery}${signInModeQuery}${paymentTypeQuery}`,
        method: Exported.APIMethods.GET,
        isLoader: search == ''
    }).then(res => {
        dispatch(saveData(res?.data?.data?.results));
        dispatch(saveTotalPage(res?.data?.data?.totalPages));
    }).catch(_err => {
    })
}


export const deleteUserById = (data) => {

    return new Promise((resolve, reject) => {
        request({
            url: `${Exported.Endpoints.DELETE_USER_BY_ID}`,
            method: Exported.APIMethods.DELETE,
            data: data
        }).then(res => {
            successToast(res?.data?.message);
            resolve(res);
        }).catch(_err => {
            reject(_err);
        })
    })
}



export const getUserById = (id) => {

    return new Promise((resolve, reject) => {
        request({
            url: `${Exported.Endpoints.GET_USER_BY_ID}${id}`,
            method: Exported.APIMethods.GET
        }).then(res => {
            resolve(res?.data);
        }).catch(_err => {
            reject(_err);
        })
    })
}

export const updateUser = (data) => {

    return new Promise((resolve, reject) => {
        request({
            url: `${Exported.Endpoints.UPDATE_USER}`,
            method: Exported.APIMethods.PUT,
            data
        }).then(res => {
            successToast(res?.data?.message);
            resolve(res);
        }).catch(_err => {
            reject(_err);
        })
    })
}

export const getUniversalFiltersDropdowns = () => {

    return new Promise((resolve, reject) => {
        request({
            url: `${Exported.Endpoints.UNIVERSAL_FILTER_GET}`,
            method: Exported.APIMethods.GET,
        }).then(res => {
            resolve(res?.data?.data);
        }).catch(_err => {
            reject(_err);
        })
    })
}

export const applyUniversalFilters = (data) => {

    return new Promise((resolve, reject) => {
        request({
            url: `${Exported.Endpoints.APPLY_UNIVERSAL_FILTER}`,
            method: Exported.APIMethods.POST,
            data
        }).then(res => {
            resolve(res?.data?.data);
        }).catch(_err => {
            reject(_err);
        })
    })
}

export const applyPromo = (data) => {

    return new Promise((resolve, reject) => {
        request({
            url: `${Exported.Endpoints.APPLY_UNIVERSAL_PROMO}`,
            method: Exported.APIMethods.POST,
            data
        }).then(res => {
            successToast(res?.data?.message)
            resolve(res?.data?.data);
        }).catch(_err => {
            reject(_err);
        })
    })
}



export const getAllDeletedUsers = (page, search) => async (dispatch) => {

    let searchQuery = (search) ? `&search=${search}` : ``;

    request({
        url: `${Exported.Endpoints.GET_ALL_DELETED_USERS}?page=${page}${searchQuery}`,
        method: Exported.APIMethods.GET,
        isLoader: search == ''
    }).then(res => {
        dispatch(saveDeleteData(res?.data?.data?.results));
        dispatch(saveTotalPageDeletedUsers(res?.data?.data?.totalPages));
    }).catch(_err => {
    })
}


export const reActivateUsers = (data) => {
    return new Promise((resolve, reject) => {
        request({
            url: `${Exported.Endpoints.RE_ACTIVATE_USER}`,
            method: Exported.APIMethods.PATCH,
            data
        }).then(res => {
            successToast(res?.data?.message)
            resolve(res?.data?.data);
        }).catch(_err => {
            reject(_err);
        })
    })
}



export const { saveData, saveTotalPage,updateAllStatus, updateDataBasedOnCheckbox,
saveDeleteData, saveTotalPageDeletedUsers } = userSlice.actions;

export default userSlice.reducer;
