import React, { useEffect } from "react";
import NormalButton from "../../components/NormalButton";
import NormalModal from "../../components/NormalModal";
import NormalTable from "../../components/NormalTable";
import NormalSelect from "../../components/NormalSelect";
import NormalInput from "../../components/inputField";
import assets from "../../assets";
import { useState } from "react";
import { useForm } from "react-hook-form";
import "./styles.scss";
import "../users/styles.scss";
import { deleteSelfCareById, getSelfCare } from "../../redux/reducers/selfCareSlice";
import DeleteDropDown from "../../components/deleteModal";
import NoData from "../../components/noData";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TooltipComponent from "../../components/tooltip";
import { DROP_DOWN_CATEGORY, downloadFile } from "../../utils";
import { getDropDown } from "../../redux/reducers/dropdownSlice";
import ExportedData from "../../assets";
import InputFieldNoValidation from "../../components/inputFieldNoValidation";

const SelfCareContent = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const headerDetails = [
    { label: "Content Id" },
    { label: "Category" },
    { label: "Title" },
    { label: "Artwork" },
    { label: "Icon" },
    { label: "Tags" },
    { label: "QR" },
    { label: "Media File" },
    { label: "Subscription" },
    { label: "Action" },
  ];
  const options = [
    { label: "Tasks", value: 1 },
    { label: "Premium", value: 2 },
    { label: "Free", value: 3 },
  ];

  const [categoryOptions, setCatergoryOptions] = useState([]);
  const [category, setCategory] = useState('');

  const dispatch = useDispatch();

  const data = useSelector((state) => state?.selfCare?.data);

  const totalPages = useSelector((state) => state?.selfCare?.totalPages);
  const [page, setPage] = useState(1);

  const [search, setSearch] = useState('');
  const [deleteData, setDeleteData] = useState(null);

  useEffect(() => {
    getCategoryDropDown();
  }, []);

  const getCategoryDropDown = () => {
    getDropDown(DROP_DOWN_CATEGORY.SELF_CARE_CATEGORY).then(res => {
      setCatergoryOptions([...res]);
    })
  }

  useEffect(() => {
    getData()
  }, [page, search, category]);


  const getData = () => {
    dispatch(getSelfCare(page, search, category))
  }

  const onClickDelete = (list) => {
    setDeleteData(list);
    setModalOpen(true)
  }

  const onClickEdit = (list) => {
    navigate("/main/add-self-care", {
      state: {
        data: list
      }
    })
  }

  const onClickDeleteFromPopup = () => {
    let {
      _id
    } = deleteData;

    deleteSelfCareById(_id).then(_res => {
      getData();
      setModalOpen(false);
      setDeleteData(null)
    }).catch(_err => { })
  }


  const handleDownloadClick = (imageUrl, title) => {
    downloadFile(imageUrl, title)
  };

  const onClickVideo = (media) => {
    window.open(media, '_blank');
  }


  return (
    <div className="users-main-container">
      <div className="users-header-container">
        <p className="users-title">Self Care Content</p>
      </div>
      <div className="d-flex justify-content-end mb-5">
        <div className="search-container">
          <label></label>
          <InputFieldNoValidation leftIcon searchIcon placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
        </div>
        <div className="subscription-container ">
          <label className="subscription-label">Category</label>
          <NormalSelect
            options={categoryOptions}
            name="subscription"
            borderGreen
            disableButtons={true}
            onChange={(e) => {
              setCategory(e?.value)
            }}
          />
        </div>
      </div>
      <div className="users-table-container">
        <NormalTable
          headerDetails={headerDetails}
          pagination
          totalPages={totalPages}
          onPageChange={(page) => setPage(page)}
          page={page}>
          {data.length > 0 ? (
            <>
              {data.map((list) => {
                let {
                  selfCareId,
                  category,
                  title,
                  artWork,
                  tags,
                  mediaFile,
                  icon,
                  subscription,
                  qrCode,
                  header
                } = list;
                return (
                  <tr key={selfCareId}>
                    <td>{selfCareId}</td>
                    <td>{category}</td>
                    <td>{title}</td>
                    <td>
                      {artWork ? <img
                        src={artWork}
                        alt="content"
                        className="badge-image"
                      /> : 'NA'}
                    </td>
                    <td>
                      <img src={icon} alt="content" className="badge-image" />
                    </td>
                    <td>{tags ? tags : 'NA'}</td>
                    <td>
                      <img
                        src={assets.Icons.downloadIcon}
                        alt="download"
                        className="download-icon"
                        onClick={() => handleDownloadClick(qrCode, title)}
                      />
                    </td>
                    <td>
                      {
                        `${mediaFile}`?.includes('mp3') ?
                          <img src={ExportedData.Icons.mp3} onClick={() => onClickVideo(mediaFile)} alt="content" className="media-tutorial-icon" />
                          : <img src={ExportedData.Icons.video} onClick={() => onClickVideo(mediaFile)} alt="content" className="media-tutorial-icon" />
                      }

                    </td>
                    <td>{header}</td>
                    <td align="center">
                      <TooltipComponent
                        onClickEdit={() => onClickEdit(list)}
                        onClickDelete={() => onClickDelete(list)} />
                    </td>
                  </tr>
                );
              })}
            </>
          ) :
            <tr>
              <td colSpan={10} className="text-center">
                <NoData />
              </td>
            </tr>}
        </NormalTable>
      </div>
      <DeleteDropDown
        show={modalOpen}
        title={"Delete"}
        screen={"Content"}
        description={"Are you sure do you want to delete?"}
        onClickYes={onClickDeleteFromPopup}
        onHide={() => setModalOpen(false)}
      />
    </div>
  );
};

export default SelfCareContent;