import React, { useEffect, useState } from "react";
import "./styles.scss";
import NormalButton from "../../components/NormalButton";
import NormalInput from "../../components/inputField";
import CheckBox from "../../components/checkBox";
import NormalSelect from "../../components/NormalSelect";
import { useForm } from "react-hook-form";
import NormalTable from "../../components/NormalTable";
import { useDispatch, useSelector } from "react-redux";
import NoData from "../../components/noData";
import { deleteUserById, getUserData, updateAllStatus, updateDataBasedOnCheckbox } from "../../redux/reducers/userSlice";
import { getCountryDropdown, getDropDown } from "../../redux/reducers/dropdownSlice";
import { CSV_FILE_NAME, DROP_DOWN_CATEGORY, calculatePaymentDuration, checkPaymentDuration, errorToast, fileDownload } from "../../utils";
import { getPlansDropDown } from "../../redux/reducers/plansSlice";
import moment from "moment";
import TooltipComponent from "../../components/tooltip";
import DeleteDropDown from "../../components/deleteModal";
import Exported from "../../services/endpoints";
import { useNavigate } from "react-router-dom";
import UniversalFilters from "../../components/universalUserFilters";
import ExportedData from "../../assets";
import InputFieldNoValidation from "../../components/inputFieldNoValidation";
const Users = () => {

  const onChangeCheckBox = (e) => {
    setCheckedAll(e.target?.checked);

    dispatch(updateAllStatus(e.target.checked));

  }

  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedStatus, setCheckedStatus] = useState(false);

  const userDatas = useSelector((state) => state?.user?.data ?? []);

  const headerDetails = [
    {
      label: <CheckBox value={checkedAll} disabled={userDatas.length == 0} onChange={onChangeCheckBox} />,
      fixedCheckbox: true,
    },
    { label: "User Id", fixedUserId: true },
    { label: "Username", fixedUserName: true },
    { label: "Email" },
    // { label: "DOB" },
    { label: "Country" },
    { label: "OS" },
    { label: "Sign In Mode" },
    { label: "Date of Sign Up" },
    { label: "Profile Verified" },
    { label: "Subscription Type" },
    { label: "Payment Duration" },
    { label: "Payment Type" },
    { label: "Action" },
    { label: "App Tracking Permission", notificationIcon: 'User allowsZEN-it to track them across other apps and websites (App Tracking Transparency)' },
    { label: "Location Data Tracking Permission", notificationIcon: 'User allowsZEN-it to track them across other apps and websites (App Tracking Transparency)' },
    { label: "Device Data Tracking Permission", notificationIcon: 'User allowsZEN-it to track them across other apps and websites (App Tracking Transparency)' },
    { label: "Marketing Comm. Permission", notificationIcon: 'User allowsZEN-it to track them across other apps and websites (App Tracking Transparency)' },
  ];




  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [subscription, setSubscription] = useState('');
  const [country, setCountry] = useState('');
  const [permission, setPermission] = useState('');
  const [os, setOS] = useState('');
  const [signInMode, setSignInMode] = useState('');
  const [paymentType, setPaymentType] = useState('');

  const [permissionOptions, setPermissionOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [filterModal, setFilterModal] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const totalPages = useSelector((state) => state?.user?.totalPages || 0);

  const [osOptions, setOsOptions] = useState([
    {label: "Android", value: "Android"},
    {label: "iOS", value: "iOS"},
  ]);

  const [paymentTypeOptions, setPaymentTypeOptions] = useState([
    {label: "Razorpay", value: "Razorpay"},
    {label: "Apple", value: "Apple"},
  ]);

  const [signInModeOptions, setSignInModeOptions] = useState([
    {label: "Native", value: "Native"},
    {label: "Google", value: "Google"},
    {label: "Apple", value: "Apple"},
    {label: "Microsoft", value: "Microsoft"},
  ]);



  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    getPermissionData();
    getCountryData();
    getSubscriptionData();
  }, [])

  useEffect(() => {
    getData()
  }, [page, search, subscription, country, permission, os, signInMode, paymentType]);


  useEffect(() => {
    const checkedCount = userDatas.filter(item => item.checked).length;
    setSelectedCount(`${checkedCount}`)
    setCheckedStatus(!(checkedCount == 0));
    setCheckedAll(userDatas.length > 0 && checkedCount == userDatas.length)
  }, [userDatas])

  const getData = () => {
    dispatch(getUserData(page, search, country, subscription, permission, os, signInMode, paymentType));
  }

  const getPermissionData = () => {
    getDropDown(DROP_DOWN_CATEGORY.USER_PERMISSION).then(res => {
      let data = [...res];
      setPermissionOptions(data);
    })
  }

  const getCountryData = () => {
    getCountryDropdown().then(res => {
      let data = [...res];
      setCountryOptions(data);
    })
  }

  const getSubscriptionData = () => {
    getPlansDropDown().then(res => {
      let data = [...res];
      setSubscriptionOptions(data);
    })
  }

  const { register } = useForm();

  const onClickEdit = (data) => {
    navigate('/main/edit-users', {
      state: {
        data
      }
    })
  }

  const onClickDelete = (data) => {
    let {
      _id
    } = data;

    let list = [];

    list.push(_id);

    let item = {
      "ids": list
    }
    setDeleteData(item);
    setShowDeletePopup(true);
  }

  const onClickDeleteFromPopup = () => {
    deleteUserById(deleteData).then(_res => {
      getData();
      setShowDeletePopup(false);
      setDeleteData(null)
    }).catch(_err => { })
  }


  const onClickExport = () => {
    fileDownload(`${Exported.Endpoints.GET_ALL_USERS}?export=1`, CSV_FILE_NAME.USER_LIST);
  }


  const onChangeCheckBoxFromList = (value, index) => {

    dispatch(updateDataBasedOnCheckbox({
      index, value
    }));

    setCheckedStatus(true);
  }

  const onClickDeleteAll = () => {
    let request = getRequest();
    if (request.length > 0) {
      let data = {
        "ids": [...request]
      }
      setDeleteData(data);
      setShowDeletePopup(true);
    } else {
      errorToast("Please select atleast one user to delete")
    }
  }

  const onClickClose = () => {
    setCheckedAll(false);
    setSelectedCount(0);
    setCheckedStatus(false);
    dispatch(updateAllStatus(false))
  }

  const getRequest = () => {
    let item = [...userDatas];
    let list = []
    item.map(it => {
      if (it.checked) {
        list.push(it?._id);
      }
    })

    return list
  }


  return (
    <div className="users-main-container">
      <div className="users-header-container">
        <p className="users-title">Users</p>
        <NormalButton label="EXPORT CSV" mainBg onClick={onClickExport} />
      </div>
      {
        checkedStatus ?
          <div className="checked-container">
            <div className="selected-container" onClick={() => onClickClose()}>
              <img
                alt="close"
                className="close-popup"
                src={ExportedData.Icons.close} />
              <label>{selectedCount} Selected</label>
            </div>

            <div className="selected-container">
              <img
                alt="close"
                className="delete-icon"
                onClick={() => onClickDeleteAll()}
                src={ExportedData.Icons.delete} />
            </div>
          </div>
          :
          <div>
          <div className="users-input-container ">
            <div className="search-container">
              <label></label>
              <InputFieldNoValidation leftIcon searchIcon placeholder="Search" onChange={(e) => setSearch(e?.target?.value)} />
            </div>
            <div className="user-filter-container me-3">
              <label className="subscription-label"></label>
              <NormalButton
                label={"Filters"}
                cancelBtn
                onClick={() => setFilterModal(true)}
              />
            </div>
            <div className="subs-container-new me-3">
              <label className="subscription-label">Permissions</label>
              <NormalSelect
                options={permissionOptions}
                name="subscription"
                disableButtons
                register={register}
                borderGreen
                onChange={(e) => {
                  setPermission(e?.value);
                }}
              />
            </div>
            <div className="subscription-container me-3">
              <label className="subscription-label">Subscription</label>
              <NormalSelect
                options={subscriptionOptions}
                name="subscription"
                register={register}
                disableButtons
                borderGreen
                onChange={(e) => {
                  setSubscription(e?.label?.props?.data?.label);
                }}
              />
            </div>
            <div className="subscription-container me-3">
              <label className="subscription-label">Location</label>
              <NormalSelect
                options={countryOptions}
                name="location"
                disableButtons
                register={register}
                borderGreen
                onChange={(e) => {
                  setCountry(e?.label?.props?.data?.label);
                }}
              />
            </div>
            
          </div>
          <div className="users-input-container ">
            <div className="subscription-container me-3">
              <label className="subscription-label">Payment Type</label>
              <NormalSelect
                options={paymentTypeOptions}
                name="paymentType"
                disableButtons
                register={register}
                borderGreen
                onChange={(e) => {
                  setPaymentType(e?.label?.props?.data?.label);
                }}
              />
            </div>
            <div className="subscription-container me-3">
              <label className="subscription-label">OS</label>
              <NormalSelect
                options={osOptions}
                name="os"
                register={register}
                disableButtons
                borderGreen
                onChange={(e) => {
                  setOS(e?.label?.props?.data?.label);
                }}
              />
            </div>
            <div className="subscription-container">
              <label className="subscription-label">Sign In Mode</label>
              <NormalSelect
                options={signInModeOptions}
                name="signInMode"
                disableButtons
                register={register}
                borderGreen
                onChange={(e) => {
                  setSignInMode(e?.label?.props?.data?.label);
                }}
              />
            </div>
          </div>
          </div>

      }
      <div className="users-table-container" style={{
        marginTop: checkedStatus ? 15 : 0
      }}>
        <NormalTable
          headerDetails={headerDetails}
          pagination
          totalPages={totalPages}
          onPageChange={(page) => setPage(page)}
          page={page}>
          {userDatas.length > 0 ? (
            <>
              {userDatas.map((list, index) => {
                let {
                  _id,
                  userName,
                  emailId,
                  countryName,
                  os,
                  signInMode,
                  signUpDate,
                  isProfileVerified,
                  subscriptionType,
                  duration,
                  unit,
                  paymentType,
                  appTrackingPermission,
                  locationTrackingPermission,
                  deviceTrackingPermission,
                  marketingTrackingPermission,
                  checked,
                } = list;
                return (
                  <tr key={_id}>
                    <td className="checkbox-sticky-container">
                      <CheckBox value={checked} onChange={(e) => onChangeCheckBoxFromList(e.target?.checked, index)} />
                    </td>
                    <td className="userId-sticky-container">{`${_id}`.slice(-8).toUpperCase()}</td>
                    <td className="username-column ">
                      <span className="">{userName}</span>{" "}
                      <div className="vertical-line-cell" />
                    </td>
                    <td>{emailId}</td>
                    {/* <td>{moment(dob).format("DD MMM YYYY")}</td> */}
                    <td>{countryName}</td>
                    <td>{os}</td>
                    <td>{signInMode}</td>
                    <td>{signUpDate ? moment(signUpDate).format("DD MMM YYYY"): 'NA'}</td>
                    <td>{isProfileVerified ? 'Yes': 'No'}</td>
                    <td>{subscriptionType}</td>
                    <td>{`${duration} ${unit}`}</td>
                    <td>{paymentType}</td>
                    <td align="center">
                      <TooltipComponent
                        onClickEdit={() => onClickEdit(list)}
                        onClickDelete={() => onClickDelete(list)} />
                    </td>
                    <td>{appTrackingPermission ? 'Yes' : 'No'}</td>
                    <td>{locationTrackingPermission ? 'Yes' : 'No'}</td>
                    <td>{deviceTrackingPermission ? 'Yes' : 'No'}</td>
                    <td>{marketingTrackingPermission ? 'Yes' : 'No'}</td>
                  </tr>
                );
              })}
            </>
          ) : <tr>
            <td colSpan={18} className="text-center">
              <NoData />
            </td>
          </tr>}
        </NormalTable>
      </div>

      <DeleteDropDown
        show={showDeletePopup}
        title={"Delete"}
        screen={"Users"}
        description={"Are you sure do you want to delete?"}
        onClickYes={onClickDeleteFromPopup}
        onHide={() => setShowDeletePopup(false)}
      />

      <UniversalFilters
        show={filterModal}
        onSuccess={() => getData()}
        onHide={() => setFilterModal(false)} />

    </div>
  );
};

export default Users;
