import React from "react";
import './styles.scss'
import Lottie from "lottie-react";
import ExportedData from '../../assets';
import NormalButton from "../../components/NormalButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getComponentFromPath } from "../../utils";
const NoPermission = () => {

    const userData = useSelector((state) => state.auth.userData);


    let navigate = useNavigate();

    const onClickNavigate = () => {
        let permission = [...userData?.permissions];
        let currentPermission = permission.filter(it => it.isView);
        if(currentPermission.length > 0){
            let topPermissionData = currentPermission[0];
            let module = `${topPermissionData?.module}`;
    
            let path = getComponentFromPath("screen", module, "childPath");
    
            let navPath = `/main${path?.childPath}`;
    
            navigate(navPath);
    
          }else{
            navigate("/main/profile-settings");
          }
    
    }

    return (
        <div>
            <Lottie animationData={ExportedData.Lottie.forbidden} loop={true} className="no-permission" />
            <div className="permission-container">
                <p className="no-permission-text">You don't have permission to access this screen</p>
                <NormalButton
                    label="Navigate to Authorized Screens"
                    loginBtn
                    onClick={() => onClickNavigate()}
                />
            </div>
        </div>
    )
}

export default NoPermission;