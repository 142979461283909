import React, { useEffect, useState } from "react";
import NormalTable from "../../components/NormalTable";
import "../staff/styles.scss";
import "../users/styles.scss";
import NoData from '../../components/noData';
import TooltipComponent from "../../components/tooltip";
import InputFieldNoValidation from "../../components/inputFieldNoValidation";
import { useDispatch, useSelector } from "react-redux";
import { getAllDeletedUsers, reActivateUsers } from "../../redux/reducers/userSlice";
import moment from "moment";

const DeletedUsers = () => {

  const data = useSelector((state) => state?.user?.deletedUsers ?? []);
  const totalPages = useSelector((state) => state?.user?.totalPagesDeletedUsers || 0);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const headerDetails = [
    { label: "S No" },
    { label: "Username" },
    { label: "Email Id" },
    { label: "Reason" },
    { label: "Deleted On" },
    { label: "Action" }
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    getData()
  }, [page, search]);

  const getData = () => {
    dispatch(getAllDeletedUsers(page, search));
  }
  const onClickReactivate = (item) => {
    let request = {
      userId: item?._id
    }
    reActivateUsers(request).then(res => {
      getData();
    }).catch(_err => { })
  }

  return (
    <div className="users-main-container">
      <div className="users-header-container">
        <p className="users-title">Deleted Users</p>
      </div>
      <div className="customer-care-search-container ">
        <div className="customer-care-search-input">
          <InputFieldNoValidation leftIcon searchIcon placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
        </div>
      </div>
      <div className="users-table-container">
        <NormalTable
          headerDetails={headerDetails}
          pagination
          totalPages={totalPages}
          onPageChange={(page) => setPage(page)}
          page={page}
        >
          {data.length > 0 ? (
            <>
              {data.map((list, index) => {
                let {
                  _id,
                  userName,
                  emailId,
                  deletedOn,
                  reason,

                } = list;
                return (
                  <tr key={_id}>
                    <td>{((page - 1) ? (page - 1) : '') + '' + (index + 1)}</td>
                    <td>{userName}</td>
                    <td>{emailId}</td>
                    <td>{reason}</td>
                    <td>{moment(deletedOn).format("DD MMM YYYY, hh:mm a")}</td>
                    <td align="center">
                      <TooltipComponent
                        onClickReactivate={() => onClickReactivate(list)}
                      />
                    </td>
                  </tr>
                );
              })}
            </>
          ) : <tr>
            <td colSpan={8} className="text-center">
              <NoData />
            </td>
          </tr>
          }
        </NormalTable>
      </div>



    </div>
  );
};

export default DeletedUsers;
