import React from "react";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import "./styles.scss";
import NormalSelect from "../NormalSelect";
import { useForm } from "react-hook-form";
import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const ChartCard = ({
  chartType,
  title,
  selectOptions,
  data,
  options,
  footerData,
  onChangeOptions,
  selectedItem,
  index,
  loading
}) => {
  const { register } = useForm();
  let ChartComponent;

  switch (chartType) {
    case "bar":
      ChartComponent = Bar;
      break;
    case "line":
      ChartComponent = Line;
      break;
    case "pie":
      ChartComponent = Doughnut;
      break;
    default:
      ChartComponent = Bar;
  }

  return (
    <div
      className={`${footerData ? "footer-main-container" : "chart-card-main-container"
        }`}
      key={title}>
      <div className="chart-title-container">
        <span className="chart-title"> {title} </span>
        {selectOptions && (
          <NormalSelect
            name="chart"
            options={selectOptions}
            disableButtons
            value={selectedItem}
            isClearable={false}
            onChange={(e) => onChangeOptions(e, title, index)}
            register={register}
          />
        )}
      </div>
      <div className="chart-card-container">
        <div
          className={`${chartType === "pie" ? "pie-chart-card" : "chart-card"}`}
        >

          {
            loading ? <p className="no-chart-data">{loading}</p> : data.labels && data.labels?.length > 0 ? <ChartComponent data={data} options={options} /> : <p className="no-chart-data">No Chart Data</p>
          }
          
        </div>
      </div>
      {footerData && (
        <div className="footer-card-container">
          {Object.keys(footerData).map((key, value) => (
            <div className="footer-card-data" key={`${value}`}>
              <span className="footer-card-title"> {key}</span>
              <p className="footer-card-content">{footerData[key]}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChartCard;
