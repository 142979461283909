import React, { useEffect, useState } from "react";
import "./styles.scss";
import NormalModal from "../NormalModal";
import NormalButton from "../NormalButton";
import ExportedData from "../../assets";
import { useSelector } from "react-redux";
import { dropDownValidation, errorToast, successToast } from "../../utils";
import moment from "moment";
import { useForm } from "react-hook-form";
import CustomController from "../customController";
import NormalSelect from "../NormalSelect";
import DatePicker from "../DatePicker";
import TextAreaInput from "../TextArea";
import { updateRefunDetails } from "../../redux/reducers/paymentSlice";

const RefundDetails = ({ show, onHide, data = {}, onUpdated }) => {

    const [modalOpen, setModalOpen] = useState(show);
    const [isEdit, setIsEdit] = useState(false);



    const hideModal = () => {
        onHide();
        setValue("isRequested", null);
        setValue("message", null);
        setValue("date", null);
        setDate(null);
        setSelectedRequest(null);
        setModalOpen(false);
        setTimeout(() => {
            setIsEdit(false)
        }, 100);
    }

    const {
        handleSubmit,
        register,
        formState: { errors },
        control,
        setValue
    } = useForm({ mode: "onBlur" });

    const onSubmit = (item) => {
        let request = {
            ...item,
            _id: data?.paymentId,
            type: data?.type,
            date: moment(date).format("YYYY-MM-DD")
        }
        updateRefunDetails(request).then(res => {
            setIsEdit(false)
            onHide();
            onUpdated();
            successToast("Refund details updated successfully");
        }).catch(err => {})
    }

    const [selectedRequest, setSelectedRequest] = useState(null);
    const [date, setDate] = useState(null);

    const [requestedOption, setRequestedOption] = useState([
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' }
    ])

    useEffect(() => {
        setModalOpen(show);

        if (data?.isRequested) {
            let item = requestedOption.filter(it => it.label == data?.isRequested);
            setSelectedRequest(item[0]);
            setDate(data?.date ? moment(data?.date).toDate() : null)
            setValue("isRequested", data?.isRequested);
            setValue("message", data?.message)
            setValue("date", data?.date ? moment(data?.date).format("YYYY-MM-DD") : null)
        }


    }, [show]);

    return (
        <>
            <NormalModal
                show={modalOpen}
                setModal={hideModal}
                customModalClass="width75">
                <div className="container">
                    <div>
                        <div className="delete-popup-container">
                            <div className="header">
                                <span className="delete-users">Refund Details</span>
                                <NormalButton
                                    secondaryBtn
                                    onClick={() => setIsEdit(!isEdit)}
                                    label={isEdit ? 'Cancel' : 'Edit'} />
                            </div>
                            <img
                                src={ExportedData.Icons.close_popup}
                                alt="close"
                                className="popup-close-icon"
                                onClick={() => hideModal()}
                            />
                        </div>


                        {
                            !isEdit ?
                                <div className="refund-popup-container">
                                    <p className="refund-text">Refund Requested?: {data?.isRequested}</p>
                                    <p className="refund-text">Date: {data?.date ? moment(data?.date).format('DD MMM YYYY') : 'NA'}</p>
                                    <p className="refund-text">Message: {data?.message ? data?.message : "NA"}</p>
                                </div>
                                :
                                <div>
                                    <form className="refund-form-container" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="">
                                            <label className="login-input-label">Refund Requested</label>
                                            <CustomController
                                                name="isRequested"
                                                control={control}
                                                data={selectedRequest}
                                                error={errors.isRequested}
                                                register={register("isRequested", dropDownValidation('Requested'))}
                                                render={({ field: { onChange } }) => {
                                                    return (
                                                        <NormalSelect
                                                            options={requestedOption}
                                                            borderGreen
                                                            disableButtons
                                                            value={selectedRequest}
                                                            error={errors.isRequested}
                                                            onChange={(e) => {
                                                                setSelectedRequest(e);
                                                                onChange(e?.value);
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </div>

                                        <div className="refund-form-containers ">
                                            <label className="login-input-label">Refund Date</label>

                                            <CustomController
                                                name="date"
                                                control={control}
                                                data={date}
                                                error={errors.date}
                                                register={register("date", dropDownValidation('Date'))}
                                                render={({ field: { onChange } }) => {
                                                    return (
                                                        <DatePicker
                                                            placeHolder={true}
                                                            className='date-picker-refund'
                                                            format={"dd-MM-yyyy"}
                                                            placeholderText="Select from date"
                                                            value={date ?? null}
                                                            error={errors.date}
                                                            onChange={(e) => {
                                                                setDate(e);
                                                                onChange("date")
                                                            }}
                                                            messages={errors}
                                                        />
                                                    );
                                                }}
                                            />

                                        </div>

                                        <div className="mb-2">
                                            <label className="login-input-label">Message</label>
                                            <TextAreaInput
                                                name='message'
                                                placeholder="Please enter a message"
                                                register={register("message", {
                                                    required: "Message is required",
                                                })}
                                                error={errors.message}
                                                messages={errors}
                                            />
                                        </div>
                                        <NormalButton label={'Update'} secondaryBtnBg />
                                    </form>
                                </div>
                        }

                    </div>
                </div >
            </NormalModal >
        </>
    );
};

export default RefundDetails;