import React, { useRef, useState } from "react";
import "./styles.scss";
import "../login/styles.scss";
import NormalButton from "../../components/NormalButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { forgotPassword, verifyOtp } from "../../redux/reducers/authSlice";
import { Toast } from "../../components/toast";

const VerifyOtp = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  const { state } = location;
  const [emailId, _setEmailId] = useState(state?.emailId);
  const [isForgotPassword, _setForgotPassword] = useState(state?.forgotPassword);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [countdown, setCountdown] = useState(120);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) =>
        prevCountdown > 0 ? prevCountdown - 1 : 0
      );
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const handleChange = (index, value) => {
    if (!isNaN(value) && value !== "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < 3) {
        inputRefs[index + 1].current.focus();
      }
    } else if (value === "") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
  };

  const isOtpIncomplete = otp.some((digit) => digit === "");
  const showResendText = countdown === 0;


  const handleVerify = () => {
    let request = {
      "emailId": emailId,
      "userType": "staff",
      "otp": parseInt(otp.join(""))
    }

    verifyOtp(request).then(res => {
      Toast({ type: "success", message: res.data.message });
      if (isForgotPassword) {
        navigate("/auth/reset-password", {
          state: {
            emailId: emailId,
            forgotPassword: isForgotPassword
          }
        });
      } else {
        navigate("/auth/reset-password", {
          state: {
            forgotPassword: false,
            emailId: emailId
          }
        });
      }
    }).catch(err => {

    })

  };

  const triggerNewOtp = () => {
    let request = {
      "emailId": emailId,
      "userType": "staff"
    }
    forgotPassword(request).then(res => {
      Toast({ type: "success", message: res.data.message });
      setCountdown(60)
    }).catch(err => {

    })
  }

  return (
    <>
      <div className="col-md-6 login-container mt-3">
        <div className="forgot-container">
          <p className="forgot-content-title">
            {!isForgotPassword ? "Change Password" : "Forgot your Password?"}
          </p>
          <p className="forgot-login-text">
            A verification code has been sent to the registered email. <br></br>
            This code is valid for the next{" "}
            <span className="otp-seconds">{countdown}</span> seconds.
          </p>
          <div className="otp-input-container">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                className="otp-input"
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                ref={inputRefs[index]}
              />
            ))}
            {showResendText && (
              <div className="resend-otp-text-container">
                <p
                  className="resend-otp-text"
                  onClick={() => triggerNewOtp()}
                >
                  Resend verification Code
                </p>
              </div>
            )}
          </div>

          <div className="mt-2">
            <NormalButton
              label={"VERIFY"}
              loginBtn
              onClick={handleVerify}
              disable={isOtpIncomplete}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOtp;
