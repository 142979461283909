import { createSlice } from "@reduxjs/toolkit";
import request from "../../services";
import Exported from "../../services/endpoints";

export const notificationSlice = createSlice({
    name: "notification",
    initialState: {
        data: [],
        userNotifications: []
    },
    reducers: {
        saveData: (state, action) => {
            state.data = action.payload;
        },
        saveUserNotifications: (state, action) => {
            state.userNotifications = action.payload;
        },
    }
});


export const getNotificationData = () => async (dispatch) => {
    request({
        url: Exported.Endpoints.GET_ALL_NOTIFICATION,
        method: Exported.APIMethods.GET
    }).then(res => {
       dispatch(saveData(res?.data?.data));
    }).catch(_err => {
    })
}

export const getUserNotifications = () => async (dispatch) => {
    request({
        url: Exported.Endpoints.GET_ALL_USER_NOTIFICATION,
        method: Exported.APIMethods.GET
    }).then(res => {
       dispatch(saveUserNotifications(res?.data?.data));
    }).catch(_err => {
    })
}

export const createUserNotifications = (data) => {
    return new Promise((resolve, reject) => {
        request({
            url: Exported.Endpoints.CREATE_USER_NOTIFICATION,
            method: Exported.APIMethods.POST,
            data
        }).then(res => {
            resolve(res)
        }).catch(_err => {
            reject(_err)
        })
    })
    
}

export const { saveData, saveUserNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;
