import React, { useState } from "react";
import "./styles.scss";
import Pagination from "../pagination";
import assets from "../../assets";
import NormalButton from "../NormalButton";
import NormalModal from "../NormalModal";
import DatePicker from "../DatePicker";
import InfoToolTip from "../infoToolTip";
import moment from "moment";
import { errorToast } from "../../utils";

const NormalTable = ({
  headerDetails,
  children,
  subscriptionTable,
  pagination,
  totalPages = 0,
  page = 1,
  onPageChange,
  showPagination,
  onClickSave,
  isBorder = false
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const hasData = children?.props?.children?.length > 0;


  const [fromDate, setFromDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [save, setSave] = useState(false);


  const userIdLeft = hasData ? 98 : 40;
  const userNameLeft = hasData ? 196 : 105;

  const onClickSaveButton = () => {

    if (!fromDate) {
      errorToast("Please select From Date");
      return
    }

    if (!endDate) {
      errorToast("Please select End Date");
      return
    }

    let dateFormatFrom = new Date(fromDate);
    let dateFormatEnd = new Date(endDate);

    if((dateFormatEnd.getTime() - dateFormatFrom.getTime()) < 0){
      errorToast("From date should be greater than to date");
      return
    }

    let request = {
      fromDate: moment(fromDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD")
    }

    if (onClickSave) {
      onClickSave(request);
      setModalOpen(false);
      setSave(true)
    }
  }

  const clearFilters = () => {
    setFromDate(null);
    setEndDate(null);
    setSave(false)
    let request = {
      fromDate: null,
      endDate: null
    }

    if (onClickSave && fromDate && endDate) {
      onClickSave(request);
      setModalOpen(false);
    }
  }

  return (
    <div className="table-container" style={{
        border: isBorder ? "1px solid rgb(203, 203, 203)" : "none",
        borderRadius:5
    }}>
      <div className="table-scroll">
        <table className="main-table" cellSpacing={0}>
          <thead
            className={`${subscriptionTable
              ? "subscription-table-header"
              : "normal-table-header"
              }`}
          >
            <tr>
              {headerDetails.map((header, index) => (
                <th
                  key={index}
                  className={`${header.fixedCheckbox
                    ? "checkbox-sticky-container"
                    : header.fixedUserId
                      ? "userId-sticky-container"
                      : header.fixedUserName
                        ? "username-column"
                        : ""
                    } `}
                  style={{
                    left:
                      header.fixedUserId && userIdLeft
                        ? `${userIdLeft}px`
                        : header.fixedUserName && userNameLeft
                          ? `${userNameLeft}px`
                          : undefined,
                  }}
                >
                  <div className="table-header-custom">
                    {header.label}
                    {header.filterIcon && (
                      <div>
                        {
                          save && (
                            <div className="filter-applied"/>)
                        }
                        <img
                          src={assets.Icons.filter}
                          alt="filter"
                          className="table-filter-icon"
                          onClick={() => setModalOpen(true)}
                        />
                        
                      </div>

                    )}

                    {header.notificationIcon && (
                      <div>
                        <InfoToolTip content={header?.notificationIcon} />
                      </div>
                    )}
                  </div>

                </th>
              ))}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      </div>
      {(pagination && totalPages != 0) && (
        <div
          className={`${showPagination
            ? "d-flex text-center flex-column mt-1"
            : "d-flex justify-content-between mt-3"
            }`}
        >
          <span className={`show-text ${showPagination ? "col-md-12" : ""}`}>
            {" "}
            Showing {(page * 10) - (10 - 1)} - {page * 10} of {10 * totalPages}
          </span>
          <div
            className={`${showPagination ? "users-pagination" : "table-pagination"
              }`}
          >
            <Pagination
              pageChange={(page) => { onPageChange(page) }}
              totalPage={totalPages}
            />
          </div>
        </div>
      )}


      <NormalModal show={modalOpen} customModalClass="width75" setModal={() => setModalOpen(false)}>
        <div className="filter-popup">
          <div className="filter-popup-main-container">
            <div className="filter-popup-container">
              <span className="filter-users">Filter by Transaction Dates</span>
              <img
                src={assets.Icons.close_popup}
                alt="close"
                className="popup-close-icon"
                onClick={() => setModalOpen(false)}
              />
            </div>
            <div className="filter-date-input ">
              <div className="filter-from-input">
                <label className="login-input-label">From</label>
                <DatePicker
                  calendarStyle={true}
                  placeHolder={true}
                  isWeekdays={true}
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(e)
                  }}
                  format={"dd-MM-yyyy"}
                  placeholderText="DD/MM/YYYY"
                  dateIcon
                />
              </div>
              <div className="filter-to-input">
                <label className="login-input-label">To</label>
                <DatePicker
                  calendarStyle={true}
                  placeHolder={true}
                  isWeekdays={true}
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e)
                  }}
                  format={"dd-MM-yyyy"}
                  placeholderText="DD/MM/YYYY"
                  dateIcon
                />
              </div>
            </div>
          </div>
          <div className="filter-popup-btn-container">
            <div className="clear-filter" onClick={() => clearFilters()}>
              <span> Clear Filter</span>
            </div>
            <div className="d-flex">
              <div className="filter-cancel-btn-container">
                <NormalButton
                  label=" CANCEL"
                  cancelBtn
                  onClick={() => {
                    setFromDate(null);
                    setEndDate(null);
                    setModalOpen(false);
                  }}
                />
              </div>
              <div className="filter-save-btn-container">
                <NormalButton label="SAVE" mainBg onClick={() => onClickSaveButton()} />
              </div>
            </div>
          </div>
        </div>
      </NormalModal>
    </div>
  );
};

export default NormalTable;
