import React, { useState } from "react";
import "../verifyOtp/styles.scss";
import NormalButton from "../../components/NormalButton";
import NormalInput from "../../components/inputField";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { REGEX } from '../../utils'
import Constants from '../../utils/constants'
import { forgotPassword } from "../../redux/reducers/authSlice";
import { Toast } from "../../components/toast";

const ForgotPassword = () => {
  let navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const handleEmailChange = async (event) => {
    setSuccess(true);
  };

  const onSubmit = (data) => {

    let request = {
      ...data,
      "userType": "staff"
    }
    forgotPassword(request).then(res => {
      Toast({ type: "success", message: res.data.message });
      navigate("/auth/verify-otp" , { state: {
        emailId: data.emailId,
        forgotPassword: true
      }});

    }).catch(err => {

    });
  };
  const customErrorMessages = {
    required: "Email is required",
    pattern: {
      value: REGEX.EMAIL_ID,
      message: Constants.EMAIL_ID_ERROR
    },
  };
  return (
    <>
      <form
        className="col-md-6 login-container mt-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="forgot-container">
          <p className="forgot-content-title">Forgot your Password?</p>
          <p className="forgot-login-text">
            Enter the registered email <br></br> to receive a verification code
          </p>
          <div className="login-input-container">
            <label
              className={`login-input-label ${errors.emailId !== undefined ? "text-danger" : ""
                }`}
            >
              Email
            </label>
            <NormalInput
              type={"text"}
              placeholder={"Please enter your email"}
              onChange={handleEmailChange}
              register={register("emailId", {
                required: customErrorMessages.required,
                pattern: customErrorMessages.pattern,
              })}
              error={errors.emailId}
              messages={customErrorMessages}
              leftIcon
              emailIcon
              rightIcon
              successIcon={success}
            />
          </div>
          <div className="text-start">
            <p
              className="login-forgot-text"
              onClick={() => navigate("/auth/login")}
            >
              Back to Login
            </p>
          </div>

          <div className="mt-2">
            <NormalButton label={"SEND VERIFICATION CODE"} loginBtn />
          </div>
        </div>
      </form>
    </>
  );
};

export default ForgotPassword;
