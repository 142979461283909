import { createSlice } from "@reduxjs/toolkit";
import request from "../../services";
import Exported from "../../services/endpoints";

export const paymentSlice = createSlice({
    name: "payment",
    initialState: {
        paymentData: {},

    },
    reducers: {
        savePaymentCount: (state, action) => {
            state.paymentData = action.payload;
        }
    }
});

export const getPaymentDataCount = () => async (dispatch) => {

    request({
        url: `${Exported.Endpoints.GET_PAYMENT_COUNT}`,
        method: Exported.APIMethods.GET,
        isLoader:false
    }).then(res => {
        dispatch(savePaymentCount(res?.data?.data))
    }).catch(_err => {
    })
}


export const getSubscriptionGrowth = (type) => {

    return new Promise((resolve, _reject) => {
        request({
            url: `${Exported.Endpoints.GET_SUBSCRIPTION_GROWTH}${type}`,
            method: Exported.APIMethods.GET,
            isLoader:false
        }).then(res => {
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const getSubscriptionByLocation = (type) => {

    return new Promise((resolve, _reject) => {
        request({
            url: `${Exported.Endpoints.GET_SUBSCRIPTION_BY_LOCATION}${type}`,
            method: Exported.APIMethods.GET,
            isLoader:false
        }).then(res => {
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const updateRefunDetails = (data) => {

    return new Promise((resolve, _reject) => {
        request({
            url: `${Exported.Endpoints.UPDATE_REFUND_DETAILS}`,
            method: Exported.APIMethods.PUT,
            data
        }).then(res => {
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const { savePaymentCount } = paymentSlice.actions;

export default paymentSlice.reducer;
