import React, { useEffect, useState } from "react";
import NormalButton from "../../components/NormalButton";
import NormalTable from "../../components/NormalTable";
import NormalInput from "../../components/inputField";
import assets from "../../assets";
import "./styles.scss";
import "../users/styles.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteStaffById, getData, saveEmpId } from '../../redux/reducers/staffSlice'
import NoData from '../../components/noData';
import TooltipComponent from "../../components/tooltip";
import { CSV_FILE_NAME, fileDownload } from "../../utils";
import Exported from "../../services/endpoints";
import { Button, Modal } from 'react-bootstrap';
import DeleteDropDown from "../../components/deleteModal";
import InputFieldNoValidation from "../../components/inputFieldNoValidation";


const Staff = () => {
  let navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const headerDetails = [
    { label: "Emp Id" },
    { label: "First Name" },
    { label: "Last Name" },
    { label: "Email" },
    { label: "Phone" },
    { label: "Staff Role" },
    { label: "Admin Role" },
    { label: "Action" },
  ];


  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [deleteData, setDeleteData] = useState(null);


  const data = useSelector((state) => state?.staff?.data || []);

  const totalPage = useSelector((state) => state?.staff?.totalPages || 0);

  const currentEmailId = useSelector((state) => state.auth.userData?.emailId);

  const dispatch = useDispatch();

  useEffect(() => {
    getStaffData();
    dispatch(saveEmpId(''))
  }, [page, search]);


  const getStaffData = () => {
    dispatch(getData(page, search))
  }


  const onClickDelete = (list) => {
    setDeleteData(list);
    setModalOpen(true)
  }

  const onClickDeleteFromPopup = () => {
    let {
      _id
    } = deleteData;

    deleteStaffById(_id).then(_res => {
      getStaffData();
      setModalOpen(false);
      setDeleteData(null)
    }).catch(_err => { })
  }

  const onClickEdit = (it) => {
    navigate("/main/add-staff", {
      state: {
        data: it
      }
    })
  }

  const onClickCSV = () => {
    fileDownload(`${Exported.Endpoints.GET_ALL_STAFF}?export=1`, CSV_FILE_NAME.STAFF_LIST);
  }

  const onPageChange = (page) => {
    setPage(page)
  }

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
  }

  return (
    <div className="users-main-container">
      <div className="users-header-container">
        <p className="users-title">Staff List</p>
        <NormalButton label="EXPORT CSV" mainBg onClick={onClickCSV} />
      </div>
      <div className="customer-care-search-container ">
        <div className="staff-search-input">
          <InputFieldNoValidation
            leftIcon
            searchIcon
            onChange={onChangeSearch}
            placeholder="Search" />
        </div>
        <div className="ms-3">
          <NormalButton
            label="ADD NEW STAFF"
            cancelBtn
            onClick={() => navigate("/main/add-staff")}
          />
        </div>
      </div>
      <div className="users-table-container">
        <NormalTable
          headerDetails={headerDetails}
          pagination
          totalPages={totalPage}
          onPageChange={(page) => onPageChange(page)}
          page={page}>
          {data.length > 0 ? (
            <>
              {data.map((list) => {
                let {
                  employeeId,
                  firstName,
                  lastName,
                  emailId,
                  phone,
                  staffRole,
                  adminRole,
                } = list;
                return (
                  <tr key={employeeId}>
                    <td>{employeeId}</td>
                    <td>{firstName}</td>
                    <td>{lastName}</td>
                    <td>{emailId}</td>
                    <td>{phone}</td>
                    <td>{staffRole}</td>
                    <td>{adminRole}</td>
                    <td align="center">
                      {
                        emailId == currentEmailId ?
                          <label className="logged-in-user">Logged In User</label> :
                          <TooltipComponent
                            onClickEdit={() => onClickEdit(list)}
                            onClickDelete={() => onClickDelete(list)} />
                      }

                    </td>
                  </tr>
                );
              })}
            </>
          ) : <tr>
            <td colSpan={8} className="text-center">
              <NoData />
            </td>
          </tr>
          }
        </NormalTable>
      </div>

      <DeleteDropDown
        show={modalOpen}
        title={"Delete"}
        screen={"Staff"}
        description={"Are you sure do you want to delete?"}
        onClickYes={onClickDeleteFromPopup}
        onHide={() => setModalOpen(false)}
      />

    </div>
  );
};

export default Staff;
