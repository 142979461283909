import { createSlice } from "@reduxjs/toolkit";
import request from "../../services";
import Exported from "../../services/endpoints";

export const dropdownSlice = createSlice({
    name: "dropdown",
    initialState: {
    },
    reducers: {

    }
});


export const getDropDown = (category) => {
    return new Promise((resolve, reject) => {
        request({
            url: `${Exported.Endpoints.GET_DROP_DOWN_BY_CATEGORY}${category}`,
            method: Exported.APIMethods.GET
        }).then(res => {
            resolve(res.data.data);
        }).catch(_err => {
        })
    })
}

export const createDropDown = (data) => {
    return new Promise((resolve, reject) => {
        request({
            url: `${Exported.Endpoints.CREATE_DROP_DOWN}`,
            method: Exported.APIMethods.POST,
            data
        }).then(res => {
            resolve(res.data.data);
        }).catch(_err => {
        })
    })
}

export const updateDropDown = (data) => {
    return new Promise((resolve, _reject) => {
        request({
            url: `${Exported.Endpoints.UPDATE_DROP_DOWN}`,
            method: Exported.APIMethods.PUT,
            data
        }).then(res => {
            resolve(res.data.data);
        }).catch(_err => {
        })
    })
}

export const deleteDropDownById = (id) => {
    return new Promise((resolve, _reject) => {
        request({
            url: `${Exported.Endpoints.DELETE_DROP_DOWN}${id}`,
            method: Exported.APIMethods.DELETE,
        }).then(res => {
            resolve(res.data.data);
        }).catch(_err => {
        })
    })
}

export const getCountryDropdown = () => {
    return new Promise((resolve, reject) => {
        request({
            url: `${Exported.Endpoints.COUNTRY_DROP_DOWN}`,
            method: Exported.APIMethods.GET
        }).then(res => {
            resolve(res.data.data);
        }).catch(_err => {
        })
    })
}



export const { } = dropdownSlice.actions;

export default dropdownSlice.reducer;
