import React, { useEffect, useState } from "react";
import "./styles.scss";
import "../../theme/variables.scss";
import DashboardCard from "../../components/dashboardCard";
import assets from "../../assets";
import ChartCard from "../../components/chartCard";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerCareData, getFeaturesData, getRevenueData, getUserDemographyData, getUsersDataCount } from "../../redux/reducers/dashboardSlice";
import { showLoader } from "../../utils";

const Dashboard = () => {
  const [cardData, setCardData] = useState([
    {
      id: 1,
      status: "neutral",
      title: "Users",
      content: 0,
      footerImage: assets.Icons.equal_line,
      footerContent: "0% since last month",
      key: "totalUsers"
    },
    {
      id: 2,
      status: "neutral",
      title: "Paid Users",
      content: 0,
      footerImage: assets.Icons.equal_line,
      footerContent: "0% since last month",
      key: "paidUsers"
    },
    {
      id: 3,
      status: "neutral",
      title: "Free Users",
      content: 0,
      footerImage: assets.Icons.equal_line,
      footerContent: "0% since last month",
      key: 'freeUsers'
    },
    {
      id: 1,
      status: "neutral",
      title: "Revenue",
      content: 0,
      footerImage: assets.Icons.equal_line,
      footerContent: "0% since last month",
      key: 'revenue'
    },
  ]);

  const [chartData, setChartData] = useState([
    {
      id: 1,
      title: "Revenue",
      chartType: "line",
      selectOptions: [
        { label: "All", value: 'all' },
        { label: "Month", value: 'month' },
        { label: "Week", value: 'week' },
      ],
      selectedItem: { label: "All", value: 'all' },
      loading: 'Loading...',
      chartData: {
        labels: [],
        datasets: [
          {
            fill: false,
            borderColor: "#4ECD83",
            borderWidth: 2,
            pointBackgroundColor: "rgba(75,192,192,1)",
            pointRadius: 4,
            data: [],
          },
        ],
      },

      chartOptions: {
        scales: {
          x: {
            beginAtZero: false,
            grid: {
              display: false,
            },

            ticks: {
              color: "#351c75",
            },
            axis: {
              display: false,
            },
          },
          y: {
            beginAtZero: false,
            grid: {
              display: true,
            },
            border: {
              display: false,
            },
            ticks: {
              stepSize: 50000,
              color: "#351c75",
              callback: function (value, index, values) {
                return value.toLocaleString();
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    },
    {
      id: 2,
      title: "Features",
      chartType: "Bar",
      selectOptions: [
        { label: "All", value: 'all' },
        { label: "Month", value: 'month' },
        { label: "Week", value: 'week' },
      ],
      selectedItem: { label: "All", value: 'all' },
      loading: 'Loading...',
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [
              "#DDCDF7",
              "#DDCDF7",
              "#DDCDF7",
              "#DDCDF7",
              "#DDCDF7",
            ],
            borderWidth: 1,
            borderRadius: 5,
            data: [],
          },
        ],
      },

      chartOptions: {
        scales: {
          x: {
            beginAtZero: false,
            grid: {
              display: false,
            },

            ticks: {
              color: "#351c75",
            },
            axis: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: true,
            },
            border: {
              display: false,
            },
            ticks: {
              stepSize: 25,
              color: "#351c75",
              callback: function (value, index, values) {
                return value.toLocaleString();
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    },
    {
      id: 3,
      title: "User Demography",
      chartType: "pie",
      selectOptions: [
        { label: "All", value: 'all' },
        { label: "Month", value: 'month' },
        { label: "Week", value: 'week' },
      ],
      selectedItem: { label: "All", value: 'all' },
      loading: 'Loading...',
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ["#9AE2A9", "#FFE188", "#DDCDF7", "#6796AB"],
            borderColor: ["#9AE2A9", "#FFE188", "#DDCDF7", "#6796AB"],
            borderWidth: 1,
          },
        ],
      },

      chartOptions: {
        plugins: {
          legend: {
            display: true,
            position: "outside",
          },
          labels: {
            position: "outside",
            textStrokeColor: "transparent",
            color: "rgba(0, 0, 0, 0)",
          },
        },
      },
      footerData: {

      }
    },
    {
      id: 4,
      title: "Customer Care",
      chartType: "pie",
      selectOptions: [
        { label: "All", value: 'all' },
        { label: "Month", value: 'month' },
        { label: "Week", value: 'week' },
      ],
      selectedItem: { label: "All", value: 'all' },
      loading: 'Loading...',
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ["#9AE2A9", "#FFE188", "#DDCDF7", "#6796AB"],
            borderColor: ["#9AE2A9", "#FFE188", "#DDCDF7", "#6796AB"],
            borderWidth: 1,
          },
        ],
      },

      chartOptions: {
        plugins: {
          legend: {
            display: true,
            position: "outside",
          },
          labels: {
            position: "outside",
            textStrokeColor: "transparent",
            color: "rgba(0, 0, 0, 0)",
          },
        },
      },
      footerData: {
        "Total Queries": 0,
        "Resolved": 0,
        "Pending": 0,
      }
    },
  ]);


  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.dashboard?.usersData);

  const DEFALUT_TYPE = 'all';

  const REVENUE_INDEX = 0;
  const FEATURE_INDEX = 1;
  const USER_DEMOGRAPHY_INDEX = 2;
  const CUSTOMER_CARE_INDEX = 3;

  useEffect(() => {
    showLoader(true);
    getDashboardData();
    revenueData(DEFALUT_TYPE, REVENUE_INDEX);
    featuresData(DEFALUT_TYPE, FEATURE_INDEX);
    userDemography(DEFALUT_TYPE, USER_DEMOGRAPHY_INDEX);
    customerCare(DEFALUT_TYPE, CUSTOMER_CARE_INDEX);
  }, [])

  const getDashboardData = () => {
    dispatch(getUsersDataCount());
  }


  useEffect(() => {

    let data = [...cardData];

    let item = { ...userData };
    if (Object.keys(item).length > 0) {
      data.map(it => {
        let object = {
          ...userData[it?.key],
        }

        let {
          percentage,
          count
        } = object;

        it["status"] = percentage > 0 ? "profit" : percentage < 0 ? "loss" : "neutral";
        it["footerImage"] = percentage > 0 ? assets.Icons.up_trend : percentage < 0 ? assets.Icons.down_trend : assets.Icons.equal_line;
        it["footerContent"] = percentage % 1 === 0 ? `${percentage}% since last month` : `${percentage.toFixed(2)}% since last month`;
        it["content"] = count;
        showLoader(false);
      });
    }

    setCardData(data);
  }, [userData]);

  const revenueData = (type, index) => {
    getRevenueData(type).then(res => {
      updateData(res?.data, index)
    }).catch(_err => { })
  }

  const featuresData = (type, index) => {
    getFeaturesData(type).then(res => {
      updateData(res?.data, index)
    }).catch(_err => { })
  }

  const userDemography = (type, index) => {
    getUserDemographyData(type).then(res => {
      updateData(res?.data, index)
    }).catch(_err => { })
  }

  const customerCare = (type, index) => {
    getCustomerCareData(type).then(res => {
      updateData(res?.data, index)
    }).catch(_err => { })
  }


  const updateData = (data, index) => {
    let item = [...chartData];
    item[index].chartData.labels = [...data?.labels];
    item[index].chartData.datasets[0].data = [...data?.dataSets];
    item[index].loading = ''

    if (index == CUSTOMER_CARE_INDEX) {
      item[index].footerData["Total Queries"] = data?.totalTickets;
      item[index].footerData["Resolved"] = data?.resolvedTickets;
      item[index].footerData.Pending = data?.pendingTickets;
    }

    setChartData([...item])
  }

  const onChangeOptions = (e, title, index) => {
    let item = [...chartData];
    item[index].chartData.labels = [];
    item[index].chartData.datasets[0].data = [];
    item[index].selectedItem = e;
    item[index].loading = 'Loading...'
    setChartData([...item]);

    switch (title) {
      case "Revenue": {
        revenueData(e?.value, index)
        break;
      }
      case "Features": {
        featuresData(e?.value, index)
        break;
      }
      case "User Demography": {
        userDemography(e?.value, index);
        break;
      }
      case "Customer Care": {
        customerCare(e?.value, index)
        break;
      }
    }
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-title">
        <p>Dashboard</p>
      </div>
      <div className="d-flex mb-3">
        {cardData.map((data, index) => {
          return (
            <div key={data.title} className="chart-component-container">
              <DashboardCard
                cardStatus={data.status}
                title={data.title}
                content={data.content}
                footerImage={data.footerImage}
                index={index}
                footerContent={data.footerContent}
              />
            </div>
          )
        })}
      </div>
      <div className="d-flex flex-wrap">
        {chartData.map((list, index) => {
          return (
            <div key={list.title} className="chart-component-container">
              <ChartCard
                chartType={list.chartType}
                title={list.title}
                selectOptions={list.selectOptions}
                data={list.chartData}
                options={list.chartOptions}
                footerData={list.footerData}
                selectedItem={list.selectedItem}
                index={index}
                loading={list.loading}
                onChangeOptions={(e, title, index) => onChangeOptions(e, title, index)}
              />
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default Dashboard;
