const Constants = {
    PASSWORD_ERROR: "Password must contain at least 1 capital letter, 1 special character, 1 number and minimum of 8 characters.",
    EMAIL_ID_ERROR: "Email Id is not valid",


    DROP_DOWN_CATEGORIES: {
        STAFF_ROLE: 'staff/role',
        ADMIN_ROLE: 'admin/role',
        GENDER: 'gender',
    },

    LABEL_ERROR_DROP_DOWN: 'Please enter display label or upload a file for the display label',
    VALUE_ERROR_DROP_DOWN: 'Please enter value for the selected file',


    SELF_CARE_CONTENT_CATEGORY: {
        UPLIFT:'Uplift',
        CENTERING:'centering',
        MEDITATION:'meditation',
        STRETCH:'stretch',
    }
}


export default Constants;