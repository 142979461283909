import React, { useEffect, useState } from "react";
import NormalButton from "../../components/NormalButton";
import NormalTable from "../../components/NormalTable";
import "../staff/styles.scss";
import "../users/styles.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, } from "react-redux";
import NoData from '../../components/noData';
import { getUserNotifications } from "../../redux/reducers/notificationSlice";
import moment from "moment";


const UserNotification = () => {

  let navigate = useNavigate();

  const dispatch = useDispatch();

  const data = useSelector((state) => state?.notification?.userNotifications ?? [])


  const headerDetails = [
    { label: "S No" },
    { label: "Message" },
    { label: "Staff Name" },
    { label: "Sent On" },
  ];

  useEffect(() => {
    dispatch(getUserNotifications());
  }, [])


  return (
    <div className="users-main-container">
      <div className="users-header-container">
        <p className="users-title">User Notification</p>

      </div>
      <div className="customer-care-search-container ">

        <div className="ms-3">
          <NormalButton
            label="ADD NOTIFICATION "
            cancelBtn
            onClick={() => navigate("/main/add-notification")}
          />
        </div>
      </div>
      <div className="users-table-container">
        <NormalTable
          headerDetails={headerDetails}
        >
          {data.length > 0 ? (
            <>
              {data.map((list, index) => {
                let {
                  id,
                  message,
                  staffName,
                  createdAt
                } = list;
                return (
                  <tr key={id}>
                    <td>{index + 1}</td>
                    <td>{message}</td>
                    <td>{staffName}</td>
                    <td>{moment(createdAt).format("DD MMMM, YYYY hh:mm a")}</td>
                  </tr>
                );
              })}
            </>
          ) : <tr>
            <td colSpan={8} className="text-center">
              <NoData />
            </td>
          </tr>
          }
        </NormalTable>
      </div>



    </div>
  );
};

export default UserNotification;
