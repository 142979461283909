import React from "react";
import "./styles.scss";
import "../login/styles.scss";
import "../editUsers/styles.scss";
import assets from "../../assets";
import NormalInput from "../../components/inputField";
import NormalSelect from "../../components/NormalSelect";
import { useState } from "react";
import NormalButton from "../../components/NormalButton";
import { useNavigate } from "react-router-dom";
import FileUpload from "../../components/FileUpload";
import TextAreaInput from "../../components/TextArea";
import { useForm } from "react-hook-form";

const EditBadge = () => {
  let navigate = useNavigate();
  const [options] = useState([
    { label: "Tasks", value: 1 },
    { label: "Premium", value: 2 },
    { label: "Free", value: 3 },
  ]);
  const onSubmit = (data) => {
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  return (
    <form className="edit-user-container" onSubmit={handleSubmit(onSubmit)}>
      <div className="back-to-user-container">
        <img
          src={assets.Icons.left_icon}
          alt="left-icon"
          className="left-icon"
        />
        <span className="back-to-user" onClick={() => navigate("/main/badges")}>
          Back to Badges
        </span>
      </div>
      <div className="edit-user col-md-6 ">
        <p className="edit-user-details">Edit Badge Details</p>
        <div className="col-md-12 d-flex">
          <div className=" col-md-6 ">
            <div className="">
              <label className="login-input-label">Category</label>
              <NormalSelect
                options={options}
                name="category"
                borderGreen
                register={register("category", {
                  required: "Category is required",
                })}
                error={errors.category}
                messages={errors}
              />
            </div>
            <div className="">
              <label className="login-input-label">Header Message</label>
              <NormalInput
                type={"text"}
                register={register("headerMessage", {
                  required: "Header Message is required",
                })}
                error={errors.headerMessage}
                messages={errors}
                placeholder="Enter Header Message"
              />
            </div>{" "}
            <div className=" ">
              <label className="login-input-label">Achievement</label>
              <NormalInput
                type={"text"}
                register={register("achievement", {
                  required: "Achievement is required",
                })}
                error={errors.achievement}
                messages={errors}
                placeholder="Enter Achievement"
              />
            </div>
          </div>
          <div className=" col-md-6 ms-3">
            <div className="">
              <label className="login-input-label">Body Message</label>
              <TextAreaInput
                placeholder="Enter Body Message"
                register={register("bodyMessage", {
                  required: "Body Message is required",
                })}
                error={errors.bodyMessage}
                messages={errors}
              />
            </div>
            <div className="">
              <label className="login-input-label">Badge Image</label>
              <FileUpload
                image={assets.Images.badges1}
                register={register("badgeImage", {
                  required: "Badge Image is required",
                })}
                error={errors.badgeImage}
                messages={errors}
              />
            </div>{" "}
          </div>
        </div>
        <div className=" d-flex mt-3">
          <div className="edit-cancel-btn">
            <NormalButton
              label=" CANCEL"
              cancelBtn
              onClick={() => navigate("/main/badges")}
            />
          </div>
          <div className="edit-update-btn">
            <NormalButton label=" UPDATE" mainBg />
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditBadge;
