import React from "react";
import "./styles.scss";
import FormErrorMessage from "../ErrorMessage";
const TextAreaInput = ({
  name,
  placeholder,
  value,
  termsAndCondition,
  error,
  messages,
  register,
  disabled = false
}) => {
  const hasError = error !== undefined;
  return (
    <div>
      <textarea
        name={name}
        {...register}
        disabled={disabled}
        className={`${
          termsAndCondition ? "terms-text-area" : "text-area-input"
        }  ${hasError ? "text-error" : ""} ${disabled ? "text-disabled" : ""}` }
        placeholder={placeholder}
        value={value}
      />
      <div>
        <FormErrorMessage error={error} messages={messages} />
      </div>
    </div>
  );
};

export default TextAreaInput;
