import React from "react";
import "./styles.scss";

const NormalButton = ({
  label,
  onClick,
  loginBtn,
  disable,
  cancelBtn,
  secondaryBtn,
  secondaryBtnBg,
  mainBg,
}) => {
  return (
    <div>
      <button
        className={`${
          disable
            ? "disable-btn"
            : secondaryBtn
            ? "secondary-btn"
            : secondaryBtnBg
            ? "secondary-btn-bg"
            : loginBtn
            ? "login-btn"
            : mainBg
            ? "main-bg-btn"
            : cancelBtn
            ? "cancel-btn"
            : ""
        } `}
        onClick={onClick}
        disabled={disable}
      >
        <span className="btn-label">{label}</span>
      </button>
    </div>
  );
};

export default NormalButton;
