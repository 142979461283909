import React from "react";
import "./styles.scss";

const AddDropButton = ({
  label,
  onClick,
}) => {
  return (
      <div
        className={`cancel-btn`}
        onClick={onClick}
      >
        <span className="btn-label">{label}</span>
      </div>
  );
};

export default AddDropButton;
