import React, { useState } from "react";
import "./styles.scss";
import "../inputField/styles.scss";
import FormErrorMessage from "../ErrorMessage";
import assets from "../../assets";
import Select from "react-select";
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import ExportedData from "../../assets";
import { isHTMLString } from "../../utils";
import LottieAnimationFromURL from "../lottieAnimationFromURL";


const CustomOption = ({ data, showIcons, onClickEdit, onClickDelete, disableButtons, handleColorChange = false, image, ...props }) => {
  const [hovered, setHovered] = useState(false);

  const handleEdit = (event) => {
    event.stopPropagation();
    onClickEdit(data)
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    onClickDelete(data)
  };


  return (
    <div
      {...props}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={!data.isDisabled ? "option-dropdown" : ''}
    >
      {
        data.label && `${data.label}`.includes('drop-downs/') ?

          <img src={data.label} className="dropdown-image-item" />

          : typeof data.label == 'object' ? data.label : <div className="label-value-container">
            <span>{data.label}</span>
            {
              (handleColorChange && !data.isDisabled) && <div className="color-icon" style={{
                backgroundColor: data?.value
              }} />
            }
          </div>
      }


      {(!disableButtons && hovered && !data.isDisabled) && (
        <div>
          <img onClick={handleEdit} src={ExportedData.Icons.edit} alt="edit" width={15} height={15} className="icon-dropdown" />
          <img onClick={handleDelete} src={ExportedData.Icons.delete} alt="delete" width={15} height={15} />
        </div>
      )}
    </div>
  );
};




const NormalSelect = ({
  name,
  options,
  value,
  onChange,
  error,
  messages,
  register,
  borderGreen,
  leftIcon,
  countryIcon,
  placeholder,
  hidePlaceholder,
  disabled = false,
  handleColorChange,
  searchIcon,
  onClickEdit,
  onClickDelete,
  disableButtons = false,
  isClearable = true,
  disableBorder = false,
  hasImage = false
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f0f0f0" : "white",
      color: "#0a6a78",
      cursor: "pointer",
      fontFamily: "Open-Sans",
      fontSize: "12px",
      borderBottom: "1px solid #dedede",
      padding: "10px 20px",
    }),
    control: (provided, state) => ({
      ...provided,
      border: error ? "1px solid red" : disableBorder ? "0px solid #0a6a78" : "1px solid #0a6a78",
      fontSize: "12px",
      fontFamily: "Open-Sans",
      padding: leftIcon ? "0 20px" : hidePlaceholder ? "0" : "0 5px",
      height: "40px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#0a6a78",
      display: hidePlaceholder && "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#0a6a78",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#0a6a78",
    }),
  };

  const formatOptionLabel = ({ label }) => (
    <div>
      {(label && `${label}`?.startsWith('https://')) ? <img src={label} alt="option"  className="dropdown-image-item" />
        : <span>{label}</span>}
    </div>
  );


  return (
    <div className="select-container">
      <Select
        options={options.map(option => ({
          value: option.value,
          label: <CustomOption data={option} showIcons={false} onClickDelete={onClickDelete} onClickEdit={onClickEdit} disableButtons={disableButtons} handleColorChange={handleColorChange} />,
          isDisabled: option?.isDisabled ?? false,
          image: option?.image
        }))}
        styles={customStyles}
        placeholder={placeholder}
        isClearable={isClearable}
        value={value}
        isDisabled={disabled}
        onChange={onChange}
        formatOptionLabel={formatOptionLabel}
        name={name} />

      {leftIcon && countryIcon ? (
        <div
          className={`${error ? "error-icon-container" : "country-icon-container"
            }`}
        >
          <img
            className="country-input-icon"
            src={assets.Icons.country}
            alt="copy"
          />{" "}
        </div>
      ) : leftIcon && searchIcon ? (
        <div
          className={`${error ? "error-icon-container" : "country-icon-container"
            }`}
        >
          <img className="search-select-icon" src={searchIcon} alt="copy" />{" "}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default NormalSelect;
