import React from "react";
import "./styles.scss";

const DashboardCard = ({
  cardStatus,
  title,
  content,
  footerImage,
  footerContent,
  index
}) => {
  const formatWithComma = (number) => {
    return Math.abs(number) >= 100000;
  };
  return (
    <div className="dashboard-card">
      <div className={`${cardStatus}`} />
      <div className="ms-2 mt-2">
        <span className="dash-card-title">{title}</span>
        <p className="dash-card-content">
          {formatWithComma(content) ? content.toLocaleString("en-Us") : content}
        </p>
        <div className="dashboard-card-footer-container ">
          <img
            src={footerImage}
            alt="card-footer"
            className={`${
              cardStatus === "neutral"
                ? "footer-line-image"
                : "footer-trend-image"
            }`}
          />
          <span className="card-footer-content ">{footerContent}</span>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
