import React, { useEffect } from "react";
import "../login/styles.scss";
import assets from "../../assets";
import NormalInput from "../../components/inputField";
import NormalSelect from "../../components/NormalSelect";
import { useState } from "react";
import NormalButton from "../../components/NormalButton";
import { useLocation, useNavigate } from "react-router-dom";
import "./styles.scss";
import FileUpload from "../../components/FileUpload";
import { useForm } from "react-hook-form";
import CustomController from "../../components/customController";
import CheckBox from "../../components/checkBox";
import { REGEX, S3_BUCKET_FOLDER, dropDownValidation, upload } from "../../utils";
import AddDropButton from "../../components/dropDownButton";
import AddDropDown from "../../components/addDropdown";
import Constants from "../../utils/constants";
import { deleteDropDownById, getDropDown } from "../../redux/reducers/dropdownSlice";
import DeleteDropDown from "../../components/deleteModal";
import { useDispatch, useSelector } from "react-redux";
import { createStaff, editStaff, generateEmpId, getStaffById, saveEmpId } from "../../redux/reducers/staffSlice";

const AddStaff = () => {
  let navigate = useNavigate();

  let location = useLocation();
  const { state } = location;

  const [staffData, setStaffData] = useState(state?.data);

  const dispatch = useDispatch();

  const employeeId = useSelector((state) => state.staff.empId)

  const [category, setCategory] = useState('');

  const [show, setShow] = useState(false);

  const [showDelete, setShowDelete] = useState(false);

  const [editData, setEditData] = useState(null);

  const [deleteData, setDeleteData] = useState(null);

  const [photo, setPhoto] = useState('');

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue
  } = useForm({ mode: "onBlur" });

  const onClickAddDropDown = (category) => {
    setCategory(category);
    setEditData(null)
    setShow(true);
  };

  const [staffRole, setStaffRole] = useState([
    {
      label: <AddDropButton
        label="Add New Staff Role"
        onClick={() => onClickAddDropDown(Constants.DROP_DOWN_CATEGORIES.STAFF_ROLE)}
      />,
      isDisabled: true
    }
  ]);

  const [adminRole, setAdminRole] = useState([
    {
      label: <AddDropButton
        label="Add New Admin Role"
        onClick={() => onClickAddDropDown(Constants.DROP_DOWN_CATEGORIES.ADMIN_ROLE)}
      />,
      isDisabled: true
    }
  ]);

  const [gender, setGender] = useState([
    {
      label: <AddDropButton
        label="Add New Gender"
        onClick={() => onClickAddDropDown(Constants.DROP_DOWN_CATEGORIES.GENDER)}
      />,
      isDisabled: true
    }
  ]);


  const [selectedStaffRole, setSelectedStaffRole] = useState(null)
  const [selectedAdminRole, setSelectedAdminRole] = useState(null)
  const [selectedGender, setSelectedGender] = useState(null)


  const [selectedPermissions, setSelectedPermissions] = useState([
    {
      "module": "Dashboard",
      "isEdit": false,
      "isView": false
    },
    {
      "module": "Users",
      "isEdit": false,
      "isView": false
    },
    {
      "module": "Payment",
      "isEdit": false,
      "isView": false
    },
    {
      "module": "Badges",
      "isEdit": false,
      "isView": false
    },
    {
      "module": "Staff",
      "isEdit": false,
      "isView": false
    },
    {
      "module": "PrivacyTerms",
      "isEdit": false,
      "isView": false
    },
    {
      "module": "Content",
      "isEdit": false,
      "isView": false
    },
    {
      "module": "CustomerCare",
      "isEdit": false,
      "isView": false
    },
    {
      "module": "UserNotification",
      "isEdit": false,
      "isView": false
    }
  ]);

  useEffect(() => {

    async function getData() {
      await getDropDownByCategory(Constants.DROP_DOWN_CATEGORIES.ADMIN_ROLE);
      await getDropDownByCategory(Constants.DROP_DOWN_CATEGORIES.STAFF_ROLE);
      await getDropDownByCategory(Constants.DROP_DOWN_CATEGORIES.GENDER);
    }

    getData();

    if (staffData) {

      let {
        _id
      } = staffData;

      getStaffById(_id).then(res => {

        let {
          firstName,
          lastName,
          emailId,
          phone,
          permissions,
          photo,
          employeeId,
          gender,
          adminRole,
          staffRole
        } = res?.data;

        setStaffData(res?.data);
        setValue('firstName', firstName);
        setValue('lastName', lastName);
        setValue('emailId', emailId);
        setValue('phone', phone);
        setValue('gender', gender)
        setValue('staffRole', staffRole)
        setValue('adminRole', adminRole)
        dispatch(saveEmpId(employeeId));
        setSelectedPermissions([...permissions]);
        setPhoto(photo);
      }).catch(_err => { })

    } else {
      dispatch(generateEmpId());
    }
  }, [])



  const customErrorMessages = {
    required: "Email is required",
    pattern: {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: "Invalid email address",
    },
  };

  const onAddedData = (category) => {
    setShow(false);
    if (category) {
      getDropDownByCategory(category)
    }
  }

  const getDropDownByCategory = (category) => {
    getDropDown(category).then(res => {
      switch (category) {
        case Constants.DROP_DOWN_CATEGORIES.ADMIN_ROLE: {
          let data = [{
            ...adminRole[0]
          }];
          data = [...data, ...res];
          setAdminRole(data);
          if (staffData) {
            let item = data.filter(it => it.value == staffData.adminRole)[0];
            setSelectedAdminRole(item)
          } else {
            setSelectedAdminRole(null);
          }
          break;
        }
        case Constants.DROP_DOWN_CATEGORIES.STAFF_ROLE: {
          let data = [{
            ...staffRole[0]
          }];
          data = [...data, ...res];
          setStaffRole(data);
          if (staffData) {
            let item = data.filter(it => it.value == staffData.staffRole)[0];
            setSelectedStaffRole(item)
          } else {
            setSelectedStaffRole(null);
          }
          break;
        }
        case Constants.DROP_DOWN_CATEGORIES.GENDER: {
          let data = [{
            ...gender[0]
          }];
          data = [...data, ...res];
          setGender(data);
          if (staffData) {
            let genderItem = data.filter(it => `${it.value}`.toLowerCase() == `${staffData.gender}`.toLowerCase())[0];
            setSelectedGender(genderItem)
          } else {
            setSelectedGender(null);
          }
          break;
        }
      }
    })
  }

  const onClickDelete = (data) => {
    setDeleteData(data);
    setShowDelete(true);
  }

  const onClickDeleteFromPopup = () => {
    setShowDelete(false)
    let {
      _id,
      dropDownType
    } = deleteData;

    deleteById(_id, dropDownType);
  }

  const onClickEdit = (data) => {
    setCategory(data.dropDownType);
    setEditData({
      label: data.label,
      value: data.value,
      id: data._id
    });
    setShow(true);
  }

  const deleteById = (id, dropDownType) => {
    deleteDropDownById(id).then(_res => {
      getDropDownByCategory(dropDownType)
    })
  }

  const onSubmit = (data) => {

    let {
      firstName,
      lastName,
      emailId,
      password,
      phone
    } = data
    let request = {
      "employeeId": employeeId,
      "firstName": firstName,
      "lastName": lastName,
      "gender": selectedGender?.value,
      "emailId": emailId,
      "password": password,
      "phone": phone,
      "staffRole": selectedStaffRole?.value,
      "adminRole": selectedAdminRole?.value,
      "photo": photo ?? '',
      "permissions": [...selectedPermissions]
    };


    if (photo && typeof photo != 'string' && photo?.size) {
      upload(S3_BUCKET_FOLDER.STAFFS, photo).then(res => {
        request['photo'] = res.fileName;
        if(staffData){
          request["_id"] = staffData?._id;
          delete request['employeeId']
          editStaffUser(request);
        }else{
          createStaffUser(request);
        }
      })
    } else {
      if(staffData){
        request["_id"] = staffData?._id;
        delete request['employeeId'];
        if(photo && photo?.startsWith('https://')){
          let extractedSubstring = photo.match(/\.com\/(.*?)\?/);
          if (extractedSubstring && extractedSubstring.length > 1) {
            request["photo"] = extractedSubstring[1];
          }
        }
        editStaffUser(request);
      }else{
        createStaffUser(request);
      }
    }
  }

  const createStaffUser = (data) => {
    createStaff(data).then(_res => {
      navigate(-1);
    }).catch(_err => {

    })
  }

  const editStaffUser = (data) => {
    editStaff(data).then(_res => {
      navigate(-1);
    }).catch(_err => {
    })
  }

  const onCheckPermission = (e, index, type) => {
    if(type == 'isEdit'){
      let data = [...selectedPermissions];
      data[index][type] = e;
      if(e){
        data[index]['isView'] = e;
      }
      setSelectedPermissions(data);
    }else{
      let data = [...selectedPermissions];
      if(!e){
        data[index]['isEdit'] = false ;
      }
      data[index]['isView'] = e;
      setSelectedPermissions(data);
    }
   
  }


  return (
    <>
      <form className="edit-user-container" onSubmit={handleSubmit(onSubmit)}>
        <div className="back-to-user-container">
          <img
            src={assets.Icons.left_icon}
            alt="left-icon"
            className="left-icon"
          />
          <span className="back-to-user" onClick={() => navigate(-1)}>
            Back to Staff List
          </span>
        </div>
        <div className="edit-user col-md-6 ">
          <p className="edit-user-details">
            {staffData ? "Edit Staff Details" : "Add New Staff"}{" "}
          </p>
          <div className="col-md-12 d-flex">
            <div className=" col-md-6 ">
              <div className="">
                <label className="login-input-label">Emp Id</label>
                <NormalInput
                  type={"text"}
                  value={employeeId}
                  disabled={true}
                  placeholder="Please Enter Emp Id"
                />
              </div>
              <div className="">
                <label className="login-input-label">First Name</label>
                <NormalInput
                  type={"text"}
                  register={register("firstName", {
                    required: "First Name is required",
                  })}
                  error={errors.firstName}
                  messages={errors}
                  placeholder="Please Enter First Name"
                />
              </div>{" "}
              <div className="">
                <label className="login-input-label">Email</label>
                <NormalInput
                  type={"text"}
                  placeholder={"Please enter your email"}
                  register={register("emailId", {
                    required: customErrorMessages.required,
                    pattern: customErrorMessages.pattern,
                  })}
                  error={errors.emailId}
                  messages={customErrorMessages}
                />
              </div>
              <div className="">
                <label className="login-input-label">Staff Role</label>
                <CustomController
                  name="staffRole"
                  control={control}
                  data={selectedStaffRole}
                  error={errors.staffRole}
                  register={register("staffRole", dropDownValidation('Staff Role'))}
                  render={({ field: { onChange } }) => {
                    return (
                      <NormalSelect
                        options={staffRole}
                        borderGreen
                        value={selectedStaffRole}
                        onClickEdit={onClickEdit}
                        onClickDelete={onClickDelete}
                        error={errors.staffRole}
                        onChange={(e) => {
                          setSelectedStaffRole(e);
                          onChange(e?.value);
                        }}
                      />
                    );
                  }}
                />
              </div>{" "}
              <div className="">
                <label className="login-input-label">Photo</label>
                <FileUpload
                  onChange={(e) => {
                    setPhoto(e);
                  }}
                  file={photo}
                  error={errors.photo}
                  placeholder={"Select an Image"}
                />

              </div>
            </div>
            <div className=" col-md-6 ms-3">
              <div className="">
                <label className="login-input-label">Gender</label>
                <CustomController
                  name="gender"
                  control={control}
                  data={selectedGender}
                  error={errors.gender}
                  register={register("gender", dropDownValidation('Gender'))}
                  render={({ field: { onChange } }) => {
                    return (
                      <NormalSelect
                        options={gender}
                        borderGreen
                        error={errors.gender}
                        onClickEdit={onClickEdit}
                        value={selectedGender}
                        onClickDelete={onClickDelete}
                        onChange={(e) => {
                          setSelectedGender(e);
                          onChange(e?.value);
                        }}
                      />

                    );
                  }}
                />
              </div>
              <div className="">
                <label className="login-input-label">Last Name</label>
                <NormalInput
                  type={"text"}
                  register={register("lastName", {
                    required: "Last Name is required",
                  })}
                  error={errors.lastName}
                  messages={errors}
                  placeholder="Please Enter Last Name"
                />
              </div>{" "}
              {!staffData && (
                <div className="">
                  <label className="login-input-label">Password</label>
                  <NormalInput
                    type={"password"}
                    passwordIcon
                    eyeIcon
                    rightIcon
                    placeholder="Please enter a temporary password"
                    register={register("password", {
                      required: "Password is required",
                      pattern: {
                        value: REGEX.PASSWORD,
                        message: Constants.PASSWORD_ERROR,
                      },
                    })}
                    error={errors.password}
                    messages={errors}
                  />
                </div>
              )}
              <div className="">
                <label className="login-input-label">Phone</label>
                <NormalInput
                  type={"text"}
                  placeholder="Please enter Phone Number"
                  register={register("phone", {
                    required: "Phone Number  is required",
                  })}
                  error={errors.phone}
                  messages={errors}
                />
              </div>
              <div className="">
                <label className="login-input-label">Admin Role</label>
                <CustomController
                  name="adminRole"
                  control={control}
                  data={selectedAdminRole}
                  error={errors.adminRole}
                  register={register("adminRole", dropDownValidation('Admin Role'))}
                  render={({ field: { onChange } }) => {
                    return (
                      <NormalSelect
                        options={adminRole}
                        borderGreen
                        value={selectedAdminRole}
                        error={errors.adminRole}
                        onChange={(e) => {
                          setSelectedAdminRole(e);
                          onChange(e?.value);
                        }}
                      />

                    );
                  }}
                />
              </div>{" "}
            </div>
          </div>
        </div>
        <div className="edit-user col-md-6 mt-3">
          <p className="edit-ticket-status">Select Permissions for Admin Role</p>
          {selectedPermissions.map(({ module, isEdit, isView }, index) => (
            <div className="col-md-5 d-flex mt-2" key={module}>
              <span className="permission-input-label col-md-5">{module}</span>
              <div className="d-flex align-items-center ms-4">
                <CheckBox value={isView} onChange={(e) => onCheckPermission(e.target.checked, index, 'isView')} />
                <label className="permission-input-label mb-1 ms-2 mt-1">View</label>
              </div>
              {module != 'Dashboard' && (<div className="d-flex align-items-center justify-content-center ms-3">
                <CheckBox value={isEdit} onChange={(e) => onCheckPermission(e.target.checked, index, 'isEdit')} />
                <label className="permission-input-label ms-2 mb-1 mt-1">Edit</label>
              </div>
              )}
            </div>
          ))}
          <div className=" d-flex mt-3">
            <div className="edit-cancel-btn">
              <NormalButton
                label=" CANCEL"
                cancelBtn
                onClick={() => navigate("/main/staff")}
              />
            </div>
            <div className="edit-update-btn">
              <NormalButton label={staffData ? `Edit` : `${"ADD"}`} mainBg />
            </div>
          </div>
        </div>
      </form>

      <AddDropDown
        show={show}
        category={category}
        onAddedData={onAddedData}
        editData={editData} />

      <DeleteDropDown
        show={showDelete}
        title={"Delete"}
        description={"Are you sure do you want to delete?"}
        onClickYes={onClickDeleteFromPopup}
        onHide={() => setShowDelete(false)}
      />
    </>
  );
};

export default AddStaff;
