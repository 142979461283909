import React, { useEffect } from "react";
import DashboardCard from "../../components/dashboardCard";
import assets from "../../assets";
import { useState } from "react";
import "../dashboard/styles.scss";
import ChartCard from "../../components/chartCard";
import NormalButton from "../../components/NormalButton";
import "./styles.scss";
import NormalTable from "../../components/NormalTable";
import { useNavigate } from "react-router-dom";
import NoData from "../../components/noData";
import { useDispatch, useSelector } from "react-redux";
import { deletePlan, getAllPlans } from "../../redux/reducers/plansSlice";
import { getRecentSubscription } from "../../redux/reducers/subscriptionSlice";
import { getPaymentDataCount, getSubscriptionByLocation, getSubscriptionGrowth } from "../../redux/reducers/paymentSlice";
import { showLoader } from "../../utils";
import TooltipComponent from "../../components/tooltip";
import DeleteDropDown from "../../components/deleteModal";
const Payment = () => {
  let navigate = useNavigate();

  const headerDetails = [
    { label: "Transaction Id" },
    { label: "Username" },
    { label: "Email" },
    { label: "Amount" },
    { label: "Subscription Id" },
    { label: "Payment Status" },
    { label: "Country" },
  ];

  const subscriptionHeader = [{ label: "Model" }, { label: "Action" }];
  const [showDelete, setShowDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const [cardData, setCardData] = useState([
    {
      id: 1,
      status: "neutral",
      title: "Total Subscriptions",
      content: 0,
      footerImage: assets.Icons.equal_line,
      footerContent: "0% since last month",
      key: 'totalSubscriptions'
    },
    {
      id: 2,
      status: "neutral",
      title: "New Subscriptions",
      content: 0,
      footerImage: assets.Icons.equal_line,
      footerContent: "0% since last month",
      key: 'newSubscription'
    },
    {
      id: 3,
      status: "neutral",
      title: "Active Subscriptions",
      content: 0,
      footerImage: assets.Icons.equal_line,
      footerContent: "0% since last month",
      key: 'activeSubscription'
    },
    {
      id: 1,
      status: "neutral",
      title: "Churn Rate",
      content: 0,
      footerImage: assets.Icons.equal_line,
      footerContent: "0% since last month",
      key: 'churnRate'
    },
  ]);

  const [chartData, setChartData] = useState([
    {
      id: 1,
      title: "Subscription Growth",
      chartType: "line",
      selectOptions: [
        { label: "All", value: 'all' },
        { label: "Month", value: 'month' },
        { label: "Week", value: 'week' },
      ],
      selectedItem: { label: "All", value: 'all' },
      chartData: {
        labels: [],
        datasets: [
          {
            fill: false,
            borderColor: "#4ECD83",
            borderWidth: 2,
            pointBackgroundColor: "rgba(75,192,192,1)",
            pointRadius: 4,
            data: [],
          },
        ],
      },

      chartOptions: {
        scales: {
          x: {
            beginAtZero: false,
            grid: {
              display: false,
            },

            ticks: {
              color: "#351c75",
            },
            axis: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: true,
            },
            border: {
              display: false,
            },
            ticks: {
              stepSize: 50,
              color: "#351c75",
              callback: function (value, index, values) {
                return value.toLocaleString();
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    },
    {
      id: 2,
      title: "Subscription by Location",
      chartType: "Bar",
      selectOptions: [
        { label: "All", value: 'all' },
        { label: "Month", value: 'month' },
        { label: "Week", value: 'week' },
      ],
      selectedItem: { label: "All", value: 'all' },
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [
              "#DDCDF7",
              "#DDCDF7",
              "#DDCDF7",
              "#DDCDF7",
              "#DDCDF7",
            ],
            borderWidth: 1,
            borderRadius: 5,
            data: [],
          },
        ],
      },

      chartOptions: {
        scales: {
          x: {
            beginAtZero: false,
            grid: {
              display: false,
            },

            ticks: {
              color: "#351c75",
            },
            axis: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: true,
            },
            border: {
              display: false,
            },
            ticks: {
              stepSize: 25,
              color: "#351c75",
              callback: function (value, index, values) {
                return value.toLocaleString();
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    },
  ]);

  const dispatch = useDispatch();

  const planData = useSelector((state) => state?.plans?.data);
  const recentSubscription = useSelector((state) => state?.subscriptions?.recentSubscription);

  const paymentData = useSelector((state) => state?.payment?.paymentData);

  const DEFALUT_TYPE = 'all';

  const SUBSCRIPTION_GROWTH = 0;
  const SUBSCRIPTION_BY_LOCATION = 1;

  useEffect(() => {
    showLoader(true)
    dispatch(getAllPlans());
    dispatch(getRecentSubscription());

    dispatch(getPaymentDataCount());
    subscriptionGrowth(DEFALUT_TYPE, SUBSCRIPTION_GROWTH);
    subscriptionByLocation(DEFALUT_TYPE, SUBSCRIPTION_BY_LOCATION);
  }, []);


  useEffect(() => {

    let data = [...cardData];

    let item = { ...paymentData };
    if (Object.keys(item).length > 0) {
      data.map(it => {
        let object = {
          ...paymentData[it?.key],
        }

        let {
          percentage,
          count
        } = object;

        it["status"] = percentage > 0 ? "profit" : percentage < 0 ? "loss" : "neutral";
        it["footerImage"] = percentage > 0 ? assets.Icons.up_trend : percentage < 0 ? assets.Icons.down_trend : assets.Icons.equal_line;
        it["footerContent"] = percentage % 1 === 0 ? `${percentage}% since last month` : `${percentage.toFixed(2)}% since last month`;
        it["content"] = (it.key == 'churnRate') ? `${count}%` : count;
      });
      showLoader(false)

    };

    setCardData(data);
  }, [paymentData]);





  const subscriptionGrowth = (type, index) => {
    getSubscriptionGrowth(type).then(res => {
      updateData(res?.data, index)
    }).catch(_err => { })
  }

  const subscriptionByLocation = (type, index) => {
    getSubscriptionByLocation(type).then(res => {
      updateData(res?.data, index)
    }).catch(_err => { })
  }

  const updateData = (data, index) => {
    let item = [...chartData];
    item[index].chartData.labels = [...data?.labels];
    item[index].chartData.datasets[0].data = [...data?.dataSets];
    item[index].loading = ''

    setChartData([...item])
  }

  const onChangeOptions = (e, title, index) => {
    let item = [...chartData];
    item[index].chartData.labels = [];
    item[index].chartData.datasets[0].data = [];
    item[index].selectedItem = e;
    item[index].loading = 'Loading...'
    setChartData([...item]);

    switch (title) {
      case "Subscription by Location": {
        subscriptionByLocation(e?.value, index)
        break;
      }
      case "Subscription Growth": {
        subscriptionGrowth(e?.value, index)
        break;
      }
    }
  }

  const onClickEdit = (data) =>{
    navigate("/main/add-subscription", {
      state: {
        data: data
      }
    })
  }

  const onClickDelete = (data) =>{
    setDeleteData(data);
    setShowDelete(true);
  }

  const onClickDeleteFromPopup = () => {
    setShowDelete(false)
    let {
      _id,
    } = deleteData;

    deleteById(_id);
  }

  const deleteById = (id) => {
    deletePlan(id).then(_res => {
      setDeleteData(null);
      dispatch(getAllPlans());
    }).catch(_err=>{
      
    })
  }


  return (
    <div className="dashboard-container">
      <div className="dashboard-title">
        <p>Payment</p>
      </div>
      <div className="d-flex mb-3">
        {cardData.map((data, index) => {
          return (
            <div key={data.title} className="payment-chart-component-container">
              <DashboardCard
                cardStatus={data.status}
                title={data.title}
                content={data.content}
                footerImage={data.footerImage}
                index={index}
                footerContent={data.footerContent}
              />
            </div>
          )
        })}
      </div>
      <div className="d-flex flex-wrap">
        {chartData.map((list, index) => {
          return (
            <div key={list.title} className="payment-chart-component-container">
              <ChartCard
                chartType={list.chartType}
                title={list.title}
                selectOptions={list.selectOptions}
                data={list.chartData}
                options={list.chartOptions}
                footerData={list.footerData}
                selectedItem={list.selectedItem}
                index={index}
                loading={list.loading}
                onChangeOptions={(e, title, index) => onChangeOptions(e, title, index)}
              />
            </div>
          )
        })}
      </div>
      <div className="col-md-12 d-flex justify-content-between">
        <div className="col-md-8 mt-3">
          <div className="d-flex justify-content-between">
            <p className="recent-subscription">Recent Subscriptions</p>
            <NormalButton
              label="MANAGE ALL"
              mainBg
              onClick={() => navigate("/main/manage-subscriptions")}
            />
          </div>
          <div className="mt-3">
            <NormalTable headerDetails={headerDetails} isBorder>
              {recentSubscription.length > 0 ? (
                <>
                  {recentSubscription.map((list) => {
                    let {
                      _id,
                      userName,
                      emailId,
                      totalAmount,
                      subscriptionId,
                      countryName,
                      type
                    } = list;
                    return (
                      <tr key={_id}>
                        <td>{`${_id}`.slice(-8).toUpperCase()}</td>
                        <td>{userName}</td>
                        <td>{emailId}</td>
                        <td>{totalAmount}</td>
                        <td>
                        {
                            type == "Razorpay" ? <a href={`https://dashboard.razorpay.com/app/subscriptions/${subscriptionId}`} target="_blank">{subscriptionId}</a> : <label>{subscriptionId}</label>
                           }
                          
                          </td>
                        <td>{type}</td>
                        <td>{countryName}</td>
                      </tr>
                    );
                  })}
                </>
              ) : <tr>
                <td colSpan={6} className="text-center">
                  <NoData />
                </td>
              </tr>}
            </NormalTable>
          </div>
        </div>
        <div className="col-md-3 mt-4 ms-3 subscription-model">
          <p className="recent-subscription">Subscription Models</p>
          <div className="mt-4">
            <NormalTable headerDetails={subscriptionHeader} subscriptionTable isBorder>
              {planData.length > 0 ? (
                <>
                  {planData.map((data) => {
                    return (
                      <tr key={data.planName}>
                        <td>
                          {data.planName}
                        </td>
                        <td align="center">
                          <TooltipComponent
                            onClickEdit={() => onClickEdit(data)}
                            onClickDelete={() => onClickDelete(data)} />
                        </td>
                        {/* <td>
                          <img
                            src={assets.Icons.edit}
                            alt="edit"
                            className="payment-edit-icon"
                            onClick={() => navigate("/main/add-subscription")}
                          />
                        </td> */}
                      </tr>
                    );
                  })}
                </>
              ) : <tr>
                <td colSpan={2} className="text-center">
                  <NoData />
                </td>
              </tr>}
            </NormalTable>
            <div className="mt-1">
              <NormalButton
                label="CREATE NEW MODEL"
                cancelBtn
                onClick={() => navigate("/main/add-subscription")}
              />
            </div>
          </div>
        </div>
      </div>

      <DeleteDropDown
        show={showDelete}
        title={"Delete"}
        screen={"Payment"}
        description={"Are you sure do you want to delete?"}
        onClickYes={onClickDeleteFromPopup}
        onHide={() => setShowDelete(false)}
      />
    </div>
  );
};

export default Payment;
