import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";

const CheckBox = ({
  name,
  value,
  disabled,
  onChange
}) => {
 
  return (
    <>
      <input
        type={"checkbox"}
        name={name}
        onChange={onChange}
        checked={value}
        disabled={disabled}
        className={`custom-checkbox`} />
    </>
  );
};

export default CheckBox;
