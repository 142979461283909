import React, { useEffect, useState } from "react";
import "../login/styles.scss";
import assets from "../../assets";
import NormalInput from "../../components/inputField";
import NormalButton from "../../components/NormalButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FileUpload from "../../components/FileUpload";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createTutorials, generateTutorialId, getTutorialsById, saveTutorialId, updateTutorials } from "../../redux/reducers/tutorialSlice";
import { S3_BUCKET_FOLDER, dropDownValidation, showLoader, upload } from "../../utils";
import CustomController from "../../components/customController";
import './styles.scss'

const AddTutorial = () => {
  let navigate = useNavigate();

  const location = useLocation();

  const { state } = location;

  const [tutorialData, setTutorialData] = useState(state?.data);

  const tutorialId = useSelector((state) => state?.tutorials?.tutorialId);

  const [media, setMedia] = useState(null);


  const [icon, setIcon] = useState(null);

  const [mediaFileName, setMediaFileName] = useState(null);
  const [duration, setDuration] = useState('');


  const dispatch = useDispatch();

  useEffect(() => {

    if (tutorialData) {

      let {
        _id
      } = tutorialData;

      getTutorialsById(_id).then(res => {

        let {
          contentId,
          media,
          icon,
          duration,
          title,
          iconLink
        } = res?.data;

        setTutorialData(res?.data);
        setValue('contentId', contentId);
        setValue('media', media);
        setValue('icon', icon);
        setValue('duration', duration);
        setValue('title', title)
        setIcon(iconLink);
        setMediaFileName(media);
        setMedia(media)
        dispatch(saveTutorialId(contentId));
        setDuration(duration)
      }).catch(_err => { })

    } else {
      dispatch(generateTutorialId());
    }
  }, [])


  const onSubmit = (data) => {
    data["contentId"] = tutorialId;

    if (tutorialData) {

      let requestMedia = [];
      if (icon && typeof icon != 'string' && icon?.size) {
        requestMedia.push(upload(S3_BUCKET_FOLDER.TUTORIAL_ICONS, icon, false, 'icon'));
      }

      if (media && typeof media != 'string' && media?.size) {
        requestMedia.push(upload(S3_BUCKET_FOLDER.TUTORIAL_VIDEO, media, false, 'media'));
      }

      data["_id"] = tutorialData?._id;
      delete data['contentId']

      if (requestMedia.length > 0) {
        showLoader(true);
        Promise.all([...requestMedia]).then(results => {
          showLoader(false)
          results.forEach((result, _index) => {
            data[result?.key] = result["fileName"]
          });
          updateFields(data);
        })
      } else {
        updateFields(data)
      }


    } else {
      showLoader(true);

      let requestIcon = upload(S3_BUCKET_FOLDER.TUTORIAL_ICONS, icon, false, 'icon');
      let requestMedia = upload(S3_BUCKET_FOLDER.TUTORIAL_VIDEO, media, false, 'media');

      Promise.all([requestIcon, requestMedia]).then(results => {
        showLoader(false);
        results.forEach((result, _index) => {
          data[result?.key] = result["fileName"]
        });

        createTutorials(data).then(_res => {
          navigate(-1);
        }).catch(_err => { })
      })
    }
  };

  const updateFields = (data) => {
    updateTutorials(data).then(_res => {
      navigate(-1)
    }).catch(_errr => { })
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue
  } = useForm({ mode: "onBlur" });
  return (
    <form className="edit-user-container" onSubmit={handleSubmit(onSubmit)}>
      <div className="back-to-user-container">
        <img
          src={assets.Icons.left_icon}
          alt="left-icon"
          className="left-icon"
        />
        <span
          className="back-to-user"
          onClick={() => navigate("/main/content")}
        >
          Back to Content
        </span>
      </div>
      <div className="edit-user col-md-6 ">
        <p className="edit-user-details">
          {tutorialData ? "Edit Learn Tutorial" : "Add a new Tutorial"}
        </p>
        <div className="col-md-12 d-flex">
          <div className=" col-md-6 ">
            <div className="">
              <label className="login-input-label">Content ID</label>
              <NormalInput
                type={"text"}
                disabled
                value={tutorialId}
                placeholder="Please Enter Content Id"
              />
            </div>
            <div className="">
              <label className="login-input-label">Media File</label>
              <CustomController
                name="media"
                control={control}
                error={errors.media}
                register={register("media", dropDownValidation('Media File'))}
                render={({ field: { onChange } }) => {
                  return (
                    <FileUpload
                      placeholder="Select an file"
                      accept="video/mp4"
                      onCalculateDuration={(e) => {
                        setDuration(e);
                        setValue('duration', e);
                      }}
                      fileNameData={mediaFileName}
                      onChange={(e) => {
                        setMedia(e);
                        if (e == null) {
                          onChange('');
                          setValue('duration', '');
                          setDuration('')

                        } else {
                          onChange("File uploaded")
                        }
                      }}
                      error={errors.media}
                    />
                  );
                }}
              />
            </div>
            <div className="">
              <label className="login-input-label duration">Duration (In Minutes)</label>
              <NormalInput
                type={"text"}
                placeholder="Choose a file, and the duration will be calculated."
                disabled
                value={duration}
              />
            </div>
          </div>
          <div className=" col-md-6 ms-3">
            <div className="">
              <label className="login-input-label">Title</label>
              <NormalInput
                type={"text"}
                placeholder={"Please enter a title for the tutorial video"}
                register={register("title", {
                  required: "Title is required",
                })}
                error={errors.title}
                messages={errors}
              />
            </div>
            <div className="">
              <label className="login-input-label">Icon </label>

              <CustomController
                name="icon"
                control={control}
                error={errors.icon}
                register={register("icon", dropDownValidation('Icon'))}
                render={({ field: { onChange } }) => {
                  return (
                    <FileUpload
                      placeholder={"Select an image"}
                      file={icon}
                      onChange={(e) => {
                        setIcon(e);
                        if (e == null) {
                          onChange('')
                        } else {
                          onChange("File uploaded")
                        }
                      }}
                      error={errors.icon}
                    />
                  );
                }}
              />
            </div>{" "}
          </div>
        </div>
        <div className=" d-flex mt-3">
          <div className="edit-cancel-btn">
            <NormalButton
              label=" CANCEL"
              cancelBtn
              onClick={() => navigate("/main/content")}
            />
          </div>
          <div className="edit-update-btn">
            <NormalButton label={`${tutorialData ? "UPDATE" : "ADD"}`} mainBg />
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddTutorial;
