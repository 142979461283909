import React, { useEffect, useState } from "react";
import "./styles.scss";
import NormalModal from "../NormalModal";
import NormalButton from "../NormalButton";
import DatePicker from "../DatePicker";
import { Form } from "react-bootstrap";
import NormalTable from "../NormalTable";
import NoData from "../noData";
import ExportedData from "../../assets";
import MultiSelect from "../multiSelect";
import { applyPromo, applyUniversalFilters, getUniversalFiltersDropdowns } from "../../redux/reducers/userSlice";
import moment from "moment";
import { calculatePaymentDuration, checkPaymentDuration, errorToast } from "../../utils";
import NormalSelect from "../NormalSelect";

const UniversalFilters = ({ show, onHide, onSuccess }) => {

    const [modalOpen, setModalOpen] = useState(show);
    const [activeLabel, setActiveLabel] = useState("Username");
    const [selectedDateOption, setSelectedDateOption] = useState("specificDate");
    const [managePremium, setManagePremium] = useState(false);

    const [usernameOptions, setUserNameOptions] = useState([]);
    const [emailOptions, setEmailOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [plansOptions, setPlanOptions] = useState([]);
    const [permissionOptions, setPermissionOptions] = useState([]);
    const [paymentOptions] = useState([
        { label: "1 month", value: 30 },
        { label: "3 months", value: 90 },
        { label: "6 months", value: 180 },
        { label: "12 months", value: 360 },
    ]);
    const [subscriptionFilterOptions] = useState([
        { label: "Subscription ends in 3 days", value: 3 },
        { label: "Subscription ends in 7 days", value: 7 },
    ]);

    const [selectedPlans, setSelectedPlans] = useState([]);
    const [selectedPermisions, setSelectedPermissions] = useState([]);
    const [selectedUserNames, setSelectedUserNames] = useState([]);
    const [selectedEmailIds, setSelectedEmailIds] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedPaymentOptions, setSelectedPaymentOptions] = useState([]);
    const [selectedSubsEnd, setSelectedSubsEnd] = useState([]);

    const [selectedSpecificDate, setSelectedSpecificDate] = useState(null);

    const [selectedDateRange, setSelectedDateRange] = useState({
        fromDate: null,
        endDate: null
    })

    useEffect(() => {
        getUniversalFilters()
    }, [])


    const getUniversalFilters = () => {
        getUniversalFiltersDropdowns().then(res => {

            let {
                countries,
                emailIds,
                permissions,
                plans,
                userNames
            } = res;

            setCountryOptions(countries);
            setPlanOptions(plans);
            setEmailOptions(emailIds);
            setUserNameOptions(userNames)
            setPermissionOptions(permissions)


        }).catch(_err => {

        })
    }

    const filterMenu = [
        { label: "Username" },
        { label: "Email" },
        { label: "DOB" },
        { label: "Country" },
        { label: "Subscription" },
        { label: "Payment Duration" },
        { label: "Subscription / Promo End Date" },
        { label: "Permissions" },
    ];


    const filterHeaderDetails = [
        { label: "User Id" },
        { label: "Username" },
        { label: "Email" },
        { label: "DOB" },
        { label: "Country" },
        { label: "OS" },
        { label: "Sign In Mode" },
        { label: "Date of Sign Up" },
        { label: "Profile Verified" },
        { label: "Subscription Type" },
        { label: "Payment Duration" },
        { label: "Payment Type" },
        { label: "App Tracking Permission", notificationIcon: 'User allowsZEN-it to track them across other apps and websites (App Tracking Transparency)' },
        { label: "Location Data Tracking Permission", notificationIcon: 'User allowsZEN-it to track them across other apps and websites (App Tracking Transparency)' },
        { label: "Device Data Tracking Permission", notificationIcon: 'User allowsZEN-it to track them across other apps and websites (App Tracking Transparency)' },
        { label: "Marketing Comm. Permission", notificationIcon: 'User allowsZEN-it to track them across other apps and websites (App Tracking Transparency)' },
    ];

    useEffect(() => {
        setModalOpen(show)
    }, [show])

    const hideModal = () => {
        onHide();
        setModalOpen(false);
    }

    const handleActiveLabel = (label) => {
        setActiveLabel(label)
    }

    const onClickClearFilters = () => {
        setSelectedUserNames([])
        setSelectedEmailIds([])
        //dob
        setSelectedSpecificDate(null)
        setSelectedDateRange({
            fromDate: null,
            endDate: null
        })
        setSelectedCountries([])
        setSelectedPlans([])
        setSelectedPaymentOptions([])
        setSelectedSubsEnd([])
        setSelectedPermissions([]);
        setFinalPlan(null);
        setFromDate(null);
        setEndDate(null);
        setFilteredUsers([]);
        setManagePremium(false)

    }

    const [filteredUsers, setFilteredUsers] = useState([]);
    const [appleCount, setAppleCount] = useState(0);

    const [finalPlan, setFinalPlan] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const applyFilterAndGetUsers = () => {
        let request = {
            userNames: retrieveValueFromArray(selectedUserNames, 'label'),
            countries: retrieveValueFromArray(selectedCountries, 'label'),
            planNames: retrieveValueFromArray(selectedPlans, 'label'),
            dob: selectedDateOption === "specificDate" ? (selectedSpecificDate ? moment(selectedSpecificDate).format("YYYY-MM-DD") : '') : {
                fromDate: (selectedDateRange.fromDate ? moment(selectedDateRange.fromDate).format("YYYY-MM-DD") : ''),
                endDate: (selectedDateRange.endDate ? moment(selectedDateRange.endDate).format("YYYY-MM-DD") : ''),
            },
            subsEnd: retrieveValueFromArray(selectedSubsEnd, 'value'),
            permissions: retrieveValueFromArray(selectedPermisions, 'value'),
            emailIds: retrieveValueFromArray(selectedEmailIds, 'label'),
            paymentOptions: retrieveValueFromArray(selectedPaymentOptions, 'value')
        }

        applyUniversalFilters(request).then(res => {
            let data = [...res];
            if (data.length > 0) {
                setManagePremium(true);
                let isApple = data.filter(it => it.paymentType == 'Apple');
                setAppleCount(isApple.length);
                setFilteredUsers([...res]);
            } else {
                errorToast("No users were located for the chosen filter. Adjust the filters to retrieve the users.")
            }
        }).catch(_err => {

        })
    }

    const retrieveValueFromArray = (data, key) => {
        let list = [];

        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            list.push(element[key]);
        }

        return list;
    }

    const onClickSave = () => {

        if(appleCount > 0){
            errorToast(`Filtered Users has ${appleCount} apple subscription.`);
            return
        }

        let userIds = []
        filteredUsers.map(it => {
            userIds.push(it?._id)
        })

        let request = {
            planId: finalPlan?.value,
            fromDate: fromDate ? moment(fromDate).format("YYYY-MM-DD") : '',
            endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : '',
            userIds
        }

        applyPromo(request).then(_res => {
            onClickClearFilters();
            hideModal();
            onSuccess()
        }).catch(_err => { })
    }

    return (
        <div className="user-filter-popup-container">
            <NormalModal show={modalOpen} filterModal={true} setModal={(e) => hideModal()}>
                {!managePremium ? (
                    <div>
                        <div className="user-filter-popup">
                            <span className="delete-users">Filters</span>
                            <img
                                src={ExportedData.Icons.close_popup}
                                alt="close"
                                className="popup-close-icon"
                                onClick={() => hideModal()}
                            />
                        </div>
                        <div className="col-md-12 d-flex user-filter-main-container">
                            <div className="col-md-3 filter-left-container">
                                {filterMenu.map((list) => {
                                    return (
                                        <div className="filter-popup-label" key={list.label}>
                                            <span
                                                className={`${activeLabel === list.label
                                                    ? "filter-active-label"
                                                    : ""
                                                    }`}
                                                onClick={() => handleActiveLabel(list.label)}
                                            >
                                                {" "}
                                                {list.label}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="col-md-9 filter-right-container">
                                {activeLabel === "Username" ? (
                                    <MultiSelect
                                        options={usernameOptions}
                                        value={selectedUserNames}
                                        onChange={(e) => {
                                            setSelectedUserNames(e);
                                        }}
                                    />
                                ) : activeLabel === "Email" ? (
                                    <MultiSelect
                                        options={emailOptions}
                                        value={selectedEmailIds}
                                        onChange={(e) => {
                                            setSelectedEmailIds(e);
                                        }}
                                    />
                                ) : activeLabel === "DOB" ? (
                                    <>
                                        <div className="d-flex">
                                            <div className="me-3">
                                                <div className="d-flex align-items-center">
                                                    <Form.Check
                                                        inline
                                                        value="specificDate"
                                                        checked={selectedDateOption === "specificDate"}
                                                        onChange={() =>
                                                            setSelectedDateOption("specificDate")
                                                        }
                                                        type="radio"
                                                    />
                                                    <span className="login-input-label mb-2">
                                                        Specific Date
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="d-flex align-items-center">
                                                    <Form.Check
                                                        inline
                                                        value="dateRange"
                                                        checked={selectedDateOption === "dateRange"}
                                                        onChange={() =>
                                                            setSelectedDateOption("dateRange")
                                                        }
                                                        type="radio"
                                                    />
                                                    <span className="login-input-label mb-2">
                                                        Range
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {selectedDateOption === "specificDate" && (
                                            <div className="col-md-5">
                                                <div className="filter-date-input mt-2">
                                                    <DatePicker
                                                        calendarStyle={true}
                                                        placeHolder={true}
                                                        isWeekdays={true}
                                                        value={selectedSpecificDate}
                                                        format={"dd-MM-yyyy"}
                                                        placeholderText="DD/MM/YYYY"
                                                        dateIcon
                                                        onChange={(e) => {
                                                            setSelectedSpecificDate(e);
                                                            setSelectedDateRange({
                                                                fromDate: null,
                                                                endDate: null
                                                            })
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                        )}

                                        {selectedDateOption === "dateRange" && (
                                            <div className="col-md-8">
                                                <div className="filter-date-input">
                                                    <div className="filter-from-input">
                                                        <label className="login-input-label">From</label>
                                                        <DatePicker
                                                            calendarStyle={true}
                                                            placeHolder={true}
                                                            isWeekdays={true}
                                                            value={selectedDateRange.fromDate}
                                                            format={"dd-MM-yyyy"}
                                                            onChange={(e) => {
                                                                setSelectedSpecificDate(null);
                                                                let endDate = selectedDateRange.endDate;
                                                                setSelectedDateRange({
                                                                    fromDate: e,
                                                                    endDate: endDate
                                                                })
                                                            }}
                                                            placeholderText="DD/MM/YYYY"
                                                            dateIcon
                                                        />
                                                    </div>
                                                    <div className="filter-to-input">
                                                        <label className="login-input-label">To</label>
                                                        <DatePicker
                                                            calendarStyle={true}
                                                            placeHolder={true}
                                                            isWeekdays={true}
                                                            format={"dd-MM-yyyy"}
                                                            placeholderText="DD/MM/YYYY"
                                                            value={selectedDateRange.endDate}
                                                            onChange={(e) => {
                                                                setSelectedSpecificDate(null);
                                                                let fromDate = selectedDateRange.fromDate;
                                                                setSelectedDateRange({
                                                                    fromDate: fromDate,
                                                                    endDate: e
                                                                })
                                                            }}
                                                            dateIcon
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : activeLabel === "Country" ? (
                                    <MultiSelect
                                        options={countryOptions}
                                        value={selectedCountries}
                                        onChange={(e) => {
                                            setSelectedCountries(e);
                                        }}
                                    />
                                ) : activeLabel === "Subscription" ? (
                                    <MultiSelect
                                        options={plansOptions}
                                        value={selectedPlans}
                                        onChange={(e) => {
                                            setSelectedPlans(e);
                                        }}
                                    />
                                ) : activeLabel === "Payment Duration" ? (
                                    <MultiSelect
                                        options={paymentOptions}
                                        value={selectedPaymentOptions}
                                        onChange={(e) => {
                                            setSelectedPaymentOptions(e);
                                        }}
                                    />
                                ) : activeLabel === "Subscription / Promo End Date" ? (
                                    <MultiSelect
                                        options={subscriptionFilterOptions}
                                        value={selectedSubsEnd}
                                        onChange={(e) => {
                                            setSelectedSubsEnd(e);
                                        }}
                                    />
                                ) : activeLabel === "Permissions" ? (
                                    <MultiSelect
                                        options={permissionOptions}
                                        value={selectedPermisions}
                                        onChange={(e) => {
                                            setSelectedPermissions(e);
                                        }}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        <div className="user-filter-btn-container ">
                            <div className="col-md-4">
                                <div className="clear-filter" onClick={() => onClickClearFilters()}>
                                    <span> Clear Filters</span>
                                </div>
                            </div>
                            <div className="d-flex col-md-8">
                                <div className="cancel-btn-container">
                                    <NormalButton
                                        label=" CANCEL"
                                        cancelBtn
                                        onClick={() => onHide()}
                                    />
                                </div>
                                <div className="promo-btn-container">
                                    <NormalButton
                                        label=" MANAGE PREMIUM (PROMO) STATUS"
                                        cancelBtn
                                        onClick={() => applyFilterAndGetUsers()}
                                    />
                                </div>

                                <div className="delete-btn-container">
                                    <NormalButton label="SAVE" mainBg />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="user-filter-popup">
                            <span className="delete-users">
                                Manage Premium (Promo) Status
                            </span>
                            <img
                                src={ExportedData.Icons.close_popup}
                                alt="close"
                                className="popup-close-icon"
                                onClick={() => onHide()}
                            />
                        </div>
                        <div className="col-md-12 d-flex user-filter-main-container ">
                            <div className="manage-left-container">
                                <label className="login-input-label">
                                    Change Subscription To
                                </label>
                                <NormalSelect
                                    options={plansOptions}
                                    value={finalPlan}
                                    disableButtons
                                    onChange={(e) => {
                                        setFinalPlan(e)
                                    }}
                                />

                                {
                                    appleCount > 0 &&

                                        <div>
                                            <label className="filtered-apple-user">{`Filtered Users has ${appleCount} apple subscription.`}</label>
                                        </div>
                                }

                                

                            </div>
                            <div className=" manage-center-container">
                                <div className="d-flex ps-3">
                                    <div className="filter-date-input ">
                                        <div className="filter-from-input">
                                            <label className="login-input-label">From</label>
                                            <DatePicker
                                                calendarStyle={true}
                                                placeHolder={true}
                                                isWeekdays={true}
                                                format={"dd-MM-yyyy"}
                                                placeholderText="DD/MM/YYYY"
                                                dateIcon
                                                value={fromDate}
                                                onChange={(e) => {
                                                    setFromDate(e)
                                                }}
                                            />
                                        </div>
                                        <div className="filter-to-input">
                                            <label className="login-input-label">To</label>
                                            <DatePicker
                                                calendarStyle={true}
                                                placeHolder={true}
                                                isWeekdays={true}
                                                format={"dd-MM-yyyy"}
                                                placeholderText="DD/MM/YYYY"
                                                dateIcon
                                                value={endDate}
                                                onChange={(e) => {
                                                    setEndDate(e)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="manage-right-container">
                                <label className="login-input-label">
                                    For the following users
                                </label>
                                <div className="filter-users-table-container">
                                    <NormalTable
                                        headerDetails={filterHeaderDetails}
                                    >
                                        {filteredUsers.length > 0 ? (
                                            <>
                                                {filteredUsers.map((list) => {
                                                    let {
                                                        _id,
                                                        userName,
                                                        emailId,
                                                        dob,
                                                        countryName,
                                                        os,
                                                        signInMode,
                                                        signUpDate,
                                                        isProfileVerified,
                                                        subscriptionType,
                                                        duration,
                                                        unit,
                                                        paymentType,
                                                        appTrackingPermission,
                                                        locationTrackingPermission,
                                                        deviceTrackingPermission,
                                                        marketingTrackingPermission,
                                                    } = list;
                                                    return (
                                                            <tr key={_id} className={paymentType == 'Apple' ? 'disabled-apple' : ''}>
                                                                <td>{`${_id}`.slice(-8).toUpperCase()}</td>
                                                                <td>{userName}</td>
                                                                <td>{emailId}</td>
                                                                <td>{moment(dob).format("DD MMM YYYY")}</td>
                                                                <td>{countryName}</td>
                                                                <td>{os}</td>
                                                                <td>{signInMode}</td>
                                                                <td>{signUpDate ? moment(signUpDate).format("DD MMM YYYY") : 'NA'}</td>
                                                                <td>{isProfileVerified ? 'Yes' : 'No'}</td>
                                                                <td>{subscriptionType}</td>
                                                                <td>{`${duration} ${unit}`}</td>
                                                                <td>{paymentType}</td>
                                                                <td>{appTrackingPermission ? 'Yes' : 'No'}</td>
                                                                <td>{locationTrackingPermission ? 'Yes' : 'No'}</td>
                                                                <td>{deviceTrackingPermission ? 'Yes' : 'No'}</td>
                                                                <td>{marketingTrackingPermission ? 'Yes' : 'No'}</td>
                                                            </tr>
                                                    );
                                                })}
                                            </>
                                        ) : <tr>
                                            <td colSpan={6} className="text-center">
                                                <NoData />
                                            </td>
                                        </tr>}
                                    </NormalTable>
                                </div>
                            </div>
                        </div>
                        <div className="manage-btn-container ">
                            <div className=" d-flex justify-content-end ">
                                <div className="cancel-btn-container">
                                    <NormalButton
                                        label=" CANCEL"
                                        cancelBtn
                                        onClick={() => setManagePremium(false)}
                                    />
                                </div>

                                <div className="delete-btn-container">
                                    <NormalButton label="SAVE" mainBg onClick={onClickSave} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </NormalModal>
        </div>
    );
};

export default UniversalFilters;