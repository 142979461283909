import React, { useEffect } from "react";
import "./styles.scss";
import assets from "../../assets";
import NormalButton from "../../components/NormalButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPrivacyTerms } from "../../redux/reducers/privacyTermsSlice";
import {
  Editor,
  EditorProvider,
} from 'react-simple-wysiwyg';

const PrivacyTerms = () => {

  let navigate = useNavigate();

  const dispatch = useDispatch();

  const terms = useSelector((state) => state.privacyTerms?.terms)
  const privacy = useSelector((state) => state.privacyTerms?.privacy)

  useEffect(() => {
    dispatch(getPrivacyTerms());
  }, []);

  const openBrowser = (title, data) => {
    if (data.startsWith('https://')) {

      window.open(data, '_blank');

    } else {
      const htmlContent = `
              <!DOCTYPE html>
              <html>
                <head>
                  <title>ZenIt - ${title}</title>
                </head>
                <body>
                  ${data}
                </body>
              </html>`;

      const htmlBlob = new Blob([htmlContent], { type: 'text/html' });

      const htmlBlobURL = URL.createObjectURL(htmlBlob);

      const htmlTab = window.open(htmlBlobURL, '_blank');

      htmlTab.addEventListener('beforeunload', () => {
        URL.revokeObjectURL(htmlBlobURL);
      });
    }

  }


  return (
    <div className="terms-main-container">
      <div className="">
        <p className="terms-title">ZEN-it App Terms of Use</p>
        <div className="col-md-12">
          <EditorProvider>
            <Editor value={terms ? ( terms?.file && `${terms?.file}`.startsWith('https://') ? `Pdf file uploaded for Terms and Condition` : terms?.content) : 'No Data Avaiable'} disabled containerProps={{
              style:
              {
                color: '#0a6a78',
                fontFamily: 'Arial, sans-serif',
                height: 200,
                overflow: 'auto',
                borderColor: '#0a6a78'
              }
            }} >
            </Editor>
          </EditorProvider>
        </div>
        <div className="d-flex justify-content-end align-items-center mt-3">
          <img
            src={assets.Icons.privacy_edit}
            alt="terms&privacy"
            onClick={() => openBrowser('Terms and Condition', terms?.file ? terms?.file : terms?.content)}
            className="terms-icon"
          />
          <div className="">
            <NormalButton
              label={terms ? "EDIT" : "ADD"}
              mainBg
              onClick={() => navigate("/main/edit-terms")}
            />
          </div>
        </div>
      </div>
      <div className="">
        <p className="terms-title">ZEN-it App Privacy Policy</p>
        <div className="col-md-12">
          <EditorProvider>
            <Editor value={privacy ? ( privacy?.file && `${privacy?.file}`.startsWith('https://') ? `Pdf file uploaded for Privacy Policy` : privacy?.content) : 'No Data Avaiable'} disabled containerProps={{
              style:
              {
                color: '#0a6a78',
                fontFamily: 'Arial, sans-serif',
                height: 200,
                overflow: 'auto',
                borderColor: '#0a6a78'
              }
            }} >
            </Editor>
          </EditorProvider>
        </div>
        <div className="d-flex justify-content-end align-items-center mt-3">
          <img
            src={assets.Icons.privacy_edit}
            alt="terms&privacy"
            onClick={() => openBrowser('Privacy Policy', privacy?.file ? privacy?.file : privacy?.content)}
            className="terms-icon"
          />
          <div className="">
            <NormalButton
              label={privacy ? "EDIT" : "ADD"}
              mainBg
              onClick={() => navigate("/main/edit-privacy")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyTerms;
