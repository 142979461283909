import React, { useEffect } from "react";
import NormalButton from "../../components/NormalButton";
import NormalModal from "../../components/NormalModal";
import NormalTable from "../../components/NormalTable";
import NormalSelect from "../../components/NormalSelect";
import NormalInput from "../../components/inputField";
import assets from "../../assets";
import { useState } from "react";
import { useForm } from "react-hook-form";
import "./styles.scss";
import "../users/styles.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteBadgeById, getBadges } from "../../redux/reducers/badgesSlice";
import NoData from "../../components/noData";
import { getDropDown } from "../../redux/reducers/dropdownSlice";
import { CSV_FILE_NAME, DROP_DOWN_CATEGORY, fileDownload } from "../../utils";
import Exported from "../../services/endpoints";
import TooltipComponent from "../../components/tooltip";
import DeleteDropDown from "../../components/deleteModal";
import InputFieldNoValidation from "../../components/inputFieldNoValidation";

const Badges = () => {
  let navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const headerDetails = [
    { label: "Badge Id" },
    { label: "Category" },
    { label: "Header Message" },
    { label: "Image" },
    { label: "Achievement" },
    { label: "Body Message" },
    { label: "Action" },
  ];
  const options = [
    { label: "Tasks", value: 1 },
    { label: "Premium", value: 2 },
    { label: "Free", value: 3 },
  ];

  const [categoryOptions, setCategoryOptions] = useState([]);

  const { register } = useForm();

  const [page, setPage] = useState(1);
  const totalPage = useSelector((state) => state?.badges?.totalPages || 0);

  const [search, setSearch] = useState('');
  const [category, setCatefory] = useState('');

  const data = useSelector((state) => state?.badges?.data || []);

  const dispatch = useDispatch();

  useEffect(() => {
    getBagesData()
  }, [page, search, category]);

  useEffect(() => {
    if(data.length == 0 && page != 1){
      window.location.reload()
    }
  }, [data]);

  const getBagesData = () => {
    dispatch(getBadges(page, category, search));
  }

  useEffect(() => {
    getCategoryDropDown();
  }, []);

  const getCategoryDropDown = () => {
    getDropDown(DROP_DOWN_CATEGORY.BADGE_CATEGORY).then(res => {
      setCategoryOptions([...res]);
    })
  }

  const onClickExport = () => {
    fileDownload(`${Exported.Endpoints.GET_ALL_BADGES}?export=1`, CSV_FILE_NAME.BADGES_LIST);
  }

  const onClickEdit = (it) => {
    navigate("/main/add-badge", {
      state: {
        data: it
      }
    })
  }

  const onClickDelete = (it) => {
    setDeleteData(it);
    setModalOpen(true);
  }

  const onClickDeleteFromPopup = () => {
    setModalOpen(false)
    let {
      _id
    } = deleteData;

    deleteBadgeById(_id).then(_res => {
      getBagesData();
    }).catch(_err => { })
  }

  return (
    <div className="users-main-container">
      <div className="users-header-container">
        <p className="users-title">Badges</p>
        <NormalButton label="EXPORT CSV" mainBg onClick={onClickExport} />
      </div>
      <div className="users-input-container ">
        <div className="search-container">
          <label></label>
          <InputFieldNoValidation leftIcon searchIcon placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
        </div>
        <div className="subscription-container me-3">
          <label className="subscription-label">Category</label>
          <NormalSelect
            options={categoryOptions}
            name="subscription"
            register={register}
            borderGreen
            disableButtons={true}
            onChange={(e) => {
              setCatefory(e?.value)
            }}
          />
        </div>
        <div className="subscription-container pt-4">
          <NormalButton
            label="CREATE NEW BADGE"
            cancelBtn
            onClick={() => navigate("/main/add-badge")}
          />
        </div>
      </div>
      <div className="users-table-container">
        <NormalTable
          headerDetails={headerDetails}
          pagination
          totalPages={totalPage}
          onPageChange={(page) => setPage(page)}
          page={page}>
          {data.length > 0 ? (
            <>
              {data.map((list) => {
                let {
                  _id,
                  category,
                  headerMessage,
                  badgeImage,
                  achievement,
                  bodyMessage,
                } = list;
                return (
                  <tr key={_id}>
                    <td>{`${_id}`.slice(-5).toUpperCase()}</td>
                    <td>{category}</td>
                    <td>{headerMessage}</td>
                    <td>
                      <img src={badgeImage} alt="badges" className="badge-image" />
                    </td>
                    <td>{achievement}</td>
                    <td>{bodyMessage}</td>
                    <td align="center">
                      <TooltipComponent
                        onClickEdit={() => onClickEdit(list)}
                        onClickDelete={() => onClickDelete(list)} />
                    </td>
                  </tr>
                );
              })}
            </>
          ) : <tr>
            <td colSpan={7} className="text-center">
              <NoData />
            </td>
          </tr>}
        </NormalTable>
      </div>
      <DeleteDropDown
        show={modalOpen}
        screen={"Badges"}
        title={"Delete"}
        description={"Are you sure do you want to delete?"}
        onClickYes={onClickDeleteFromPopup}
        onHide={() => setModalOpen(false)}
      />
    </div>
  );
};

export default Badges;
