import { createSlice } from "@reduxjs/toolkit";
import request from "../../services";
import Exported from "../../services/endpoints";
import { successToast } from "../../utils";

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        usersData: {},

    },
    reducers: {
        saveUserCount: (state, action) => {
            state.usersData = action.payload;
        }
    }
});

export const getUsersDataCount = () => async (dispatch) => {

    request({
        url: `${Exported.Endpoints.GET_USERS_COUNT}`,
        method: Exported.APIMethods.GET,
        isLoader:false
    }).then(res => {
        dispatch(saveUserCount(res?.data?.data))
    }).catch(_err => {
    })
}


export const getRevenueData = (type) => {

    return new Promise((resolve, _reject) => {
        request({
            url: `${Exported.Endpoints.GET_REVENUE}${type}`,
            method: Exported.APIMethods.GET,
            isLoader:false
        }).then(res => {
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const getFeaturesData = (type) => {

    return new Promise((resolve, _reject) => {
        request({
            url: `${Exported.Endpoints.GET_FEATURES_COUNT}${type}`,
            method: Exported.APIMethods.GET,
            isLoader:false
        }).then(res => {
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const getUserDemographyData = (type) => {

    return new Promise((resolve, _reject) => {
        request({
            url: `${Exported.Endpoints.GET_USER_DEMOGRAPHY}${type}`,
            method: Exported.APIMethods.GET,
            isLoader:false
        }).then(res => {
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const getCustomerCareData = (type) => {

    return new Promise((resolve, _reject) => {
        request({
            url: `${Exported.Endpoints.GET_CUSTOMER_CARE_COUNTS}${type}`,
            method: Exported.APIMethods.GET,
            isLoader:false
        }).then(res => {
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const { saveUserCount } = dashboardSlice.actions;

export default dashboardSlice.reducer;
