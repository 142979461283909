import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react';
import axios from 'axios';
import './styles.scss';

const LottieAnimationFromURL = ({ animationURL, status = true }) => {
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const response = await axios.get(animationURL);
        setAnimationData(response.data);
      } catch (error) {
      }
    };

    fetchAnimationData();
  }, [animationURL]);

  return (
    <div className={status ? 'lottie-bg' : ''}>
      {animationData && <Lottie animationData={animationData} loop={true} className={status ? 'lottie-badge' : 'drop-down-lottie'} />}
    </div>
  );
};


export default LottieAnimationFromURL;