import { createSlice } from "@reduxjs/toolkit";
import request from "../../services";
import Exported from "../../services/endpoints";
import { successToast } from "../../utils";

export const selfCareSlice = createSlice({
    name: "selfCare",
    initialState: {
        data: [],
        totalPages: 0,
        selfCareId: ''
    },
    reducers: {
        saveData: (state, action) => {
            state.data = action.payload;
        },
        saveTotalPage: (state, action) => {
            state.totalPages = action.payload;
        },
        saveSelfCareId: (state, action) => {
            state.selfCareId = action.payload;
        },
    }
});

export const generateSelfCareId = () => async (dispatch) => {

    request({
        url: `${Exported.Endpoints.GENERATE_SELF_CARE_ID}`,
        method: Exported.APIMethods.GET
    }).then(res => {
        dispatch(saveTutorialId(res?.data?.data));
    }).catch(_err => {
    })
}



export const getSelfCare = (page, search, category) => async (dispatch) => {
    let searchQuery = (search) ? `&search=${search}` : ``;
    let categoryQuery = (category) ? `&category=${category}` : ``;

    request({
        url: `${Exported.Endpoints.GET_ALL_SELF_CARE}?page=${page}${searchQuery}${categoryQuery}`,
        method: Exported.APIMethods.GET,
        isLoader: search == ''
    }).then(res => {
        dispatch(saveData(res?.data?.data?.result));
        dispatch(saveTotalPage(res?.data?.data?.totalPages));
    }).catch(_err => {
    })
}

export const deleteSelfCareById = (_id) => {

    return new Promise((resolve, _reject)=>{
        request({
            url: `${Exported.Endpoints.DELETE_SELF_CARE_BY_ID}${_id}`,
            method: Exported.APIMethods.DELETE,
        }).then(res => {
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const createSelfCare = (data) => {

    return new Promise((resolve, _reject)=>{
        request({
            url: `${Exported.Endpoints.CREATE_SELF_CARE}`,
            method: Exported.APIMethods.POST,
            data
        }).then(res => {
            successToast(res?.data?.message)
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const updateSelfCare = (data) => {

    return new Promise((resolve, _reject)=>{
        request({
            url: `${Exported.Endpoints.UPDATE_SELF_CARE}`,
            method: Exported.APIMethods.PUT,
            data
        }).then(res => {
            successToast(res?.data?.message)
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const getSelfCareById = (_id) => {

    return new Promise((resolve, _reject)=>{
        request({
            url: `${Exported.Endpoints.GET_SELF_CARE_BY_ID}${_id}`,
            method: Exported.APIMethods.GET,
        }).then(res => {
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const getQRCodeById = (_id) => {

    return new Promise((resolve, _reject)=>{
        request({
            url: `${Exported.Endpoints.GENERATE_QR_SELF_CARE_ID}${_id}`,
            method: Exported.APIMethods.GET,
        }).then(res => {
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const getRelatedTracksByCategory = (category) => {

    return new Promise((resolve, _reject)=>{
        request({
            url: `${Exported.Endpoints.GET_ALL_RELATED_TRACKS_BY_CATEGORY}${category}`,
            method: Exported.APIMethods.GET,
        }).then(res => {
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const { saveData, saveTotalPage, saveTutorialId } = selfCareSlice.actions;

export default selfCareSlice.reducer;
