import React, { useEffect } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveTutorialId } from "../../redux/reducers/tutorialSlice";

const Content = () => {
  let navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(saveTutorialId(''))
  },[])

  return (
    <div className="content-main-container">
      <p className="content">Content</p>
      <div className="col-md-4 content-self-care-container">
        <p className="col-md-6 self-care">Self Care</p>
        <p
          className="col-md-3 manage"
          onClick={() => navigate("/main/self-care-content")}
        >
          Manage
        </p>
        <p
          className="col-md-3 manage"
          onClick={() => navigate("/main/add-self-care")}
        >
          Add
        </p>
      </div>
      <div className="col-md-4 content-self-care-container">
        <p className="col-md-6 self-care">Learn Tutorials</p>
        <p
          className="col-md-3 manage"
          onClick={() => navigate("/main/learn-tutorial")}
        >
          Manage
        </p>
        <p
          className="col-md-3 manage"
          onClick={() => navigate("/main/add-tutorial")}
        >
          Add
        </p>
      </div>
    </div>
  );
};

export default Content;
