import React, { useEffect } from "react";
import "./styles.scss";
import "../login/styles.scss";
import "../editUsers/styles.scss";
import assets from "../../assets";
import NormalInput from "../../components/inputField";
import NormalSelect from "../../components/NormalSelect";
import { useState } from "react";
import NormalButton from "../../components/NormalButton";
import { useLocation, useNavigate } from "react-router-dom";
import FileUpload from "../../components/FileUpload";
import TextAreaInput from "../../components/TextArea";
import { useForm } from "react-hook-form";
import AddDropDown from "../../components/addDropdown";
import Constants from "../../utils/constants";
import { DROP_DOWN_CATEGORY, S3_BUCKET_FOLDER, dropDownValidation, upload } from "../../utils";
import AddDropButton from "../../components/dropDownButton";
import DeleteDropDown from "../../components/deleteModal";
import { deleteDropDownById, getDropDown } from "../../redux/reducers/dropdownSlice";
import CustomController from "../../components/customController";
import LottieAnimationFromURL from "../../components/lottieAnimationFromURL";
import { createBadgeData, getBadgeById, updateBadgeData } from "../../redux/reducers/badgesSlice";

const CreateBadge = () => {
  let navigate = useNavigate();
  const [selectedBadgeColor, setSelectedBadgeColor] = useState("");
  const [selectedFontColor, setSelectedFontColor] = useState("");
  const [selectedGif, setSelectedGif] = useState("");
  const [selectedCategory, setSelectedCatgory] = useState("");

  let location = useLocation();

  const { state } = location;

  const [badgeData, setBadgeData] = useState(state?.data);

  const [badgeImage, setBadgeImage] = useState(null);

  const [badgeImageData, setBadgeImageData] = useState(null);


  const [categoryOptions, setCategoryOptions] = useState([
    {
      label: <AddDropButton
        label="Add New Category"
        onClick={() => onClickAddDropDown(DROP_DOWN_CATEGORY.BADGE_CATEGORY)}
      />,
      isDisabled: true
    }
  ]);

  const [colorOptions, setColorOptions] = useState([
    {
      label: <AddDropButton
        label="Add New Badge Color"
        onClick={() => onClickAddDropDown(DROP_DOWN_CATEGORY.BADGE_COLOR)}
      />,
      isDisabled: true
    }
  ]);

  const [fontOptions, setFontOptions] = useState([
    {
      label: <AddDropButton
        label="Add New Font Color"
        onClick={() => onClickAddDropDown(DROP_DOWN_CATEGORY.BADGE_FONTS)}
      />,
      isDisabled: true
    }
  ]);

  const [gifsOptions, setGifsOptions] = useState([]);

  const [dropdownCategory, setDropdownCategory] = useState('');
  const [show, setShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(null);


  useEffect(() => {
    getDropDownByCategory(DROP_DOWN_CATEGORY.BADGE_CATEGORY)
    getDropDownByCategory(DROP_DOWN_CATEGORY.BADGE_GIFS)
    getDropDownByCategory(DROP_DOWN_CATEGORY.BADGE_FONTS)
    getDropDownByCategory(DROP_DOWN_CATEGORY.BADGE_COLOR);

    if (badgeData) {

      let {
        _id
      } = badgeData;

      getBadgeById(_id).then(res => {

        let {
          category,
          headerMessage,
          achievement,
          bodyMessage,
          badgeColor,
          badgeFontColor,
          badgeGif,
          badgeImage
        } = res?.data;

        setBadgeData(res?.data);
        setValue('category', category);
        setValue('headerMessage', headerMessage);
        setValue('achievement', achievement);
        setValue('bodyMessage', bodyMessage);
        setValue('badgeColor', badgeColor)
        setValue('badgeFontColor', badgeFontColor)
        setValue('badgeGif', badgeGif)
        setValue('badgeImage', badgeImage);
        setValue('headerMessage', headerMessage);
        setBadgeImage(badgeImage);
      }).catch(_err => { })

    }
  }, [])

  const onClickAddDropDown = (category) => {
    setDropdownCategory(category);
    setEditData(null)
    setShow(true);
  }

  const onAddedData = (category) => {
    setShow(false);
    if (category) {
      getDropDownByCategory(category)
    }
  }

  const onClickDeleteFromPopup = () => {
    setShowDelete(false)
    let {
      _id,
      dropDownType
    } = deleteData;

    deleteById(_id, dropDownType);
  }
  const onClickDelete = (data) => {
    setDeleteData(data);
    setShowDelete(true);
  }

  const onClickEdit = (data) => {
    setDropdownCategory(data.dropDownType);
    setEditData({
      label: data.label,
      value: data.value,
      id: data._id
    });
    setShow(true);
  }

  const deleteById = (id, type) => {
    deleteDropDownById(id).then(_res => {
      getDropDownByCategory(type)
    })
  }

  const getDropDownByCategory = (category) => {
    getDropDown(category).then(res => {
      switch (category) {
        case DROP_DOWN_CATEGORY.BADGE_CATEGORY: {
          let data = [{
            ...categoryOptions[0]
          }];
          data = [...data, ...res];
          setCategoryOptions(data);
          if (badgeData) {
            let item = data.filter(it => it.value == badgeData.category)[0];
            setSelectedCatgory(item)
          } else {
            setSelectedCatgory(null);
          }
          break;
        }
        case DROP_DOWN_CATEGORY.BADGE_COLOR: {
          let data = [{
            ...colorOptions[0]
          }];
          data = [...data, ...res];
          setColorOptions(data);
          if (badgeData) {
            let item = data.filter(it => it.value == badgeData.badgeColor)[0];
            item.label = <div className="label-value-container">
              <span>{item.label}</span>
              {
                <div className="color-icon" style={{
                  backgroundColor: item?.value
                }} />
              }
            </div>
            setSelectedBadgeColor(item)
          } else {
            setSelectedBadgeColor(null);
          }
          break;
        }
        case DROP_DOWN_CATEGORY.BADGE_FONTS: {
          let data = [{
            ...fontOptions[0]
          }];
          data = [...data, ...res];
          setFontOptions(data);
          if (badgeData) {
            let item = data.filter(it => it.value == badgeData.badgeFontColor)[0];
            item.label = <div className="label-value-container">
              <span>{item.label}</span>
              {
                <div className="color-icon" style={{
                  backgroundColor: item?.value
                }} />
              }
            </div>
            setSelectedFontColor(item)
          } else {
            setSelectedFontColor(null);
          }
          break;
        }
        case DROP_DOWN_CATEGORY.BADGE_GIFS: {
          let data = [...res];
          setGifsOptions(data);
          if (badgeData) {
            let item = data.filter(it => it.value == badgeData.badgeGif)[0];
            setSelectedGif(item)
          } else {
            setSelectedGif(null);
          }
          break;
        }
      }
    })
  }


  const onSubmit = (data) => {
    if (badgeImageData && typeof badgeImageData != 'string' && badgeImageData?.size) {
      upload(S3_BUCKET_FOLDER.BADGES_ICONS, badgeImageData).then(res => {
        data["badgeImage"] = res["fileName"];
        if (badgeData) {
          data["_id"] = badgeData?._id;
          updateBadge(data);
        } else {
          createBadge(data);
        }
      })
    } else {
      if (badgeData) {
        data["_id"] = badgeData?._id;
        if (badgeImage && badgeImage?.startsWith('https://')) {
          let extractedSubstring = badgeImage.match(/\.com\/(.*?)\?/);
          if (extractedSubstring && extractedSubstring.length > 1) {
            data["badgeImage"] = extractedSubstring[1];
          }
        }
        updateBadge(data);
      } else {
        createBadge(data);
      }
    }

  };

  const createBadge = (data) => {
    createBadgeData(data).then(_res => {
      navigate(-1)
    }).catch(_err => { })
  }

  const updateBadge = (data) => {
    updateBadgeData(data).then(_res => {
      navigate(-1)
    }).catch(_err => { });
  }

  const onSetBadgeImage = (e) => {
    setBadgeImageData(e)
    const reader = new FileReader();
    reader.onload = () => {
      setBadgeImage(reader.result);
    };
    reader.readAsDataURL(e);
    
  }


  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch
  } = useForm({ mode: "onBlur" });

  const bodyMessage = watch("bodyMessage");
  const headerMessage = watch("headerMessage");
  const achievement = watch("achievement");
  const category = watch("category");

  return (
    <>
      <div className="edit-user-container">
        <div className="back-to-user-container">
          <img
            src={assets.Icons.left_icon}
            alt="left-icon"
            className="left-icon"
          />
          <span
            className="back-to-user"
            onClick={() => navigate("/main/badges")}
          >
            Back to Badges
          </span>
        </div>
        <div className="edit-user ">
          <p className="edit-user-details">{badgeData ? `Edit Badge Details` : `Create a new Badge`}</p>
          <div className="d-flex">
            <form className="col-md-3 d-flex" onSubmit={handleSubmit(onSubmit)}>
              <div className=" col-md-12 ">
                <div className="">
                  <label className="login-input-label">Category</label>
                  <CustomController
                    name="category"
                    control={control}
                    data={selectedCategory}
                    error={errors.category}
                    register={register("category", dropDownValidation('Category'))}
                    render={({ field: { onChange } }) => {
                      return (
                        <NormalSelect
                          options={categoryOptions}
                          borderGreen
                          error={errors.category}
                          onClickEdit={onClickEdit}
                          value={selectedCategory}
                          onClickDelete={onClickDelete}
                          onChange={(e) => {
                            setSelectedCatgory(e);
                            onChange(e?.value);
                          }}
                          placeholder="Select a Category or create a new one"
                        />
                      );
                    }}
                  />
                </div>
                <div className="">
                  <label className="login-input-label">Header Message</label>
                  <NormalInput
                    type={"text"}
                    placeholder="Eg: Awesome"
                    register={register("headerMessage", {
                      required: "Header Message is required",
                      maxLength: {
                        value: 50,
                        message: "Header Message cannot exceed 50 characters"
                      }
                    })}
                    error={errors.headerMessage}
                    messages={errors}
                  />
                </div>{" "}
                <div className=" ">
                  <label className="login-input-label">Achievement</label>
                  <NormalInput
                    type="text"
                    placeholder="Please enter a number"
                    register={register("achievement", {
                      required: "Achievement is required",
                    })}
                    error={errors.achievement}
                    messages={errors}
                  />
                </div>
                <div className="">
                  <label className="login-input-label">Body Message</label>
                  <TextAreaInput
                    name='bodyMessage'
                    placeholder="Please enter a message in 120 characters or less"
                    register={register("bodyMessage", {
                      required: "Body Message is required",
                    })}
                    error={errors.bodyMessage}
                    messages={errors}
                  />
                </div>
                <div className="">
                  <label className="login-input-label">Badge Image</label>
                  <CustomController
                    name="badgeImage"
                    control={control}
                    error={errors.badgeImage}
                    register={register("badgeImage", dropDownValidation('Badge Image'))}
                    render={({ field: { onChange } }) => {
                      return (
                        <FileUpload
                          placeholder="Select an image"
                          file={badgeImage}
                          onChange={(e) => {
                            if (e == null) {
                              onChange('');
                              setBadgeImage(null);
                              setBadgeImageData(null);
                            } else {
                              onSetBadgeImage(e);
                              onChange("File uploaded")
                            }
                          }}
                          error={errors.badgeImage}
                        />
                      );
                    }}
                  />

                </div>{" "}
                <div className=" d-flex mt-3">
                  <div className="edit-cancel-btn">
                    <NormalButton
                      label=" CANCEL"
                      cancelBtn
                      onClick={() => navigate("/main/badges")}
                    />
                  </div>
                  <div className="edit-update-btn">
                    <NormalButton label={badgeData ? ' UPDATE' : " CREATE"} mainBg />
                  </div>
                </div>
              </div>
            </form>
            <div className="col-md-6 ms-4">
              <span className="select-badge">Select Badge Template</span>
              <div className="d-flex">
                <div className="col-md-4 me-3">
                  <label className="login-input-label">Badge</label>
                  <CustomController
                    name="badgeColor"
                    control={control}
                    data={selectedBadgeColor}
                    error={errors.badgeColor}
                    register={register("badgeColor", dropDownValidation('Badge Color'))}
                    render={({ field: { onChange } }) => {
                      return (
                        <NormalSelect
                          options={colorOptions}
                          borderGreen
                          error={errors.badge}
                          handleColorChange
                          onClickEdit={onClickEdit}
                          value={selectedBadgeColor}
                          onClickDelete={onClickDelete}
                          onChange={(e) => {
                            setSelectedBadgeColor(e);
                            onChange(e?.value);
                          }}
                          placeholder="Select Badge color"
                        />
                      );
                    }}
                  />
                </div>
                <div className="col-md-4 me-3">
                  <label className="login-input-label">Font</label>
                  <CustomController
                    name="badgeFontColor"
                    control={control}
                    data={selectedFontColor}
                    error={errors.badgeFontColor}
                    register={register("badgeFontColor", dropDownValidation('Font Color'))}
                    render={({ field: { onChange } }) => {
                      return (
                        <NormalSelect
                          options={fontOptions}
                          borderGreen
                          error={errors.badgeFontColor}
                          handleColorChange
                          onClickEdit={onClickEdit}
                          value={selectedFontColor}
                          onClickDelete={onClickDelete}
                          onChange={(e) => {
                            setSelectedFontColor(e);
                            onChange(e?.value);
                          }}
                          placeholder="Select Font color"
                        />
                      );
                    }}
                  />
                </div>
                <div className="col-md-6 me-3">
                  <label className="login-input-label">GIF</label>
                  <CustomController
                    name="badgeGif"
                    control={control}
                    data={selectedGif}
                    error={errors.badgeGif}
                    register={register("badgeGif", dropDownValidation('Gif'))}
                    render={({ field: { onChange } }) => {
                      return (
                        <NormalSelect
                          options={gifsOptions}
                          borderGreen
                          error={errors.badgeGif}
                          value={selectedGif}
                          onClickEdit={onClickEdit}
                          disableButtons
                          onClickDelete={onClickDelete}
                          onChange={(e) => {
                            setSelectedGif(e);
                            onChange(e?.value);
                          }}
                          placeholder="Select Gif file"
                        />
                      );
                    }}
                  />
                </div>
              </div>
              {selectedBadgeColor && (
                <div className="badge-container">
                  <span className="badge-text" style={{
                    color: selectedFontColor?.value
                  }}> {headerMessage.length > 50 ? `${`${headerMessage}`.slice(0, 50)}` : `${headerMessage}`} </span>

                  <div
                    className="create-badge-container"
                    style={{
                      backgroundColor: selectedBadgeColor?.value,
                      color: selectedFontColor?.value,
                    }}
                  >
                    {
                      badgeImage && <img
                        src={badgeImage}
                        alt={"badge-upload"}
                        onError={(e) => {
                        }}
                        className="badge-image-container" />
                    }


                    {
                      selectedGif && <LottieAnimationFromURL animationURL={selectedGif?.image} />
                    }
                    {" "}

                  </div>

                  <div className="badge-body-container">
                    <p className="badge-body-text-description" style={{
                      color: selectedFontColor?.value,
                    }}> {bodyMessage} </p>
                  </div>
                </div>

              )}
            </div>
          </div>
        </div>
      </div>

      <AddDropDown
        show={show}
        category={dropdownCategory}
        onAddedData={onAddedData}
        editData={editData} />

      <DeleteDropDown
        show={showDelete}
        title={"Delete"}
        description={"Are you sure do you want to delete?"}
        onClickYes={onClickDeleteFromPopup}
        onHide={() => setShowDelete(false)}
      />
    </>
  );
};

export default CreateBadge;