import React from "react";
import assets from "../../assets";
import "./styles.scss";
import NormalButton from "../../components/NormalButton";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import NormalSelect from "../../components/NormalSelect";
import NormalInput from "../../components/inputField";
import { useForm } from "react-hook-form";

const EditPremiumFeature = () => {
  let navigate = useNavigate();
  const [options] = useState([
    { label: "IND", value: 1 },
    { label: "USA", value: 2 },
    { label: "UK", value: 3 },
  ]);
  const [freeFeatures] = useState([
    {
      id: 1,
      title: "Task list",
      icon: assets.Icons.feature_success,
      downIcon: true,
    },
    {
      id: 2,
      title: "Number of Tasks",
      inputLabel: "Unlimited",
    },
    {
      id: 1,
      title: "Task Reminder",
      icon: assets.Icons.feature_success,
      downIcon: true,
    },
    {
      id: 1,
      title: "Edit Tasks",
      icon: assets.Icons.feature_success,
      downIcon: true,
    },
    {
      id: 1,
      title: "Calendar",
      icon: assets.Icons.feature_success,
      downIcon: true,
    },
    {
      id: 1,
      title: "Calendar Integration",
      icon: assets.Icons.feature_success,
      downIcon: true,
    },
    {
      id: 1,
      title: "Focus Time",
      icon: assets.Icons.feature_success,
      downIcon: true,
    },
    {
      id: 1,
      title: "Priority Wizard",
      icon: assets.Icons.feature_success,
      downIcon: true,
    },
    {
      id: 2,
      title: "Self-Care Tasks",
      inputLabel: "All",
    },
  ]);
  const onSubmit = (data) => {
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  return (
    <form
      className="free-feature-user-container"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="back-to-user-container">
        <img
          src={assets.Icons.left_icon}
          alt="left-icon"
          className="left-icon"
        />
        <span
          className="back-to-user"
          onClick={() => navigate("/main/payment")}
        >
          Back to Payment
        </span>
      </div>
      <div className="edit-user col-md-6 ">
        <p className="edit-user-details">Edit Premium Features & Pricing</p>
        <div className="col-md-12 d-flex">
          <div className="premium-feature-left-container col-md-6">
            <div className="premium-title-container">
              <span className="premium-feature-main-title">
                Premium Features
              </span>
            </div>
            {freeFeatures.map((list) => {
              return (
                <div className="premium-feature col-md-12">
                  <div className="col-md-6">
                    <span className="free-feature-title ">{list.title}</span>
                  </div>

                  {list.icon && (
                    <div className="col-md-3 text-center">
                      <img
                        src={list.icon}
                        alt="icon"
                        className="free-feature-icon"
                      />
                    </div>
                  )}
                  {list.inputLabel && (
                    <div className="col-md-6 ps-4 text-start">
                      <label className="free-feature-label">
                        {list.inputLabel}
                      </label>
                    </div>
                  )}
                  {list.downIcon && (
                    <div className="col-md-3 text-center">
                      <img
                        src={assets.Icons.down}
                        alt="downIcon"
                        className="free-feature-down-icon"
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="premium-feature-right-container col-md-6 ms-3">
            <div className="premium-title-container">
              <span className="premium-feature-main-title">
                Premium Pricing
              </span>
            </div>
            <div className="premium-input-container">
              <div className="">
                <label className="login-input-label">Country</label>
                <NormalSelect
                  options={options}
                  name="country"
                  register={register("country", {
                    required: "Country is required",
                  })}
                  error={errors.country}
                  messages={errors}
                  borderGreen
                  leftIcon
                  countryIcon
                />
              </div>
              <div className="">
                <label className="login-input-label">
                  Premium Price (Inclusive of GST)
                </label>
                <NormalInput
                  type={"text"}
                  placeholder="Enter Premium Price"
                  register={register("premiumPrice", {
                    required: "Premium Price is required",
                  })}
                  error={errors.premiumPrice}
                  messages={errors}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" d-flex mt-3">
        <div className="edit-cancel-btn">
          <NormalButton
            label=" CANCEL"
            cancelBtn
            onClick={() => navigate("/main/payment")}
          />
        </div>
        <div className="edit-update-btn">
          <NormalButton label=" UPDATE" mainBg />
        </div>
      </div>
    </form>
  );
};

export default EditPremiumFeature;
