import React, { useState } from "react";
import "./styles.scss";
import NormalInput from "../../components/inputField";
import NormalButton from "../../components/NormalButton";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../redux/reducers/authSlice";
import { Toast } from "../../components/toast";
import { REGEX } from "../../utils";
import Constants from "../../utils/constants";

const ResetPassword = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const { state } = location;
  const [emailId, _setEmailId] = useState(state?.emailId);
  const [isForgotPassword, _setIsForgotPassword] = useState(state?.forgotPassword);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues
  } = useForm({ mode: "onBlur" });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const passwordLengthIsValid = (password) => password?.length >= 8;


  const validatePasswordMatch = () => {
    return newPassword === confirmPassword || 'Passwords do not match';
  };

  const onSubmit = (data) => {

    let request = {
      "emailId": emailId,
      "userType": "staff",
      "password": data?.newPassword
    }

    resetPassword(request).then(res=>{
      Toast({type:"success", message: res.data.message});
      if(!isForgotPassword){
        navigate('/main/dashboard')
      }else{
        navigate('/auth/login')
      }
    }).catch(err=>{

    })
  };
  return (
    <>
      <form
        className="col-md-6 login-container"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="login-content">
          <p className="content-title">
            {!isForgotPassword ? "Change Password" : "Reset Password"}
          </p>
          <p className="login-text">
            Your new password must be different <br></br>
            from previously used passwords.
          </p>
          <div className="login-input-container">
            <label className={`login-input-label`}>New Password</label>
            <NormalInput
              type={"password"}
              name={"newPassword"}
              placeholder={"Please enter your new password "}
              value={newPassword}
              register={register("newPassword", {
                required: "Password is required",
                pattern: {
                  value: REGEX.PASSWORD,
                  message: Constants.PASSWORD_ERROR,
                },
              })}
              error={errors.newPassword}
              messages={errors}
              leftIcon
              passwordIcon
              rightIcon
              eyeIcon
              successIcon={passwordLengthIsValid(newPassword)}
            />
            <label className="login-input-label">Confirm Password</label>
            <NormalInput
              type={"password"}
              name={"confirmPassword"}
              placeholder={"Please re-enter your new password"}
              onChange={handleChange}
              value={confirmPassword}
              register={register("confirmPassword", {
                required: "New Password is required",
                pattern: {
                  value: REGEX.PASSWORD,
                  message: Constants.PASSWORD_ERROR,
                },
                validate: (value) => {
                  let values = getValues();
                  return value == values["newPassword"] ? true : 'New password and Confirm Password are not same.'
                }
              })}
              error={errors.confirmPassword}
              messages={errors}
              leftIcon
              passwordIcon
              rightIcon
              eyeIcon
              successIcon={passwordLengthIsValid(confirmPassword)}
            />
          </div>
          <div className="mt-2">
            <NormalButton
              label={"LOG IN"}
              loginBtn
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default ResetPassword;
