import { createSlice } from "@reduxjs/toolkit";
import request from "../../services";
import Exported from "../../services/endpoints";
import { successToast } from "../../utils";

export const plansSlice = createSlice({
    name: "plans",
    initialState: {
        data: [],
    },
    reducers: {
        saveData: (state, action) => {
            state.data = action.payload;
        },
    }
});


export const getPlansDropDown = () => {
    return new Promise((resolve, reject) => {
        request({
            url: Exported.Endpoints.GET_PLANS_DROP_DOWN,
            method: Exported.APIMethods.GET
        }).then(res => {
            resolve(res?.data?.data)
        }).catch(_err => {
        })
    })
}

export const getAllPlans = () => async (dispatch) => {

    request({
        url: Exported.Endpoints.GET_ALL_PLANS,
        method: Exported.APIMethods.GET,
        isLoader: false
    }).then(res => {
        dispatch(saveData(res?.data?.data));
    }).catch(_err => {
    })

}

export const getAllPlansFeature = () => {
    return new Promise((resolve, reject) => {
        request({
            url: Exported.Endpoints.GET_ALL_PLANS_FEATURE,
            method: Exported.APIMethods.GET
        }).then(res => {
            resolve(res?.data?.data);
        }).catch(_err => {
        })
    })
}

export const createPlan = (data) => {
    return new Promise((resolve, reject) => {
        request({
            url: Exported.Endpoints.CREATE_PLAN,
            method: Exported.APIMethods.POST,
            data: data
        }).then(res => {
            resolve(res.data.message)
        }).catch(_err => {
            reject(_err)
        })
    })
}

export const updatePlan = (data) => {
    return new Promise((resolve, reject) => {
        request({
            url: Exported.Endpoints.UPDATE_PLAN,
            method: Exported.APIMethods.PUT,
            data: data
        }).then(res => {
            resolve(res.data.message)
        }).catch(_err => {
            reject(_err)
        })
    })
}


export const deletePlan = (id) => {
    return new Promise((resolve, reject) => {
        request({
            url: Exported.Endpoints.DELETE_PLAN + id,
            method: Exported.APIMethods.DELETE
        }).then(res => {
            successToast(res?.data?.message);
            resolve(res.data)
        }).catch(_err => {
            reject(_err)
        })
    })
}


export const getPlanById = (id) => {
    return new Promise((resolve, reject) => {
        request({
            url: Exported.Endpoints.GET_PLAN_BY_ID + id,
            method: Exported.APIMethods.GET
        }).then(res => {
            resolve(res.data)
        }).catch(_err => {
            reject(_err)
        })
    })
}



export const { saveData } = plansSlice.actions;

export default plansSlice.reducer;
