import React, { useEffect } from "react";
import "./styles.scss";
import "../login/styles.scss";
import assets from "../../assets";
import NormalButton from "../../components/NormalButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import FileUpload from "../../components/FileUpload";
import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
  BtnUnderline,
  createButton,
  RichUtils,
} from 'react-simple-wysiwyg';
import { S3_BUCKET_FOLDER, upload } from '../../utils'
import { useSelector } from "react-redux";
import { createPrivacyTerms, updatePrivacyTerms } from "../../redux/reducers/privacyTermsSlice";

const EditTerms = () => {

  let navigate = useNavigate();

  const location = useLocation();

  const [html, setHtml] = useState('');

  const [fileName, setFileName] = useState('');

  const screeenStatus = location.pathname.includes("terms") ? 'terms' : 'privacy';

  const terms = useSelector((state) => state.privacyTerms?.terms);

  const privacy = useSelector((state) => state.privacyTerms?.privacy);

  useEffect(() => {
    getData()
  }, []);

  const getData = () =>{
    if (screeenStatus == 'terms') {
      if (terms?.contentType == 'pdf') {
        setFileName(terms?.fileName);
      } else {
        setHtml(terms?.content)
      }
    } else {
      if (privacy?.contentType == 'pdf') {
        setFileName(privacy?.fileName);
      } else {
        setHtml(privacy?.content)
      }
    }
  }

  const [pdfFile, setPdfFile] = useState(null);

  const toggleTextCase = () => {
    const selection = window.getSelection();
    if (selection && selection.toString().trim() !== '') {
      const currentText = selection.toString();
      let newText = '';

      for (let i = 0; i < currentText.length; i++) {
        const char = currentText[i];
        if (char === char.toUpperCase()) {
          newText += char.toLowerCase();
        } else {
          newText += char.toUpperCase();
        }
      }

      const range = selection.getRangeAt(0);
      range.deleteContents();
      range.insertNode(document.createTextNode(newText));
    }
  };

  const BtnToggleCase = createButton('Toggle Case', 'Aa', toggleTextCase);

  const applyTextAlignment = (alignment = 'justify') => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const container = range.commonAncestorContainer;

    const blockElement = container.nodeType === 3 ? container.parentNode : container;

    if (blockElement && blockElement.tagName.toLowerCase() === 'div') {
      blockElement.style.textAlign = alignment;
    }
  };


  const BtnAlignRight = createButton('Align center', '←', 'justifyLeft');
  const BtnAlignLeft = createButton('Align center', '→', 'justifyRight');
  const BtnAlignCenter = createButton('Align sdfsdfsfd', '↑', 'justifyCenter');

  const handleTextJustify = () => {
    document.execCommand('justifyFull', false, null);
  };

  const BtnAlignJustify = createButton('Align Justify', '≡', handleTextJustify);


  const onChange = (e) => {
    setHtml(e.target.value);
    setPdfFile(null)
    setFileName('')

  }

  const onClickSave = () => {
    let request = {}
    if (screeenStatus == 'terms') {
      request = {
        content: fileName ? fileName : html,
        type: 'terms'
      }
      if (terms) {
        delete request['type'];
        request['_id'] = terms?._id
      }
    } else {
      request = {
        content: fileName ? fileName : html,
        type: 'privacy'
      }
      if (privacy) {
        delete request['type'];
        request['_id'] = privacy?._id
      }
    }


    if (pdfFile) {
      upload(S3_BUCKET_FOLDER.APP_TERMS_POLICY, pdfFile).then(res => {
        request["content"] = res.fileName;
        if (request?._id) {
          updateData(request)
        } else {
          createData(request);
        }

      }).catch(_err => { })
    } else {
      if (request?._id) {
        updateData(request)
      } else {
        createData(request);
      }
    }
  }

  const createData = (data) => {
    createPrivacyTerms(data).then(res => {
      navigate(-1);
    }).catch(_err => { })
  }

  const updateData = (data) => {
    updatePrivacyTerms(data).then(_res => {
      navigate(-1);
    }).catch(_err => { })
  }



  return (
    <div className="edit-user-container">
      <div className="back-to-user-container">
        <img
          src={assets.Icons.left_icon}
          alt="left-icon"
          className="left-icon"
        />
        <span
          className="back-to-user"
          onClick={() => navigate("/main/terms&privacy")}
        >
          Back to Terms & Privacy
        </span>
      </div>
      <div className="edit-user col-md-12 ">
        <p className="edit-user-details">
          {location.pathname.includes("terms")
            ? "Edit Terms of Use"
            : "Edit Privacy Policy"}
        </p>
        <div className="">
          <EditorProvider>
            <Editor
              value={html}
              onChange={onChange}
              containerProps={{
                style:
                {
                  color: '#0a6a78',
                  height: 350,
                  fontFamily: 'sans-serif',
                  resize: 'vertical',
                  overflow: 'auto'
                }
              }} >
              <Toolbar style={{
                backgroundColor: "transparent",
              }}>
                <BtnBold style={{
                  color: '#0a6a78',
                  fontWeight: 700
                }} />
                <BtnItalic style={{
                  color: '#0a6a78',
                  fontWeight: 700
                }} />
                <BtnUnderline style={{
                  color: '#0a6a78',
                  fontWeight: 700
                }} />
                <BtnToggleCase style={{
                  color: '#0a6a78',
                  fontWeight: 700
                }} />
                <BtnAlignRight style={{
                  color: '#0a6a78',
                  fontWeight: 700
                }} />
                <BtnAlignLeft style={{
                  color: '#0a6a78',
                  fontWeight: 700
                }} />
                <BtnAlignCenter style={{
                  color: '#0a6a78',
                  fontWeight: 700
                }} />
                <BtnAlignJustify style={{
                  color: '#0a6a78',
                  fontWeight: 700,
                  fontSize:20,
                }} />
              </Toolbar>
            </Editor>
          </EditorProvider>
        </div>
        <div className="terms-line-container">
          <hr className="terms-left-line " />
          <span className="or-text-terms">OR</span>
          <hr className="terms-right-line " />
        </div>
        <div className="col-md-3">
          <label className="login-input-label">Upload PDF</label>
          <FileUpload
            accept="application/pdf"
            placeholder="Select a file in PDF format"
            fileNameData={fileName}
            onChange={(e) => {
              setPdfFile(e);
              setFileName('');
              if (e != null) {
                setHtml('');
              }

              if(e == null && fileName != ''){
                if(screeenStatus == "terms"){
                  setHtml(terms?.content)
                }else{
                  setHtml(privacy?.content)
                }
              }

            }}
          />
        </div>{" "}
        <div className=" d-flex mt-3">
          <div className="edit-cancel-btn">
            <NormalButton
              label=" CANCEL"
              cancelBtn
              onClick={() => navigate("/main/terms&privacy")}
            />
          </div>
          <div className="edit-update-btn">
            <NormalButton label={screeenStatus == 'terms' ? (terms ? " UPDATE" : " ADD") : (privacy ? " UPDATE" : " ADD")} mainBg onClick={onClickSave} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTerms;
