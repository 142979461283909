import React, { useEffect } from "react";
import "../login/styles.scss";
import assets from "../../assets";
import NormalInput from "../../components/inputField";
import NormalSelect from "../../components/NormalSelect";
import { useState } from "react";
import NormalButton from "../../components/NormalButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FileUpload from "../../components/FileUpload";
import { useForm } from "react-hook-form";
import AddDropButton from "../../components/dropDownButton";
import { DROP_DOWN_CATEGORY, S3_BUCKET_FOLDER, dropDownValidation, errorToast, extractImagePathFromUrl, showLoader, upload } from "../../utils";
import AddDropDown from "../../components/addDropdown";
import DeleteDropDown from "../../components/deleteModal";
import { deleteDropDownById, getDropDown } from "../../redux/reducers/dropdownSlice";
import CustomController from "../../components/customController";
import { getPlansDropDown } from "../../redux/reducers/plansSlice";
import './styles.scss';
import { createSelfCare, getRelatedTracksByCategory, getSelfCareById, updateSelfCare } from "../../redux/reducers/selfCareSlice";
import Constants from "../../utils/constants";
import MultiSelect from "../../components/multiSelect";

const EditSelfCare = () => {

  let navigate = useNavigate();

  let location = useLocation();

  const { state } = location;

  const [selfCareData, setSelfCareData] = useState(state?.data);

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
    setError
  } = useForm({ mode: "onBlur" });


  const [dropdownCategory, setDropdownCategory] = useState('');
  const [show, setShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [selectedCategory, setSelectedCatgory] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [duration, setDuration] = useState('');
  const [mediaFileName, setMediaFileName] = useState(null);

  const [artWorkImage, setArtWorkImage] = useState(null);
  const [iconImage, setIconImage] = useState(null);
  const [headerImage, setHeaderMessage] = useState(null);
  const [media, setMedia] = useState(null);

  const [plansOptions, setPlanOptions] = useState([]);
  const [relatedTracks, setRelatedTracks] = useState([]);
  const [selectedRelatedTracks, setSelectedRelatedTracks] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([
    {
      label: <AddDropButton
        label="Add New Category"
        onClick={() => onClickAddDropDown(DROP_DOWN_CATEGORY.SELF_CARE_CATEGORY)}
      />,
      isDisabled: true
    }
  ]);

  useEffect(() => {
    getDropDownByCategory(DROP_DOWN_CATEGORY.SELF_CARE_CATEGORY)
    getSubcscriptionDropDown();


    if (selfCareData) {

      let {
        _id
      } = selfCareData;

      getSelfCareById(_id).then(res => {

        let {
          category,
          title,
          artWork,
          duration,
          tags,
          mediaFile,
          icon,
          planId,
          header,
          relatedTracks
        } = res?.data;

        setSelfCareData(res?.data);
        setValue('category', category);
        getAllCurrentTracksByCategory(category, relatedTracks);
        setValue('title', title);
        setValue('tags', tags);
        setValue('duration', duration);

        setValue('icon', icon);
        setValue('mediaFile', mediaFile);
        setValue('planId', planId);
        setValue('artWork', artWork);
        setValue('header', header);

        setIconImage(icon);
        setArtWorkImage(artWork);
        setHeaderMessage(header);
        setMedia(mediaFile);
        setMediaFileName(mediaFile);

        if (mediaFile && mediaFile?.startsWith('https://')) {
          let extractedSubstring = mediaFile.match(/\.com\/(.*?)\?/);
          if (extractedSubstring && extractedSubstring.length > 1) {
            setMediaFileName(extractedSubstring[1])
          }
        }

        setDuration(duration)
      }).catch(_err => { })

    }else{
      getAllCurrentTracksByCategory([]);
    }
  }, []);

  const getSubcscriptionDropDown = () => {
    getPlansDropDown().then(res => {
      setPlanOptions([...res])

      let data = [...res];

      setPlanOptions(data);
      if (selfCareData) {
        let item = data.filter(it => it.value == selfCareData.planId)[0];
        setSelectedPlan(item)
      } else {
        setSelectedPlan(null);
      }
    }).catch(_err => { })
  }



  const getDropDownByCategory = (category) => {
    getDropDown(category).then(res => {
      switch (category) {
        case DROP_DOWN_CATEGORY.SELF_CARE_CATEGORY: {
          let data = [{
            ...categoryOptions[0]
          }];
          data = [...data, ...res];
          setCategoryOptions(data);
          if (selfCareData) {
            let item = data.filter(it => it.value == selfCareData.category)[0];
            setSelectedCatgory(item)
          } else {
            setSelectedCatgory(null);
          }
          break;
        }
      }
    })
  }

  const onClickAddDropDown = (category) => {
    setDropdownCategory(category);
    setEditData(null)
    setShow(true);
  }


  const onAddedData = (category) => {
    setShow(false);
    if (category) {
      window.location.reload();
    }
  }

  const onClickDeleteFromPopup = () => {
    setShowDelete(false)
    let {
      _id
    } = deleteData;

    deleteById(_id);
  }
  const onClickDelete = (data) => {
    setDeleteData(data);
    setShowDelete(true);
  }

  const onClickEdit = (data) => {
    setDropdownCategory(data.dropDownType);
    setEditData({
      label: data.label,
      value: data.value,
      id: data._id
    });
    setShow(true);
  }

  const deleteById = (id) => {
    deleteDropDownById(id).then(_res => {
      window.location.reload();
    })
  }

  const onSubmit = (data) => {

    let items = selectedRelatedTracks.map(it => it.value);

    data["relatedTracks"] = items;

    let categoryList = {
      ...Constants.SELF_CARE_CONTENT_CATEGORY
    }

    if (!categoryList.hasOwnProperty(selectedCategory?.value?.toUpperCase()) && (headerImage == null || headerImage == '')) {
      errorToast("Header is required");
      return
    }

    if (selfCareData) {

      let requestMedia = [];
      if (iconImage && typeof iconImage != 'string' && iconImage?.size) {
        requestMedia.push(upload(S3_BUCKET_FOLDER.SELF_CARE_ICON, iconImage, false, 'icon'));
      }

      if (media && typeof media != 'string' && media?.size) {
        requestMedia.push(upload(S3_BUCKET_FOLDER.SELF_CARE_MEDIA, media, false, 'mediaFile'));
      }

      if (headerImage && typeof headerImage != 'string' && headerImage?.size) {
        requestMedia.push(upload(S3_BUCKET_FOLDER.SELF_CARE_HEADER, headerImage, false, 'header'));
      }

      if (artWorkImage && typeof artWorkImage != 'string' && artWorkImage?.size) {
        requestMedia.push(upload(S3_BUCKET_FOLDER.SELF_CARE_ART_WORK, artWorkImage, false, 'artWork'));
      }

      data["_id"] = selfCareData?._id;
      delete data['contentId']

      if (requestMedia.length > 0) {
        showLoader(true);
        Promise.all([...requestMedia]).then(results => {
          showLoader(false)
          results.forEach((result, _index) => {
            data[result?.key] = result["fileName"]
          });
          updateFields(data);
        })
      } else {
        updateFields(data)
      }


    } else {
      showLoader(true);

      let requestIcon = upload(S3_BUCKET_FOLDER.SELF_CARE_ICON, iconImage, false, 'icon');
      let requestMedia = upload(S3_BUCKET_FOLDER.SELF_CARE_MEDIA, media, false, 'mediaFile');

      let uploadFiles = [requestIcon, requestMedia];

      if (artWorkImage != null) {
        uploadFiles.push(upload(S3_BUCKET_FOLDER.SELF_CARE_ART_WORK, artWorkImage, false, 'artWork'));
      }

      if (headerImage != null) {
        uploadFiles.push(upload(S3_BUCKET_FOLDER.SELF_CARE_HEADER, headerImage, false, 'header'));
      }

      Promise.all(uploadFiles).then(results => {
        showLoader(false);
        results.forEach((result, _index) => {
          data[result?.key] = result["fileName"]
        });

        data["planId"] = selectedPlan?.value;
        data["category"] = selectedCategory?.value;

        createSelfCare(data).then(_res => {
          navigate(-1);
        }).catch(_err => { });
      })
    }

  };


  const updateFields = (data) => {

    data['icon'] = extractImagePathFromUrl(data?.icon);
    data['header'] = extractImagePathFromUrl(data?.header);
    data['artWork'] = extractImagePathFromUrl(data?.artWork);
    data['mediaFile'] = extractImagePathFromUrl(data?.mediaFile);
    updateSelfCare(data).then(_res => {
      navigate(-1)
    }).catch(_errr => { })
  }


  const headerValidation = () => {

    let data = {
      ...Constants.SELF_CARE_CONTENT_CATEGORY
    }

    if (selectedCategory == null) {
      return {}
    }

    if (!data.hasOwnProperty(selectedCategory?.value?.toUpperCase())) {
      return {
        required: `Header is required`,
      }
    } else {
      return {}
    }
  }

  const getAllCurrentTracksByCategory = (datas) => {
    getRelatedTracksByCategory().then(res => {
      setRelatedTracks(res?.data);

      if(datas){
        let filteredArray = res?.data?.filter(item => datas?.includes(item?.value));
        setSelectedRelatedTracks(filteredArray)

      }else{
        if(selectedRelatedTracks){
          setSelectedRelatedTracks(selectedRelatedTracks)
        }else{
          setSelectedRelatedTracks([])
        }
      }
    }).catch(_err => { })
  }

  const onSelecteRelatedTracks = (e) => {
    setSelectedRelatedTracks(e)
  }


  return (
    <>
      <form className="edit-user-container" onSubmit={handleSubmit(onSubmit)}>
        <div className="back-to-user-container">
          <img
            src={assets.Icons.left_icon}
            alt="left-icon"
            className="left-icon"
          />
          <span
            className="back-to-user"
            onClick={() => navigate("/main/content")}
          >
            Back to Content
          </span>
        </div>
        <div className="edit-user col-md-6 ">
          <p className="edit-user-details">
            {selfCareData ? "Edit" : "Add"} Self Care Content
          </p>
          <div className="col-md-12 d-flex">
            <div className=" col-md-6 ">
              <div className="">
                <label className="login-input-label">Category</label>
                <CustomController
                  name="category"
                  control={control}
                  data={selectedCategory}
                  error={errors.category}
                  register={register("category", dropDownValidation('Category'))}
                  render={({ field: { onChange } }) => {
                    return (
                      <NormalSelect
                        options={categoryOptions}
                        borderGreen
                        error={errors.category}
                        onClickEdit={onClickEdit}
                        value={selectedCategory}
                        onClickDelete={onClickDelete}
                        onChange={(e) => {
                          setSelectedCatgory(e);
                          onChange(e?.value);
                        }}
                        placeholder="Select a Category or create a new one"
                      />
                    );
                  }}
                />
              </div>
              <div className="">
                <label className="login-input-label">Media File</label>
                <CustomController
                  name="mediaFile"
                  control={control}
                  error={errors.mediaFile}
                  register={register("mediaFile", dropDownValidation('Media File'))}
                  render={({ field: { onChange } }) => {
                    return (
                      <FileUpload
                        placeholder="Select an file"
                        accept=".mp3,.mp4"
                        error={errors.mediaFile}
                        onCalculateDuration={(e) => {
                          setDuration(e);
                          setValue('duration', e);
                        }}
                        fileNameData={mediaFileName}
                        onChange={(e) => {
                          setMedia(e);
                          if (e == null) {
                            onChange('');
                            setValue('duration', '');
                            setDuration('')

                          } else {
                            onChange("File uploaded")
                          }
                        }}
                      />
                    );
                  }}
                />

              </div>{" "}
              <div className="">
                <label className="login-input-label">Subscription</label>
                <CustomController
                  name="planId"
                  control={control}
                  data={selectedPlan}
                  error={errors.planId}
                  register={register("planId", dropDownValidation('Subscription'))}
                  render={({ field: { onChange } }) => {
                    return (
                      <NormalSelect
                        options={plansOptions}
                        borderGreen
                        error={errors.planId}
                        onClickEdit={onClickEdit}
                        disableButtons
                        value={selectedPlan}
                        onClickDelete={onClickDelete}
                        onChange={(e) => {
                          setSelectedPlan(e);
                          onChange(e?.value);
                        }}
                        placeholder="Select a plan subscription"
                      />
                    );
                  }}
                />

              </div>
              <div className="">
                <label className="login-input-label">Title</label>
                <NormalInput
                  type={"text"}
                  placeholder="Eg : Breeze"
                  register={register("title", {
                    required: "Title is required",
                  })}
                  error={errors.title}
                  messages={errors}
                />
              </div>
              <div className="">
                <label className="login-input-label">Duration (In Minutes)</label>
                <NormalInput
                  type={"text"}
                  placeholder="Please Enter Duration in Minutes"
                  value={duration}
                  disabled
                />
              </div>
              <div className="">
                <label className="login-input-label">Tags</label>
                <NormalInput
                  type={"text"}
                  placeholder="Please enter tags in this format: #Inspiration"
                  register={register("tags", {
                    required: selectedCategory?.value != 'Stretch' ? 'Tags is required' : false
                  })}
                  error={errors.tags}
                  messages={errors}
                />
              </div>
              <div className="">
                <label className="login-input-label">Related Tracks</label>
                <MultiSelect
                  options={relatedTracks}
                  name="relatedTracks"
                  onChange={(e) => {
                    onSelecteRelatedTracks(e);
                  }}
                  borderGreen
                  value={selectedRelatedTracks}
                  disableButtons
                />

              </div>
            </div>
            <div className=" col-md-6 ms-3">
              <div className="">
                <label className="login-input-label">Artwork</label>
                <CustomController
                  name="artWork"
                  control={control}
                  error={errors.artWork}
                  register={register("artWork", {
                    required: selectedCategory?.value != 'Stretch' ? 'Art Work is required' : false
                  })}
                  render={({ field: { onChange } }) => {
                    return (
                      <FileUpload
                        placeholder="Select an image"
                        file={artWorkImage}
                        onChange={(e) => {
                          setArtWorkImage(e);
                          if (e == null) {
                            onChange('')
                          } else {
                            onChange("File uploaded")
                          }
                        }}
                        error={errors.artWork}
                      />
                    );
                  }}
                />

              </div>{" "}
              <div className="">
                <label className="login-input-label">Icon Image</label>
                <CustomController
                  name="icon"
                  control={control}
                  error={errors.icon}
                  register={register("icon", dropDownValidation('Icon'))}
                  render={({ field: { onChange } }) => {
                    return (
                      <FileUpload
                        placeholder="Select an image"
                        file={iconImage}
                        onChange={(e) => {
                          setIconImage(e);
                          if (e == null) {
                            onChange('')
                          } else {
                            onChange("File uploaded")
                          }
                        }}
                        error={errors.icon}
                      />
                    );
                  }}
                />
              </div>{" "}
              <div className="">
                <label className="login-input-label">Header Image</label>
                <CustomController
                  name="header"
                  control={control}
                  error={errors.header}
                  register={register("header", {})}
                  render={({ field: { onChange } }) => {
                    return (
                      <FileUpload
                        placeholder="Select an image"
                        file={headerImage}
                        onChange={(e) => {
                          setHeaderMessage(e);
                          if (e == null) {
                            onChange('')
                          } else {
                            onChange("File uploaded")
                          }
                        }}
                        error={errors.header}
                      />
                    );
                  }}
                />
              </div>{" "}
            </div>
          </div>
          <div className=" d-flex mt-3">
            <div className="edit-cancel-btn">
              <NormalButton
                label=" CANCEL"
                cancelBtn
                onClick={() => navigate("/main/content")}
              />
            </div>
            <div className="edit-update-btn">
              <NormalButton label={`${selfCareData ? "UPDATE" : "ADD"}`} mainBg />
            </div>
          </div>
        </div>
      </form>

      <AddDropDown
        show={show}
        category={dropdownCategory}
        onAddedData={onAddedData}
        editData={editData} />

      <DeleteDropDown
        show={showDelete}
        title={"Delete"}
        description={"Are you sure do you want to delete?"}
        onClickYes={onClickDeleteFromPopup}
        onHide={() => setShowDelete(false)}
      />
    </>
  );
};

export default EditSelfCare;
