import React, { useEffect, useState } from "react";
import "./styles.scss";
import NormalModal from "../NormalModal";
import assets from "../../assets";
import InputFieldNoValidation from "../inputFieldNoValidation";
import FileUpload from "../FileUpload";
import NormalButton from "../NormalButton";
import { useForm } from "react-hook-form";
import "../../screens/login/styles.scss";
import "../NormalTable/styles.scss";
import { S3_BUCKET_FOLDER, errorToast, upload } from "../../utils";
import Constants from "../../utils/constants";
import { createDropDown, updateDropDown } from "../../redux/reducers/dropdownSlice";
import NormalSelect from "../NormalSelect";

const AddDropDown = ({ show, category, onAddedData, editData }) => {
  const [label, setLabel] = useState('');
  const [value, setValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [valueOptions] = useState([
    { label: 'Yes', value: "true" },
    { label: 'No', value: "false" },
  ])
  const [id, setId] = useState(null);
  const [file, setFile] = useState('');



  useEffect(() => {
    if (editData) {
      let {
        label,
        value,
        id
      } = editData;

      setValue(value);

      if (value === "true") {
        setSelectedValue(valueOptions[0])
      } else if (value === "false") {
        setSelectedValue(valueOptions[1])
      }

      setId(id);
      setLabel(label);
      
      if (label?.includes('https://') && /(https?:\/\/[^\s]+)/.test(label)) {
        setFile(label)
      }
    } else {
      onChangeFile()
    }
    setModalOpen(show);
  }, [show]);

  const [photoFile, setPhotoFile] = useState(null);

  const [modalOpen, setModalOpen] = useState(show);

  const [isFileUpload, setIsFileUpload] = useState(true);


  const onChangeLabel = (e) => {
    setValue(e.target.value)
    setLabel(e.target.value)
  }

  const onChangeValue = (e) => {
    setValue(e?.value);
    setSelectedValue(e);
  }

  const onChangeFile = (e) => {
    setPhotoFile(e);
    setFile('')
    setIsFileUpload(e == null)
    setValue('')
    setLabel('drop-downs/')
    setSelectedValue(null)
  }


  const onClickSubmit = () => {
    if (label == '' && (photoFile == null || !file)) {
      errorToast(Constants.LABEL_ERROR_DROP_DOWN);
      return
    };

    if (photoFile != null && !selectedValue && !Object.keys(selectedValue).length == 0) {
      errorToast(Constants.VALUE_ERROR_DROP_DOWN);
      return
    };

    let request = {
      "label": label,
      "value": value,
      "dropDownType": category
    }

    if (photoFile != null) {
      upload(S3_BUCKET_FOLDER.DROP_DOWNS, photoFile).then(res => {
        request["label"] = res.fileName;
        updateData(request, category);
      });
    } else {
      updateData(request, category);
    }
  };

  const updateData = (request, category) => {


    if (request.label?.startsWith("https://")) {
      let indexCom = request.label.indexOf(".com/");
      let indexQuestionMark = request.label.indexOf("?");
      let result = request.label.slice(indexCom + 5, indexQuestionMark);
      request["label"] = result;
    }

    if (editData) {
      request["_id"] = id;
      delete request["dropDownType"];

      updateDropDown(request).then(_res => {
        onAddedData(category);
        onChangeFile()
      })
    } else {
      createDropDown(request).then(_res => {
        onAddedData(category);
        onChangeFile()
      })
    }
  }


  const {
    handleSubmit,
    register,
    formState: { errors },
    control
  } = useForm({ mode: "onBlur" });

  const onHideModal = () => {
    setModalOpen(false);
    onAddedData()
  }
  return (
    <>
      <NormalModal show={modalOpen} setModal={onHideModal}>
        <div className="add-dropdown-container">
          <div className="filter-popup-container">
            <span className="filter-users">{editData ? "Edit Dropdown data" : "Add New Dropdown"}</span>
            <img
              src={assets.Icons.close_popup}
              alt="close"
              className="popup-close-icon"
              onClick={() => onHideModal()}
            />
          </div>
          <div className="">
            <div
              className="d-flex align-items-center flex-wrap"
            >
              <div className="col-md-5">
                <label className="login-input-label">Display Label</label>
                <InputFieldNoValidation
                  type={"text"}
                  placeholder={"Please enter Label"}
                  value={label && `${label}`.includes('drop-downs/') ? '' : value}
                  onChange={onChangeLabel}
                  disabled={!isFileUpload || file}
                />
              </div>
              <div className="col-md-1">
                <p className="or-text">Or</p>
              </div>
              <div className="col-md-6">
                {" "}
                <label className="login-input-label">Display Label</label>
                <FileUpload
                  placeholder={"Select a File"}
                  dropdownFile={true}
                  onChange={(e) => {
                    onChangeFile(e)
                  }}
                  file={file ?? photoFile}
                  error={errors.labelFile}
                  messages={errors}
                />

              </div>
              {
                (photoFile ?? file) &&
                <div className="col-md-12">
                  <label className="login-input-label">Value for Selected File</label>
                  <NormalSelect
                    placeholder={"Please enter Value"}
                    value={selectedValue}
                    options={valueOptions}
                    onChange={onChangeValue}
                    error={errors.value}
                    name="value"
                    disableButtons
                    messages={errors}
                  />
                </div>
              }
              <div className="col-md-12 text-center mt-3">
                <NormalButton label={"SUBMIT"} mainBg onClick={onClickSubmit} />
              </div>
            </div>
          </div>
        </div>
      </NormalModal>
    </>
  );
};

export default AddDropDown;