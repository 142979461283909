import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import FormErrorMessage from "../ErrorMessage";
import ExportedData from "../../assets";

const FileUpload = ({
  image,
  artwork,
  headerImage,
  audioFile,
  tutorialIcon,
  placeholder,
  error,
  messages,
  dropdownFile,
  onChange,
  file,
  fileNameData,
  accept = "image/png, image/jpeg",
  onCalculateDuration
}) => {
  const hasError = error !== undefined;
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (file) {
      setPhoto(file);
    }
  }, [file])

  useEffect(() => {
    setFileName(fileNameData);
    if (fileNameData == '') {
      onClickDeleteFile()
    }
  }, [fileNameData])

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  };


  const [photo, setPhoto] = useState(null);
  const [_photoFile, setPhotoFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleFileChange = async (event) => {

    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setPhotoFile(selectedFile);
      setFileName(selectedFile.name);
      
      if(accept == 'video/mp4' || accept == ".mp3,.mp4"){
        const duration = await getDuration(selectedFile);
        const formattedDuration = formatDuration(duration);
        onCalculateDuration(formattedDuration)
      }

      const fileNameParts = selectedFile.name.split('.');
      
      const fileExtension = fileNameParts[fileNameParts.length - 1];
      
      if (!`${fileExtension}`.includes('pdf') && !`${fileExtension}`.includes('mp4')) {
        const reader = new FileReader();
        reader.onload = () => {
          setPhoto(reader.result);
        };
        reader.readAsDataURL(selectedFile);
      }

    }

    onChange(selectedFile);

  };

  const onClickDeleteFile = () => {
    setPhotoFile(null)
    setFileName('');
    setPhoto(null);
    onChange(null);
  }

  const getDuration = (file) => {
    return new Promise((resolve, reject) => {
      const isAudio = file.type.startsWith('audio');
      const _isVideo = file.type.startsWith('video');
  
      const mediaElement = isAudio ? document.createElement('audio') : document.createElement('video');
      const objectUrl = URL.createObjectURL(file);
  
      mediaElement.addEventListener('loadedmetadata', () => {
        URL.revokeObjectURL(objectUrl);
        const duration = mediaElement.duration;
        resolve(duration);
      });
  
      mediaElement.addEventListener('error', () => {
        URL.revokeObjectURL(objectUrl);
        reject(new Error('Error loading media'));
      });
  
      mediaElement.src = objectUrl;
    });
  };
  
  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };
  

  return (
    <>
      <div
        className={` ${dropdownFile ? "dropdown-file-container" : "file-upload-container"
          }  ${hasError ? "file-upload-error" : ""}`}
        onClick={handleFileInputClick}
      >
        <input type="file" accept={accept} style={{ display: "none" }} ref={fileInputRef} onChange={handleFileChange} />
        {
          <div>
            {
              (photo && accept == "image/png, image/jpeg") ?
                <img
                  src={photo}
                  alt={"file-upload"}
                  className={dropdownFile ? "drop-down-image" : "file-image"}
                  onError={(e) => {
                    e.target.src = ExportedData.Images.user_placeholder;
                    e.target.onerror = null;
                  }}
                />
                :
                <p className="select-image-text">{fileName ? fileName : placeholder}</p>
            }


          </div>
        }
      </div>
      {
        (photo || fileName) && <label className="delete-file" onClick={()=> onClickDeleteFile()}>Delete File</label>
      }
    </>
  );
};

export default FileUpload;
