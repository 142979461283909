import React from "react";
import assets from "../../assets";
import "./styles.scss";
import "../editUsers/styles.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import NormalButton from "../../components/NormalButton";

const EditFreeFeature = () => {
  let navigate = useNavigate();
  const [freeFeatures] = useState([
    {
      id: 1,
      title: "Task list",
      icon: assets.Icons.feature_success,
      downIcon: true,
    },
    {
      id: 2,
      title: "Number of Tasks",
      input: (
        <input
          type="text"
          maxLength="1"
          className="free-feature-input"
          value={10}
        />
      ),
      inputLabel: "Active",
    },
    {
      id: 1,
      title: "Task Reminder",
      icon: assets.Icons.feature_close,
      downIcon: true,
    },
    {
      id: 1,
      title: "Edit Tasks",
      icon: assets.Icons.feature_close,
      downIcon: true,
    },
    {
      id: 1,
      title: "Calendar",
      icon: assets.Icons.feature_success,
      downIcon: true,
    },
    {
      id: 1,
      title: "Calendar Integration",
      icon: assets.Icons.feature_close,
      downIcon: true,
    },
    {
      id: 1,
      title: "Focus Time",
      icon: assets.Icons.feature_success,
      downIcon: true,
    },
    {
      id: 1,
      title: "Priority Wizard",
      icon: assets.Icons.feature_close,
      downIcon: true,
    },
    {
      id: 2,
      title: "Self-Care Tasks",
      input: (
        <input
          type="text"
          maxLength="1"
          className="free-feature-input"
          value={3}
        />
      ),
      inputLabel: "per Category",
    },
  ]);
  return (
    <div className="free-feature-user-container">
      <div className="back-to-user-container">
        <img
          src={assets.Icons.left_icon}
          alt="left-icon"
          className="left-icon"
        />
        <span
          className="back-to-user"
          onClick={() => navigate("/main/payment")}
        >
          Back to Payment
        </span>
      </div>
      <div className="edit-user col-md-3  ">
        <p className="edit-user-details">Edit Free Features</p>
        <div className="free-feature-container">
          {freeFeatures.map((list) => {
            return (
              <div className="free-feature col-md-12">
                <div className="col-md-6">
                  <span className="free-feature-title ">{list.title}</span>
                </div>

                {list.icon && (
                  <div className="col-md-3 text-center">
                    <img
                      src={list.icon}
                      alt="icon"
                      className="free-feature-icon"
                    />
                  </div>
                )}
                {list.input && (
                  <div className="col-md-6 text-start">
                    {list.input}
                    <label className="free-feature-label">
                      {list.inputLabel}
                    </label>
                  </div>
                )}
                {list.downIcon && (
                  <div className="col-md-3 text-center">
                    <img
                      src={assets.Icons.down}
                      alt="downIcon"
                      className="free-feature-down-icon"
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className=" d-flex mt-3">
        <div className="edit-cancel-btn">
          <NormalButton
            label=" CANCEL"
            cancelBtn
            onClick={() => navigate("/main/payment")}
          />
        </div>
        <div className="edit-update-btn">
          <NormalButton label=" UPDATE" mainBg />
        </div>
      </div>
    </div>
  );
};

export default EditFreeFeature;
