import { createSlice } from "@reduxjs/toolkit";
import request from "../../services";
import Exported from "../../services/endpoints";
import { successToast } from "../../utils";

export const customerCareSlice = createSlice({
    name: "customerCare",
    initialState: {
        data: [],
        totalPages: 0
    },
    reducers: {
        saveData: (state, action) => {
            state.data = action.payload;
        },
        saveTotalPage: (state, action) => {
            state.totalPages = action.payload;
        }
    }
});

export const getAdminTickets = (page, search) => async (dispatch) => {
    let searchQuery = (search) ? `&search=${search}` : ``;

    request({
        url: `${Exported.Endpoints.GET_ALL_ADMIN_TICKETS}?page=${page}${searchQuery}`,
        method: Exported.APIMethods.GET,
        isLoader: search == ''
    }).then(res => {
        dispatch(saveData(res?.data?.results?.data));
        dispatch(saveTotalPage(res?.data?.results?.totalPages));
    }).catch(_err => {
    })
}


export const updateTickets = (data) => {

    return new Promise((resolve, _reject)=>{
        request({
            url: `${Exported.Endpoints.UPDATE_TICKETS}`,
            method: Exported.APIMethods.PATCH,
            data
        }).then(res => {
            successToast(res?.data?.message)
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const getTicketById = (_id) => {

    return new Promise((resolve, _reject)=>{
        request({
            url: `${Exported.Endpoints.GET_TICKET_BY_ID}${_id}`,
            method: Exported.APIMethods.GET,
        }).then(res => {
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const updateCommStatus = (data) => {

    return new Promise((resolve, _reject)=>{
        request({
            url: `${Exported.Endpoints.UPDATE_COMM_STATUS}`,
            method: Exported.APIMethods.PATCH,
            data
        }).then(res => {
            resolve(res?.data)
        }).catch(_err => {
        })
    })
}

export const { saveData, saveTotalPage } = customerCareSlice.actions;

export default customerCareSlice.reducer;
