import React, { useEffect } from "react";
import "../login/styles.scss";
import assets from "../../assets";
import NormalInput from "../../components/inputField";
import NormalSelect from "../../components/NormalSelect";
import { useState } from "react";
import NormalButton from "../../components/NormalButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TextAreaInput from "../../components/TextArea";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { DROP_DOWN_CATEGORY } from "../../utils";
import AddDropButton from "../../components/dropDownButton";
import AddDropDown from "../../components/addDropdown";
import DeleteDropDown from "../../components/deleteModal";
import { deleteDropDownById, getDropDown } from "../../redux/reducers/dropdownSlice";
import { getTicketById, updateTickets } from "../../redux/reducers/customerCareSlice";
import moment from "moment";
import DatePicker from "../../components/DatePicker";

const EditTicket = () => {
  let navigate = useNavigate();

  const { id } = useParams();

  const [ticketsData, setTicketsData] = useState(null);

  const [previousTicket, setPreviousTicket] = useState([]);

  const [options] = useState([
    { label: "Complete", value: 1 },
    { label: "Premium", value: 2 },
    { label: "Free", value: 3 },
  ]);

  useEffect(() => {
    if (id) {
      getTicketById(id).then(res => {
        setTicketsData(res?.data?.currentTicket);
        setPreviousTicket(res?.data?.previousTicket);
        setSelectedResoultionDate(res?.data?.currentTicket?.resolvedDate ? new Date(res?.data?.currentTicket?.resolvedDate) : null)
        getDropDownOptions(DROP_DOWN_CATEGORY.TICKET_CATEGORY, res?.data?.currentTicket);
        getDropDownOptions(DROP_DOWN_CATEGORY.TICKET_COMMUNICATION, res?.data?.currentTicket);
        getDropDownOptions(DROP_DOWN_CATEGORY.TICKET_STATUS, res?.data?.currentTicket);
      })
    }

  }, []);

  const onSubmit = (data) => {
    let request = {
      "resolvedDate": selectedResoultionDate ? moment(selectedResoultionDate).format("YYYY-MM-DD") : '',
      "communicationStatus": selectedComm?.value,
      "_id": ticketsData?._id,
      "status": seletedStatus?.value
    }

    updateTickets(request).then(_res => {
      window.location.href = '/main/customer-care';
    }).catch(_err => {

    })

  };

  const onClickTicket = (id) => {
    navigate(`/main/customer-care/${id}`);
    window.location.reload()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    control
  } = useForm({ mode: "onBlur" });

  const customErrorMessages = {
    required: "Email is required",
    pattern: {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: "Invalid email address",
    },
  };

  const [statusOptions, setStatusOptions] = useState([
    {
      label: <AddDropButton
        label="Add New Ticket Status"
        onClick={() => onClickAddDropDown(DROP_DOWN_CATEGORY.TICKET_STATUS)}
      />,
      isDisabled: true
    }
  ]);

  const [communicationOptions, setCommunicationOptions] = useState([
    {
      label: <AddDropButton
        label="Add New Comm Status"
        onClick={() => onClickAddDropDown(DROP_DOWN_CATEGORY.TICKET_COMMUNICATION)}
      />,
      isDisabled: true
    }
  ]);

  const [dropdownCategory, setDropdownCategory] = useState('');
  const [show, setShow] = useState(false);
  const [editData, setEditData] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [selectedResoultionDate, setSelectedResoultionDate] = useState(null);

  const [seletedStatus, setSeletedStatus] = useState(null);
  const [selectedComm, setSelectedComm] = useState(null);

  const onClickAddDropDown = (category) => {
    setDropdownCategory(category);
    setEditData(null)
    setShow(true);
  }

  const getDropDownOptions = (category, tkData) => {
    getDropDown(category).then(res => {
      switch (category) {
        case DROP_DOWN_CATEGORY.TICKET_STATUS: {
          let data = [{
            ...statusOptions[0]
          }];
          data = [...data, ...res];
          setStatusOptions(data);
          if (tkData) {
            let item = data.filter(it => it.value == tkData.status)[0];
            setSeletedStatus(item)
          } else {
            setSeletedStatus(null);
          }
          break;
        }
        case DROP_DOWN_CATEGORY.TICKET_COMMUNICATION: {
          let data = [{
            ...communicationOptions[0]
          }];
          data = [...data, ...res];
          setCommunicationOptions(data);
          if (tkData) {
            let item = data.filter(it => it.value == tkData.communicationStatus)[0];
            setSelectedComm(item)
          } else {
            setSelectedComm(null);
          }
          break;
        }
      }
    })
  }

  const onAddedData = (category) => {
    setShow(false);
    if (category) {
      window.location.reload();
    }
  }

  const onClickDeleteFromPopup = () => {
    setShowDelete(false)
    let {
      _id
    } = deleteData;

    deleteById(_id);
  }
  const onClickDelete = (data) => {
    setDeleteData(data);
    setShowDelete(true);
  }

  const onClickEdit = (data) => {
    setDropdownCategory(data.dropDownType);
    setEditData({
      label: data.label,
      value: data.value,
      id: data._id
    });
    setShow(true);
  }

  const deleteById = (id) => {
    deleteDropDownById(id).then(_res => {
      window.location.reload();
    })
  }

  return (
    <>
      <form className="edit-user-container" onSubmit={handleSubmit(onSubmit)}>
        <div className="back-to-user-container">
          <img
            src={assets.Icons.left_icon}
            alt="left-icon"
            className="left-icon"
          />
          <span
            className="back-to-user"
            onClick={() => navigate("/main/customer-care")}
          >
            Back to Customer Care
          </span>
        </div>
        <div className="edit-user col-md-6 ">
          <p className="edit-user-details">Edit Ticket Status</p>
          <div className="col-md-12 d-flex">
            <div className=" col-md-6 ">
              <div className="">
                <label className="login-input-label">Ticket No</label>
                <NormalInput
                  type={"text"}
                  disabled
                  value={ticketsData?.ticketNo ? `#${ticketsData?.ticketNo}` : ''}
                  placeholder="Please Enter Ticket No"
                />
              </div>
              <div className="">
                <label className="login-input-label">Username</label>
                <NormalInput
                  type={"text"}
                  disabled
                  value={`${ticketsData?.userId?.userName ?? ''}`}
                  placeholder="Please Enter Username"
                />
              </div>{" "}
              <div className="">
                <label className="login-input-label">Email</label>
                <NormalInput
                  type={"text"}
                  disabled
                  value={`${ticketsData?.userId?.emailId ?? ''}`}
                  placeholder="Please Enter email Id"
                />
              </div>
              <div className="">
                <label className="login-input-label">Category</label>
                <NormalInput
                  type={"text"}
                  disabled
                  value={`${ticketsData?.category ?? ''}`}
                  placeholder="Please Enter Category"
                />
              </div>{" "}
              <div className="">
                <label className="login-input-label">Details</label>
                <TextAreaInput
                  disabled
                  value={`${ticketsData?.description ?? ''}`}
                  placeholder="Please Enter Details"
                />
              </div>
            </div>
            <div className=" col-md-6 ms-3">
              <div className="">
                <label className="login-input-label">Ticket Date</label>
                <NormalInput
                  type={"text"}
                  disabled
                  value={ticketsData?.createdAt ? moment(ticketsData?.createdAt).add("+05:30").format("DD MMM YY hh:mm a") : ''}
                  placeholder="Please Enter Ticket Date"
                />
              </div>
              <div className="">
                <label className="login-input-label">Status</label>
                <NormalSelect
                  options={statusOptions}
                  borderGreen
                  value={seletedStatus}
                  disabled={ticketsData?.status == 'Complete'}
                  onChange={(e) => {
                    setSeletedStatus(e)
                  }}
                />
              </div>{" "}
              <div className="">
                <label className="login-input-label">Resolution Date</label>

                <DatePicker
                  calendarStyle={true}
                  placeHolder={true}
                  isWeekdays={true}
                  disabled={ticketsData?.status == 'Complete'}
                  value={selectedResoultionDate}
                  onChange={(e) => {
                    setSelectedResoultionDate(e)
                  }}
                  format={"dd-MM-yyyy"}
                  placeholderText="DD/MM/YYYY"
                  dateIcon />

              </div>
              <div className="">
                <label className="login-input-label">Communication Email</label>
                <NormalSelect
                  options={communicationOptions}
                  borderGreen
                  disabled={ticketsData?.status == 'Complete'}
                  value={selectedComm}
                  onChange={(e) => {
                    setSelectedComm(e)
                  }}
                />
              </div>{" "}
            </div>
          </div>
        </div>
        <div className="edit-user col-md-6 ">
          <p className="edit-ticket-status">Edit Transaction History</p>
          <div className="col-md-12 d-flex">
            <div className=" col-md-6 ">
              <div className="">
                <label className="login-input-label">
                  First Time Logged-In Date
                </label>
                <NormalInput
                  type={"text"}
                  disabled
                  value={ticketsData?.userId?.firstTimeLoggedInDate ?? ''}
                  placeholder="Please Enter Logged In Date"
                />
              </div>
              <div className="">
                <label className="login-input-label">Payment Plan</label>
                <NormalInput
                  type={"text"}
                  disabled
                  value={`${ticketsData?.userId?.planId?.planName ?? ''}`}
                  placeholder="Please Enter Payment Plan"
                />
              </div>{" "}
            </div>
            <div className=" col-md-6 ms-3">
              <div className="">
                <label className="login-input-label">Previous Tickets</label>
                <div className="previous-ticket-container">
                  {
                    previousTicket.length > 0 ? previousTicket?.map(it=>{
                      return(
                        <div className="ticket-background" onClick={()=> onClickTicket(it?._id)}>
                          <span>{`#${it?.ticketNo}`}</span>
                          </div>
                      )
                    }) : <span className="no-previous-tickets">No Previous Tickets Available for this User</span>
                  }

                </div>
              </div>
            </div>
          </div>
          {
            ticketsData?.status == 'Complete'  ? <div/> : 
              <div className=" d-flex mt-3">
                <div className="edit-cancel-btn">
                  <NormalButton
                    label=" CANCEL"
                    cancelBtn
                    onClick={() => navigate("/main/customer-care")}
                  />
                </div>
                <div className="edit-update-btn">
                  <NormalButton label={`UPDATE`} mainBg />
                </div>
              </div>
          }
        </div>
      </form>
      <AddDropDown
        show={show}
        category={dropdownCategory}
        onAddedData={onAddedData}
        editData={editData} />

      <DeleteDropDown
        show={showDelete}
        title={"Delete"}
        description={"Are you sure do you want to delete?"}
        onClickYes={onClickDeleteFromPopup}
        onHide={() => setShowDelete(false)}
      />

    </>
  );
};

export default EditTicket;
