import axios from 'axios';
import { store } from '../redux/store'; // Import your Redux store instance
import { Toast } from '../components/toast';
import request from '../services';
import moment from 'moment';
import LoaderRef from "../components/loader";
import Exported from '../services/endpoints';
import routes from '../routes/routes';


export const toCamelCase = (input) =>
    input.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
        index === 0 ? letter.toLowerCase() : letter.toUpperCase()
    ).replace(/\s+/g, '');


export const REGEX = {
    EMAIL_ID: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
    PASSWORD: /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+={}\[\]:;<>,.?~])(?=.{8,})[a-zA-Z0-9!@#$%^&*()_+={}\[\]:;<>,.?~]+$/
}


// Function to retrieve token from Redux store
export const getTokenFromStore = () => {
    const state = store.getState();
    const token = state.auth.userData?.accessToken;
    return token;
};

export const getPermissionFromStore = () => {
    const state = store.getState();
    const permissions = state.auth.userData?.permissions;
    return permissions;
};

export const getRefreshTokenRequest = () => {
    const state = store.getState();
    const refreshToken = state.auth.userData?.refreshToken;
    const _id = state.auth.userData?._id;
    return {
        _id,
        refreshToken,
        userType: "staff"
    };
};

export const getUserDataFromStore = () => {
    const state = store.getState();
    const data = state.auth.userData;
    return data;
};

export const getRememberMeStatus = () => {
    const state = store.getState();
    return state.auth.isRememberMe || false;
};

const baseUrl = process.env.REACT_APP_API_URL;


export const upload = async (folder, file, isLoader = true, key = '') => {

    return new Promise((resolve, reject) => {

        if (isLoader) {
            showLoader(true)
        }


        const formData = new FormData();
        formData.append('file', file);
        formData.append('folder', folder);


        axios.post(baseUrl + "/upload/file", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': getTokenFromStore()
            },
        }).then(res => {
            if (isLoader) {
                showLoader(false)
            }
            resolve({
                ...res?.data?.data,
                key
            })
        }).catch(err => {
            let { status, data } = err?.response;
            let { message } = data;
            Toast({ type: "error", message: message });
            if (status == 401) {
                window.location.pathname = '/auth/login'
            }
            if (isLoader) {
                showLoader(false)
            }
        })
    })

}


export const S3_BUCKET_FOLDER = {
    STAFFS: "staffs",
    DROP_DOWNS: "drop-downs",
    APP_TERMS_POLICY: 'app_terms_policy',
    BADGES_ICONS: 'badges/icons',
    TUTORIAL_ICONS: 'tutorials/icons',
    TUTORIAL_VIDEO: 'tutorials/videos',
    SELF_CARE_ART_WORK: 'self-care/artWork',
    SELF_CARE_HEADER: 'self-care/header',
    SELF_CARE_ICON: 'self-care/icons',
    SELF_CARE_MEDIA: 'self-care/media',
    SELF_CARE_QR: 'self-care/qr',
}

export const DROP_DOWN_CATEGORY = {
    STAFF_ROLE: 'staff/role',
    ADMIN_ROLE: 'admin/role',
    BADGE_CATEGORY: 'badge/category',
    BADGE_COLOR: 'badge/color',
    BADGE_FONTS: 'badge/fonts',
    BADGE_GIFS: 'badge/gifs',
    SELF_CARE_CATEGORY: 'selfcare/category',

    TICKET_CATEGORY: 'tickets/category',
    TICKET_STATUS: 'tickets/status',
    TICKET_COMMUNICATION: 'tickets/communitcation',

    USER_PERMISSION: 'users/permissions',
    PLAN_FEATURE: 'plan/features',
}

export const CSV_FILE_NAME = {
    STAFF_LIST: 'staffs',
    BADGES_LIST: 'badges',
    CUSTOMER_TICKET: 'Customer_Tickets',
    USER_LIST: 'Users_List',
    SUBSCRIPTION_LIST: 'Subscription_List'
}

export const FILE_NAME = {
    INVOICE: 'Invoice'
}


export const fileDownload = (
    url,
    fileName,
    fileExtension = "csv"
) => {
    request({
        url,
        params: {
            export: 1
        }
    }).then((response) => {
        const dateObj = moment(new Date()).format("DD-MMM")
        const downloadFile = document.createElement("a");
        document.body.appendChild(downloadFile);
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        downloadFile.href = url;
        downloadFile.download = `${dateObj} ${fileName}.${fileExtension}`;
        downloadFile.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(downloadFile);
    });
};

export const downloadFileFromUrl = (
    url,
    fileName,
    data,
    fileExtension,
) => {
    showLoader(true)
    axios(baseUrl + url, {
        responseType: "blob",
        headers: {
            Authorization: getTokenFromStore()
        },
        data,
        method: Exported.APIMethods.POST
    }).then((response) => {

        const file = new Blob([response.data], { type: fileExtension == 'zip' ? 'application/zip' : 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        const dateObj = moment(new Date()).format("DD-MMM")
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = `${dateObj} ${fileName}.${fileExtension}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);

    })
        .catch((error) => {
            console.error('Error downloading file:', error);
        }).finally(() => {
            showLoader(false)
        })


    // request({
    //     url,
    //     method: Exported.APIMethods.POST,
    //     data,
    //     headers:{
    //         'Accept': 'application/zip',
    //     }
    // }).then((response) => {

    //     const uint8Array = new TextEncoder().encode(response.data);

    //     const blob = new Blob([uint8Array], { type: 'application/zip' });

    //     const url = window.URL.createObjectURL(blob);

    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', 'pdfs.zip'); // Set the desired file name
    //     document.body.appendChild(link);

    //     // Initiate the download
    //     link.click();
    //     document.body.removeChild(link);
    //     window.URL.revokeObjectURL(url);


    // });
};

export const successToast = (message) => {
    Toast({ type: "success", message });
}

export const errorToast = (message) => {
    Toast({ type: "error", message });
}


export const dropDownValidation = (fieldName) => {
    return {
        required: `${fieldName} is required`,
    }
}

export const showLoader = (status) => {
    if (LoaderRef && LoaderRef.render && LoaderRef.render.defaultProps) {
        LoaderRef.render.defaultProps.setLoaderStatus(status)
    }
}

export const isHTMLString = (str) => {
    const doc = new DOMParser().parseFromString(str, 'text/html');
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}

export const downloadFile = (s3Url, fileName) => {
    axios({
        url: s3Url,
        method: 'GET',
        responseType: 'blob',
    })
        .then((response) => {
            const file = new Blob([response.data]);
            const fileURL = URL.createObjectURL(file);

            const a = document.createElement('a');
            a.href = fileURL;
            a.download = `${fileName}.png`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(fileURL);
        })
        .catch((error) => {
            console.error('Error downloading file:', error);
        });
};

export const extractImagePathFromUrl = (url) => {
    if (url && url?.startsWith('https://')) {
        let extractedSubstring = url.match(/\.com\/(.*?)\?/);
        if (extractedSubstring && extractedSubstring.length > 1) {
            return extractedSubstring[1]
        } else {
            return "";
        }
    } else {
        return url;
    }
}


export const calculatePaymentDuration = (date, duration) => {
    if (date) {
        return duration > 0 ? calculateMonthAndDays(duration) : "NA"
    } else {
        return "NA"
    }

}

export const checkPaymentDuration = (item) => {
    let {
      paymentDuration,
      planName
    } = item;

    let duration = "NA";

    if(planName == "Premium"){
      duration = paymentDuration > 1 ? `${paymentDuration} months` : `${paymentDuration} month`;
    }

    if(planName == "Premium (Promo)"){
      duration = paymentDuration;
    }


    return duration;
  }


const calculateMonthAndDays = (differenceInDays) => {
    const months = Math.floor(differenceInDays / 30);
    const remainingDays = differenceInDays % 30;

    if (months > 0 && remainingDays > 0) {
        return `${months} month${months > 1 ? 's' : ''} ${remainingDays} day${remainingDays > 1 ? 's' : ''}`;
    } else if (months > 0) {
        return `${months} month${months > 1 ? 's' : ''}`;
    } else {
        return `${differenceInDays} day${differenceInDays > 1 ? 's' : ''}`;
    }
}

export const getSideBarPermissions = (permissionKey) => {
    let permissions = getPermissionFromStore();
    if (permissions) {
        return permissions.some(it => it.module == permissionKey && it.isView);
    }
    else return false
}


export const getComponentFromPath = (key, value, returnKey) => {

    let routesData = [...routes];

    for (let i = 0; i < routesData.length; i++) {
        const element = routesData[i];
        if (element.childrens) {
            let checkKeys = key == 'childPath' ? element.path : ''
            let data = element.childrens.filter(keys => `${checkKeys}${keys[key]}` == value)[0];
            if (data) {
                return data
            }
        }

    }
    return null
}