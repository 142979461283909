import { createSlice } from "@reduxjs/toolkit";
import request from "../../services";
import Exported from "../../services/endpoints";
import { successToast } from "../../utils";

export const privacyTermsSlice = createSlice({
    name: "privacyTerms",
    initialState: {
        terms: null,
        privacy: null
    },
    reducers: {
        saveTerms: (state, action) => {
            state.terms = action.payload;
        },
        savePrivacy: (state, action) => {
            state.privacy = action.payload;
        },
    }
});


export const getPrivacyTerms = () => async (dispatch) => {
    request({
        url: Exported.Endpoints.GET_PRIVACY_TERMS,
        method: Exported.APIMethods.GET
    }).then(res => {

        let {
            terms,
            privacy
        } = res.data?.data;

        dispatch(savePrivacy(privacy ?? ''));
        dispatch(saveTerms(terms ?? ''));
    }).catch(_err => {
    })
}


export const createPrivacyTerms = (data) => {
    return new Promise((resolve, _reject)=>{
        request({
            url: Exported.Endpoints.CREATE_PRIVACY_TERMS,
            method: Exported.APIMethods.POST,
            data
        }).then(res => {
            successToast(res?.data?.message)
            resolve(res?.data);
        }).catch(_err => {
        })
    })
}

export const updatePrivacyTerms = (data) => {

    return new Promise((resolve, _reject)=>{
        request({
            url: Exported.Endpoints.UPDATE_PRIVACY_TERMS,
            method: Exported.APIMethods.PUT,
            data
        }).then(res => {
            successToast(res?.data?.message)
            resolve(res?.data);
        }).catch(_err => {
        })
    })
}

export const { savePrivacy, saveTerms } = privacyTermsSlice.actions;

export default privacyTermsSlice.reducer;
