import React, { useEffect, useState } from "react";
import "./styles.scss";
import NormalInput from "../../components/inputField";
import NormalButton from "../../components/NormalButton";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  login,
  saveRememberMe,
  saveUserData,
} from "../../redux/reducers/authSlice";
import { REGEX, getComponentFromPath, showLoader } from "../../utils";
import Constants from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import CheckBox from "../../components/checkBox";

const Login = () => {
  let navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();

  const rememberMeSelector = useSelector((state) => state.auth?.isRememberMe);
  const userData = useSelector((state) => state.auth?.userData);

  const [rememberMe, setRememberMe] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    if (rememberMeSelector) {
      navigateToMain(userData);
      setRememberMe(true);
    }
  }, []);

  const handleEmailChange = async (event) => {
    setSuccess(true);
  };

  const onSubmit = (data) => {
    let values = getValues();
    let request = {
      ...data,
      userType: "staff",
    };
    login(request)
      .then((res) => {
        dispatch(saveRememberMe(rememberMe));
        dispatch(saveUserData(res.data.data));
        navigateToMain(res.data.data);
      })
      .catch((err) => { });
  };

  const navigateToMain = (data) => {

    let permissions = [...data?.permissions];

    let hasDashboardPermission = permissions.some(it => it.module == 'Dashboard' && it.isView);

    if(hasDashboardPermission){
      navigate("/main/dashboard");
    }else{
      let currentPermission = permissions.filter(it => it.isView);

      if(currentPermission.length > 0){
        let topPermissionData = currentPermission[0];
        let module = `${topPermissionData?.module}`;

        let path = getComponentFromPath("screen", module);

        let navPath = `/main${path?.childPath}`;

        navigate(navPath);

      }else{
        navigate("/main/profile-settings");
      }

    }

    // navigate("/main/dashboard");
  };
  const customErrorMessages = {
    required: "Email is required",
    pattern: {
      value: REGEX.EMAIL_ID,
      message: Constants.EMAIL_ID_ERROR,
    },
  };

  const onChangeRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };

  return (
    <>
      <form
        className="col-md-6 login-container"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="login-content">
          <p className="content-title">ZEN-it Admin</p>
          <p className="login-text">
            Please Provide valid credentials <br></br>for a seamless login
            experience
          </p>
          <div className="login-input-container">
            <label
              className={`login-input-label ${errors.email !== undefined ? "text-danger" : ""
                }`}
            >
              Email
            </label>
            <NormalInput
              type={"text"}
              placeholder={"Please enter your email"}
              onChange={handleEmailChange}
              register={register("emailId", {
                required: customErrorMessages.required,
                pattern: customErrorMessages.pattern,
              })}
              error={errors.emailId}
              messages={customErrorMessages}
              leftIcon
              emailIcon
              rightIcon
              successIcon={success}
            />

            <label className="login-input-label">Password</label>
            <NormalInput
              type={"password"}
              name={"asdadasd"}
              placeholder={"Please enter your Password"}
              register={register("password", {
                required: "Password is required",
                pattern: {
                  value: REGEX.PASSWORD,
                  message: Constants.PASSWORD_ERROR,
                },
              })}
              error={errors.password}
              messages={errors}
              leftIcon
              passwordIcon
              rightIcon
              eyeIcon
            />
          </div>
          <div className="login-checkbox-container">
            <div className="d-flex ">
              <CheckBox
                name={"rememberMe"}
                value={rememberMe}
                onChange={onChangeRememberMe}/>
              <p className="login-checkbox-label ">Remember me</p>
            </div>
            <div>
              <p
                className="login-forgot-text"
                onClick={() => navigate("/auth/forgot-password")}
              >
                Forgot Password ?
              </p>
            </div>
          </div>
          <div className="mt-2">
            <NormalButton label={"LOG IN"} loginBtn />
          </div>
        </div>
      </form>
    </>
  );
};

export default Login;
