import { createSlice } from "@reduxjs/toolkit";
import request from "../../services";
import Exported from "../../services/endpoints";

export const appConfigsSlice = createSlice({
    name: "appConfigs",
    initialState: {
        data: [],
    },
    reducers: {
        saveData: (state, action) => {
            state.data = action.payload;
        },
    }
});


export const getAppConfigs = () => async (dispatch) => {
    request({
        url: `${Exported.Endpoints.GET_ALL_APP_CONFIGS}`,
        method: Exported.APIMethods.GET,
    }).then(res => {
        console.log(res?.data?.data);
        dispatch(saveData(res?.data?.data));
    }).catch(_err => {
    })
}

export const updateAppConfig = (data) => async (dispatch) => {
    request({
        url: `${Exported.Endpoints.UPDATE_APP_CONFIG}`,
        method: Exported.APIMethods.PUT,
        data
    }).then(res => {
        dispatch(getAppConfigs());
    }).catch(_err => {
    })
}

export const { saveData } = appConfigsSlice.actions;

export default appConfigsSlice.reducer;
