import React from "react";
import "./styles.scss";
import "../login/styles.scss";
import "../editUsers/styles.scss";
import assets from "../../assets";
import NormalButton from "../../components/NormalButton";
import {  useNavigate } from "react-router-dom";
import TextAreaInput from "../../components/TextArea";
import { useForm } from "react-hook-form";
import { createUserNotifications } from "../../redux/reducers/notificationSlice";
import { errorToast, successToast } from "../../utils";

const AddUserNotification = () => {
  let navigate = useNavigate();

  const onSubmit = (data) => {

    createUserNotifications(data).then(res => {
      successToast("Notification sent to all users");
      navigate(-1)
    }).catch(_err => {
      errorToast("Something went wrong. Please try again.")
    })

  };
  const {
    handleSubmit,
    register,
    formState: { errors },
    
  } = useForm({ mode: "onBlur" });

  return (
    <>
      <div className="edit-user-container">
        <div className="back-to-user-container">
          <img
            src={assets.Icons.left_icon}
            alt="left-icon"
            className="left-icon"
          />
          <span
            className="back-to-user"
            onClick={() => navigate("/main/user-notification")}
          >
            Back to User Notification
          </span>
        </div>
        <div className="edit-user ">
          <p className="edit-user-details">{`Create new Notification`}</p>
          <div className="d-flex">
            <form className="col-md-3 d-flex" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-12">
               
                <div className="">
                  <label className="login-input-label"> Message</label>
                  <TextAreaInput
                    name='message'
                    placeholder="Please enter a message"
                    register={register("message", {
                      required: "Message is required",
                      minLength: {
                        value: 6,
                        message: "Message should have above 6 characters"
                      }
                    })}
                    error={errors.message}
                    messages={errors}
                  />
                </div>
             
                <div className="add-btn-container">
                  <div className="edit-cancel-btn">
                    <NormalButton
                      label=" CANCEL"
                      cancelBtn
                      onClick={() => navigate("/main/user-notification")}
                    />
                  </div>
                  <div className="edit-update-btn">
                    <NormalButton label={"CREATE"} mainBg />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

  
    </>
  );
};

export default AddUserNotification;